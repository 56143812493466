// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.withdrawform .invitationLabels {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #303030;
  margin-bottom: 4px;
  line-height: 21px;
}`, "",{"version":3,"sources":["webpack://./src/components/Fundraiser/Withdraw/withdraw.scss"],"names":[],"mappings":"AAAA;EACI,gCAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;EACA,iBAAA;AACJ","sourcesContent":[".withdrawform .invitationLabels {\n    font-family: Poppins, sans-serif;\n    font-size: 14px;\n    font-weight: 500;\n    color: #303030;\n    margin-bottom: 4px;\n    line-height: 21px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
