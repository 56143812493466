import React from "react";
import FundraiserHeader from "../common/FundraiserHeader";
import FundraiserBodyHeader from "../common/FundraiserBodyHeader";
import ListItemIcon from "../../../Assets/Icons/ListItem Ellipse 8767.svg";
import GooglePlay from "../../../Assets/Images/Google Play.svg";
import AppStore from "../../../Assets/Images/App Store.svg";
import PrimaryOutlineButton from "../../common/PrimaryOutlineButton";

const Activated = () => {
  return (
    <div>
      <FundraiserHeader />
      <FundraiserBodyHeader
        heading={"Membership Activated"}
        content={"Congratulations!"}
        contentClass={
          "w-[100%] sm:w-[80%] md:w-[90%] text-[#303030] font-[600]"
        }
      />

      <div className="flex flex-col items-center">
        <div className="my-[30px] w-[90%] sm:w-[80%] md:w-[75%] lg:w-[70%] xl:w-[60%]">
          <p className="text-[18px] font-[400] font-poppins text-[#303030] w-[90%] text-center m-auto sm:w-full sm:text-start sm:m-0">
            We have successfully created your new Swoodle account and your
            membership has been activated.{" "}
          </p>
          <p className="text-[18px] font-[400] font-poppins text-[#303030] my-[18px]">
            To get started:
          </p>

          <div className="pl-7">
            <div className="flex items-baseline mb-1">
              <img src={ListItemIcon} className="mr-3 w-[10px]" />
              <p className="text-[18px] font-[400] font-poppins text-[#303030]">
                Download the Swoodle Mobile App
              </p>
            </div>

            <div className="flex items-baseline mb-1">
              <img src={ListItemIcon} className="w-[10px] mr-3" />
              <p className="w-[97%] text-[18px] font-[400] font-poppins text-[#303030]">
                Login with your username and password of the account you have
                just created and enjoy exclusive offers on food, cinema,
                activities and more.
              </p>
            </div>
          </div>
        </div>

        <div className="w-[90%] sm:w-[80%] md:w-[75%] lg:w-[70%] xl:w-[60%] sm:flex sm:justify-between text-center">
          <div className="flex justify-center gap-[3%]">
            <img
              src={GooglePlay}
              className="w-[45%] sm:w-[35%] md:w-[40%]"
            ></img>
            <img src={AppStore} className="w-[45%] sm:w-[35%] md:w-[40%]"></img>
          </div>

          <PrimaryOutlineButton
            label={"Close"}
            className={
              "w-[40%] sm:w-[18%] lg:w-[15%] text-[14px] sm:text-[16px] md:text-[18px] font-[500] my-12 sm:my-0 rounded-[10px]"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Activated;
