import React from "react";
import { useNavigate } from "react-router-dom";
import FormBg from "../../../Assets/Images/Fundraiser/membershipActivation.svg";
import Profile from "../../../Assets/Images/Profile.svg";
import emailIcon from "../../../Assets/Icons/emailIcon.svg";
import TextInput from "../../common/SignUp/TextInput";
import PrimaryButton from "../../common/PrimaryButton";
import FundraiserHeader from "../common/FundraiserHeader";
import FundraiserBodyHeader from "../common/FundraiserBodyHeader";
import { useFormik } from "formik";
import { forgetPasswordSchema } from "../../../utils/validationSchemas";
import { useDispatch } from "react-redux";
import { setForgetPasswordData } from "../../../redux/slices/authSlice";
import { forgetPassword } from "../../../services/authService";

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigation = () => {
    setTimeout(() => {
      navigate("/user-otpVerification");
    }, 1000);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgetPasswordSchema,
    onSubmit: async (values) => {
      const forgetPromise = await dispatch(forgetPassword({email: email}))?.unwrap();
      console.log('forgetPromise', forgetPromise);
      if(forgetPromise?.success === true) {
        navigation();
        dispatch(setForgetPasswordData({email: email}));
      }
      
      // Handle form submission
      console.log(values);
    },
  });

  const { email } = formik.values;

  const { handleChange, handleBlur, handleSubmit, errors, touched } = formik;

  return (
    <div className="pb-24">
      <FundraiserHeader />

      <div className="flex flex-col items-center">
        <FundraiserBodyHeader
          heading={"Recover Account"}
          content={
            "Please enter your email address. You will receive a link to create a new password via email."
          }
          contentClass={"w-[100%] sm:w-[80%] md:w-[90%] text-[#8C8C8C]"}
        />

        <div className="relative mt-12 sm:mt-24 mb-14">
          <img src={FormBg} className="w-[320px] sm:w-[370px]"></img>

          <div className="absolute top-2 sm:top-0 w-full mt-[-14%]">
            <div className="flex justify-center">
              <img src={Profile} className="w-[80px] sm:w-[104px]"></img>
            </div>

            <p className="text-[18px] md:text-[20px] font-[600] text-center text-[#00ADEF] font-poppins">
              Recover Account
            </p>

            <div className="px-[6%] mt-5 sm:mt-8 md:mt-9">
              <TextInput
                Icon={emailIcon}
                name={"email"}
                value={email}
                placeholder={"Email ID"}
                inputClass={"w-full rounded-tr-[10px] rounded-br-[10px] border-none"}
                iconAreaClass={"w-[18%] bg-[#E1E1E1] rounded-tl-[10px] rounded-bl-[10px] border-none"}
                inputDivClass={"h-[55px] sm:h-[65px]"}
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.email}
                touched={touched.email}
              />

              <PrimaryButton
                className={
                  "mt-3 sm:mt-7 w-[100%] text-[18px] font-[600] h-[50px] sm:h-[45px] md:h-[50px] rounded-[10px]"
                }
                label="Recover"
                onClick={handleSubmit}
              />
              <div className="mt-5 sm:mt-8 text-[16px] font-[500] font-poppins text-[#00ADEF] text-center underline underline-offset-2">
                Need help
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
