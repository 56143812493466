import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Privacy = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location?.pathname;

  const handlePrivacyClick = () => {
    navigate("/privacy/policy");
  };
  const handleTermsClick = () => {
    navigate("/privacy/toc");
  };

  const privacyData = [
    {
      heading: "PRIVACY",
      paragraph: [
        "Swoodle APP Pty Ltd ('Swoodle') respects your privacy. This Privacy Policy describes the information we collect and the manner in which we may use such information. We may modify or supplement this Privacy Policy from time to time. If we decide to change this Privacy Policy, we will post those changes on this page of our website. We are committed to ensuring that to the best of our ability, we meet all the requirements of Australian Privacy Principles and the Privacy Act in Australia, and the Privacy Act (2020) and Privacy Principles in New Zealand.",
      ],
    },
    {
      heading: "Collection of information:",
      paragraph: [
        "Swoodle collects information about you in 4 different ways:",
      ],
      details: [
        {
          heading: "Member Activation:",
          bullet:
            "When you activate your Entertainment membership, we will ask you to provide certain information about yourself. This information may include name, mailing address, e-mail address, and phone number. We will collect personal information such as this only when it has been voluntarily provided by you.",
        },
        {
          heading: "Restaurant Appraisal Forms:",
          bullet:
            "When you complete a restaurant appraisal form as part of your Entertainment dining experience, we collect contact information such as your name, mailing address, and e-mail address.",
        },
        {
          heading: "Voting for your Favourite Restaurant:",
          bullet:
            "When you vote for your favourite restaurant using the online form or the form in the Swoodle membership, we collect contact information such as your name, mailing address and e-mail address.",
        },
        {
          heading: "Cookies/IP Addresses:",
          bullet:
            "Swoodles' primary website does not collect permanent cookies. Swoodle collects information about individuals using the Frequent Values™ series of websites through Internet Protocol (IP) addresses. An IP address is a number that is unique to the computer through which you are connected to the Internet. The Frequent Values™ series of websites also use 'cookies' to assign identification to your computer. Cookies are small pieces of information that are stored by your web browser on your computer's hard drive. Cookies allow a website to store and reference specific types of information while you are visiting it, and to keep your session 'live' when you are logged in. Swoodles’ cookies contain no personal information or financial information. Most web browsers automatically accept cookies, although your browser preferences may be changed to prevent this if you so desire. Please see www.cookiecentral.com for further information.",
        },
      ],
    },
    {
      heading: "Delete Account",
      paragraph: [
        "Swoodle provides an option to remove a user's account details via the App. Users can revoke this request within 30 days by clicking the Withdraw prompt in the Entertainment App.",
        "This request will only affect your Swoodle membership account details. As per applicable Australian and New Zealand law, we must maintain financial records for seven years and thus keep historical data to support any audit.",
      ],
    },
    {
      heading: "Use of information by Swoodle:",
      paragraph: [],
      details: [
        {
          heading: "",
          bullet:
            "We use the information we collect from you in order to provide you with a specific service. For example, we use your contact information (eg. name, address and credit card information), along with your Swoodle number, to provide you with, and/or bill you for, any ESwoodle Membership you wish to purchase and which may be available directly from us (note: you will not be able to use such services without providing this information).",
        },
        {
          heading: "",
          bullet:
            "We may also use this information to improve our marketing efforts and services, analyze site usage and customize our website's content and layout.",
        },
        {
          heading: "",
          bullet:
            "We may disclose your personal information to the fundraiser hat sold you your Swoodle Membership or you have selected at purchase of membership so that the fundraiser may notify you of new editions and other relevant initiatives.",
        },
        {
          heading: "",
          bullet:
            "We may also use this information to notify you of special offers, restaurant updates, discounts, and new products and services related to the purchase of your Entertainment Membership (via e-mail, SMS and other on and off-line communications). If you do not wish to receive these communications from us, please notify us by e-mail at contact@swoodle.com.au or on 1300 000000",
        },
        {
          heading: "",
          bullet:
            "If you are providing artwork for your fundraising organisation, we may use these in materials relating to the promotion of the Swoodle Membership on our website",
        },
      ],
    },
    {
      heading: "Who will see or have access to your information?",
      paragraph: [
        "We may disclose to third parties aggregate anonymous statistics regarding our customers, sales, traffic analyses and other information regarding or collected through our websites and printed forms. Other than in this anonymous form, we will not disclose your personal information without first obtaining your permission except as follows:",
      ],
      details: [
        {
          heading: "",
          bullet:
            "We may disclose personal information if required by law or legal process.",
        },
        {
          heading: "",
          bullet:
            "We may disclose your personal information to third parties providing, or assisting us in providing, services requested by you, and for billing purposes.",
        },
      ],
    },
    {
      heading: "Security",
      paragraph: [
        "We process online customer orders and collect and store personally identifiable information using 128 SSL encryption technology. SSL encrypts the information you input before it is sent to us via the Internet. In addition, all of the customer information we collected on our website is stored on Swoodle App Pty Ltd's host server which is located in a restricted access area and is password protected.",
      ],
    },
    {
      heading: "Links to other sites: ads",
      paragraph: [
        "Our websites may contain advertisements and links to other websites ('Third Party Materials'). We are not responsible for the privacy policies or content of these Third Party Materials. Our privacy policy only addresses the use and disclosure of information we collect from our Websites. We encourage you to inquire about and review the privacy policies of providers of Third Party Materials before you disclose your personal information to others.",
      ],
    },
    {
      heading: "Correcting and updating your information",
      paragraph: [
        "You may gain access to personal information that Swoodle has collected about you by contacting 1300 000000 (Australia). You may also request to have any of your personal information corrected or updated by sending an e-mail to the following e-mail address: contact@swoodle.com.au or call us at 1300 0000000 (Australia).",
      ],
    },
    {
      heading: "Feedback",
      paragraph: [
        "We appreciate your comments and questions regarding this Privacy Policy. If at any time you believe we have handled your information otherwise than in accordance with this Privacy Policy, please notify us by e-mail at contact@swoodle.com.au or call us at 1300 0000000 (Australia). and we will use commercially reasonable efforts to investigate and, if we deem necessary, remedy the matter",
      ],
    },
  ];

  const termsData = [
    {
      heading: "1. Program Overview",
      paragraph: [
        "Swoodle (“Program”) is a membership program which is operated by Swoodle Pty. Ltd ABN  23 670 336 008 (“Swoodle”). By enrolling and participating in the Program, you agree that you have read and understood these Terms and Conditions and are bound by them. You consent to Swoodle collecting, using and disclosure of your personal information in accordance with the separate Swoodle Privacy Policy.",
        "‍Swoodle Digital Memberships (‘Memberships’) are sold primarily by community and fundraising groups, with 40% of the Membership purchase price retained by the organisation. Memberships can be purchased online via the fundraising organisation’s online order page.",
        "The barter, trade, sale, purchase, publishing or transfer of Memberships or any of its Swoodle Offers, Merchant Redemption Codes, or Free Trials, by any person or entity, including but not limited to travel servicers, travel providers, and printers, third party websites, publishers, and distributors of the Digital Membership or any of its Vouchers, is strictly prohibited, unless expressly authorised by Swoodle. All Swoodle Offers are subject to the Swoodle Rules of Use.",
        "Swoodle Return policy and guarantee – if for any reason you are not satisfied with your Swoodle Membership, you may within 14 days of purchase contact our office for a full refund. A proof of purchase is required. Secure Payments for additional Swoodle Memberships and Member-only offers available on the website can be made securely with a credit card at www.swoodle.com.au The charge will appear on your credit card statement as “TO BE CONFIRMED”",
        "All costs quoted on www.swoodle.com.au are in Australian dollars and are inclusive of GST.",
        "All Swoodle products, customised products, as well as the partners who provide offers on this site, are subject to availability. Swoodle takes no responsibility for any products, services, or promotions that are discounted, sold out or temporarily unavailable.",
        "These Terms and Conditions are governed by and will be construed in accordance with the laws of Australia.",
      ],
    },
    {
      heading: "2. Defined Terms",
      paragraph: [
        "Activation Date: The commencement date for an Swoodle membership, triggered by the activation of a purchased Activation Code.",
        "Activation Code: A code which is provided to a customer when they purchase a Swoodle product. The code must be activated to commence the Membership.",
        "Swoodle/Program: The membership program which is operated by Swoodle and provides Offers to its members.",
        "Member, Members or Membership: A person who holds a current and active Swoodle membership. Participating Merchants: A partner merchant that participates in the Swoodle Program and honours the associated Offers.",
        "Purchase Date: The date of purchase of a Swoodle Product, where the customer is provided with an Activation Code.",
        "Offer: Those features of the program that provide members with access to discounts on dining, travel, shopping and other categories. Offers may change from time to time without notice. Other Offers: Other promotions and special Offers which may be offered to you from time to time.",
      ],
    },
    {
      heading: "3. Joining Swoodle",
      paragraph: [
        "Customers can join the Swoodle program by purchasing a Swoodle product and activating it online at www.swoodle.com.au . From time of purchase, an Activation Code must be activated within 90 days. If activated within 90 days, the Membership will expire 12 months from the Activation Date. If not activated within 90 days, the Membership will expire 15 months from the Purchase Date. Customers can view the full range of Swoodle products online. Products and pricing are subject to change from time to time.",
        "Members must provide a valid email address and mobile number and create a unique password when joining or activating their Swoodle Membership, which will be used to log into a Members online account and the Swoodle app. Members are responsible for maintaining the secrecy of their password and can change it at any time. Swoodle takes no responsibility for any loss arising from unauthorised use of a Member’s password or a Member’s failure to comply with these provisions.",
      ],
    },
    {
      heading: "4. Participation in the Program",
      paragraph: [
        "Swoodle Offers can be accessed via the Swoodle App and website and redeemed at Participating Merchants. Swoodle Offers are not transferable, not redeemable for cash and are non-refundable. Swoodle Memberships are valid for 12 months and must be renewed, with payment of the applicable annual fee. Membership can be revoked by Swoodle at any time, in its sole discretion and without compensation, for any reason including but not limited to: abuse or breach of any of the Terms and Conditions of the Program, any actions deemed by Swoodle to be contrary to Swoodles Program or the interests of its Members or partners or in the case of fraud or misuse",
        "Swoodle assumes no liability whatsoever, including without limitation, liability for any expense, loss, cost, injury, damage, accident or any other matter or thing whatsoever, however suffered or caused (including compensatory, incidental, indirect, special, punitive, consequential or exemplary damages or damages for loss of income or profits), directly or indirectly arising out of or related to the Program or by reason of the termination of or amendment to the Program in whole or in part, the value or availability of Offers, or the addition or deletion of Offers, Participating Merchants or other features with or without notice.",
        "Neither Swoodle nor its respective officers, agents, employees and related bodies corporate or otherwise shall be responsible for any failure of the swoodle.com.au website or Swoodle app for any problems or technical malfunction of a telephone network or lines, computer online systems, servers, access providers, computer equipment, software, failure of any e-mail, online or Internet entry, technical problems, traffic congestion on the Internet or at any website, or any combination thereof, including any injury or damage to any computer or property related to or resulting from participating in or downloading any material relating to the Program. Swoodle reserves the right, at its sole discretion, to cancel or suspend this Program should a virus, bug or any other cause beyond the reasonable control of Swoodle corrupt the security or proper administration of the Program. Any attempt to deliberately damage any website or app or to undermine the legitimate operation of the Program is a violation of criminal and civil laws and should such an attempt be made, Swoodle reserves the right to seek remedies and damages to the fullest extent permitted by law, including criminal prosecution.",
      ],
    },
    {
      heading: "5. Renewing Membership",
      paragraph: [
        "At the completion of the membership period, Members will be invited to pay for a new membership period. Members who do not pay their renewal fee by the due date will not be able to access Offers.",
      ],
    },
    {
      heading: "‍6. Marketing, Promotions and Personal Information",
      paragraph: [
        "By enrolling in the Program, Members will be automatically enrolled into and consent to receive marketing communications from Swoodle, your nominated fundraiser and from people whose offers you take up. It is the responsibility of the member to set their own communication preferences by logging into their account and selecting what they wish to receive and not receive or by selecting to opt-out of receiving email communications by selecting unsubscribe on the email footers. Members are responsible for keeping the information in their account up to date including by not limited to their name, e-mail address, mobile phone number and date of birth.",
      ],
    },
    {
      heading: "7. Membership Offers",
      paragraph: [
        "Offers are only available at Participating Merchants and vary between region. Offers are for personal use only, and cannot be assigned, traded or otherwise transferred in accordance with the Terms and Conditions of the Program. Any assignment or transfer in violation of these rules will be void, and may, at the sole discretion of Swoodle, result in the loss of Membership.‍ All promotions exclude Swoodle Merchant Waitstaff offers and Corporate Partner offers",
      ],
    },
    {
      heading: "8. Competitions",
      paragraph: [
        "Swoodle may also from time to time make available to Members promotions and competitions (collectively, “Competitions”). Where applicable, Members may enter Competitions by meeting the entry criteria specified on each Competition. We may publish names of winners of Competition prizes. Each Competition will be subject to any individual terms and conditions specified on the Website. However, some of the standard terms and conditions are as follows:",
      ],
      details: [
        {
          heading: "",
          bullet:
            "We use the information we collect from you in order to provide you with a specific service. For example, we use your contact information (eg. name, address and credit card information), along with your Swoodle number, to provide you with, and/or bill you for, any ESwoodle Membership you wish to purchase and which may be available directly from us (note: you will not be able to use such services without providing this information).",
        },
        {
          heading: "",
          bullet:
            "We may also use this information to improve our marketing efforts and services, analyze site usage and customize our website's content and layout.",
        },
        {
          heading: "",
          bullet:
            "Awards and prizes must be taken intact as offered and are not  transferable, exchangeable or redeemable for cash;",
        },
        {
          heading: "",
          bullet:
            "If a person otherwise entitled to an award or prize is under the age 18, we reserve the right to instead give the award or prize to that person’s parent or guardian;",
        },
        {
          heading: "",
          bullet:
            "If an award or prize is unavailable for any reason, we reserve the right to substitute an award or prize of equal or greater value;",
        },
      ],
    },
    {
      heading: "",
      paragraph: [
        "Swoodles’ decision on all matters relating to a Competition will be final and not subject to challenge or review. Offers may not be redeemed in conjunction with any other promotional offers including, but not limited to, promotional discounted ticket offers. Neither Swoodle nor our officers, agents, employees and related bodies corporate make any representation or warranty as to the quality, suitability or merchantability of any goods or services offered or given as awards or prizes.",
      ],
    },
    {
      heading: "9. Privacy",
      paragraph: [
        "We may disclose to third parties aggregate anonymous statistics regarding our customers, sales, traffic analyses and other information regarding or collected through our websites and printed forms. Other than in this anonymous form, we will not disclose your personal information without first obtaining your permission except as follows:",
      ],
      details: [
        {
          heading: "",
          bullet:
            "We may disclose personal information if required by law or legal process.",
        },
        {
          heading: "",
          bullet:
            "We may disclose your personal information to third parties providing, or assisting us in providing, all information communication technology, website or app development and production functions, website or app analytics, services requested by you and for billing purposes.",
        },
        {
          heading: "",
          bullet:
            "We may disclose your personal information to the fundraiser that sold you your Swoodle Membership or you have selected at purchase of membership so that the fundraiser may notify you of new editions and other relevant initiatives.",
        },
        {
          heading: "",
          bullet:
            "We may disclose your personal information provided voluntarily as part of the restaurant appraisal process to the restaurant you appraised, so that the restaurant can provide you with information that it thinks you may be interested in about that restaurant",
        },
        {
          heading: "",
          bullet:
            "Swoodle may disclose personal information to an overseas required under the terms of your purchase; or the disclosure is required or authorised by an Australian court or tribunal or under Australian law. If we send personal information overseas, Swoodle will take reasonable and practicable steps to ensure that the overseas recipient handles such information in accordance with the Privacy Act.",
        },
        {
          heading: "",
          bullet: "To view the full privacy policy please click here",
        },
      ],
    },
    {
      heading: "10. Termination, Changes and Breach",
      paragraph: [
        "Swoodle may change these Terms and Conditions, any aspect of the Program including redemption procedures or any Offers in any respect, all without notice and even though changes may affect the value of Swoodle Memberships already purchased.",
      ],
    },
    {
      heading: "‍11. General",
      paragraph: [
        "These Terms and Conditions constitute the entire agreement between you and Swoodle regarding your participation in the Program, your entitlement to redeem Offers of the Program and supersede all previous versions. Except as expressly contained in these Terms and Conditions, there are no conditions, representations, warranties, express or implied, statutory or otherwise. When you deal with Swoodle or its respective officers, agents, employees and related bodies corporate over the Internet, you consent to the formation of contractual relations through electronic communications. Swoodle has the final authority as to the interpretation of these Terms and Conditions and as to any other questions or disputes regarding the Program.",
      ],
    },
    {
      heading: "12. Changes to these Terms and Conditions",
      paragraph: [
        "Swoodle reserve the right to change, suspend or terminate these Terms and Conditions at any time. Where Swoodle changes these Terms and Conditions, or any Offers under the Program, an updated copy of these Terms and Conditions will be available on the Swoodle website. Members should regularly check for updates to the Terms and Conditions",
      ],
    },
    {
      heading: "13. Rules of Use for your Swoodle Membership",
      paragraph: [
        "Please note: For all dining offers it is optional for participating businesses to accept your Entertainment Membership on all official Australian Public Holidays, Mother’s Day, Father’s Day and Valentine’s Day",
      ],
      parts: [
        {
          heading: "13.1. Swoodle Membership",
          paragraphs: [
            "Your Swoodle Membership needs to be activated on the Swoodle website. Once activated, simply show your phone with the ‘Redeem’ screen to the relevant participating business. Your membership is not transferable.",
          ],
          bullets: [],
          afterPara: "",
        },
        {
          heading: "13.2. Redeeming Offers",
          paragraphs: [
            "With the Digital Swoodle Membership, simply present the relevant digital app voucher for the participating business at the time you request your bill or make your purchase to redeem the offer. Carefully read the terms of each offer before using. Offers apply only to the items and terms specified.",
          ],
          bullets: [],
          afterPara: "",
        },
        {
          heading: "‍13.3. Not valid with other third-party offers",
          paragraphs: [
            "Swoodle offers are exclusive and may not be combined with other third-party discount programs.",
          ],
          bullets: [],
          afterPara: "",
        },
        {
          heading: "13.4. Dining Section Offers",
          paragraphs: [
            "It is not necessary to identify yourself as a Swoodle Member when making your booking or arriving at the restaurant. Present your phone with the “Redeem” screen visible at the time you request your bill to redeem the offer. You may use each offer once at each restaurant listed in this section. The restaurant’s offer will then appear as “Redeemed” after it has been used.",
          ],
          bullets: [],
          afterPara: "",
        },
        {
          heading: "13.5. Valid anytime & Excluded Days",
          paragraphs: [
            "Swoodle’s dining offers are valid at all times throughout the year, except for a few days known as Optional Days. These days include:",
          ],
          bullets: [
            "All Official Australian Public Holidays",
            "Good Friday",
            "Valentines Day",
            "Mother’s Day",
            "Father’s Day",
          ],
          afterPara: "All other Swoodle Offers are Valid at all times.",
        },
        {
          heading: "13.6. Main course offers",
          paragraphs: [
            "The restaurant will deduct the price of the least expensive main course(s), up to the maximum value stated on the offer. Please note the following:",
          ],
          bullets: [
            "If two main courses are not ordered, the least expensive item(s) ordered as a main course will be deducted.",
            "When more than two people are dining together, or if multiple Memberships are used, the least expensive main course(s) of the party is / are the one(s) deducted.",
            "When adults and children dine together and two or more adult-priced main courses are purchased, the least expensive adult main course is complimentary. If only one adult-priced main course is purchased, then the value of the child’s meal is deducted.",
          ],
          afterPara: "",
        },
        {
          heading: "13.7. 25% off dining offers",
          paragraphs: [
            "When dining at a restaurant where the offer is “25% off the total bill”, the restaurant will deduct 25% off the total bill for the table, up to the maximum value indicated on the offer. If multiple Memberships are used, the deduction is still 25% off the total bill, up to the maximum combined value of the Memberships.",
          ],
          bullets: [],
          afterPara: "",
        },
        {
          heading: "13.8. Restaurant ‘specials",
          paragraphs: [
            "If the restaurant is running a breakfast, lunch or dinner special/set menu, their offer is still valid. If it incorporates more than one course, the value of the main course portion, or 25% off will be deducted from the bill.",
          ],
          bullets: [],
          afterPara: "",
        },
        {
          heading: "‍13.9. Dining in groups",
          paragraphs: [
            "When more than two people dine together, these rules apply:",
          ],
          bullets: [
            "One bill for the party – no separate bills",
            "No more than three Entertainment Digital Memberships per party",
            "Number of diners 2, number of Memberships 1",
            "Number of diners 3, number of Memberships 1",
            "Number of diners 4, number of Memberships 2",
            "Number of diners 5, number of Memberships 2",
            "Number of diners 6 or more, number of Memberships 3",
          ],
          afterPara: "",
        },
        {
          heading: "13.10. Solo dining",
          paragraphs: [
            "Some restaurants include a solo dining option clearly marked on the offer. These are valid only when dining alone and may not be used when solo diners join other people at a table.",
          ],
          bullets: [],
          afterPara: "",
        },
        {
          heading: "13.11. Dine-in only",
          paragraphs: [
            "Restaurant offers in the Fine and Contemporary Dining, Café and Bistro Dining, and Casual Restaurant and Family Dining categories are valid for dine-in only, unless otherwise stated.",
          ],
          bullets: [],
          afterPara: "",
        },
        {
          heading: "13.12. Informal Dining and Takeaway offers",
          paragraphs: [
            "Unless otherwise noted, offers in the Informal Dining and Takeaway category may be used for both takeaway and dine-in (not for delivery unless otherwise stated).",
          ],
          bullets: [],
          afterPara: "",
        },
        {
          heading: "13.13. Movie offers",
          paragraphs: [
            "If a movie offer states “no free list” the cinema may be obligated by studio contracts to exclude certain movies. To check, call the cinema or check their website.",
          ],
          bullets: [],
          afterPara: "",
        },
        {
          heading:
            "13.14. Arts, Sports, Attractions, Retail and Local Services offers",
          paragraphs: [
            "Please read each offer for terms and conditions. When using these offers with accompanying children, if two or more adult admissions are purchased, the least expensive adult admission is complimentary, unless otherwise stated.",
          ],
          bullets: [],
          afterPara: "",
        },
        {
          heading: "13.15. Shopping, Travel and Leisure",
          paragraphs: [
            "Please refer to the specific terms and conditions for each offer. For the most up to date information about these offers and products, visit our Shopping or Travel  sections.",
          ],
          bullets: [],
          afterPara: "",
        },
        {
          heading: "13.16. Hotel and Resort Accommodation",
          paragraphs: [
            "Your Swoodle Membership is valid for up to 50% off at over 1,800 leading hotels and resorts. Properties featured in this section may have specific terms and conditions on their page. Please refer to each offer for details and the Hotel Program Rules of Use.",
            "Any changes or updates to these ‘Rules of Use’ can be found at www.swoodle.com.au The barter, trade, sale, purchase, or transfer of this Membership or any of its Swoodle Offers, Merchant Redemption Codes, Free Trials, or any other content, by any person or entity, including but not limited to travel services, travel providers, and printers, third party websites, publishers, and distributors of the Membership or any of its Offers, is strictly prohibited, unless expressly authorised by Swoodle Pty Ltd. All Swoodle Offers are subject to the Swoodle Rules of Use. Swoodle Pty Ltd. reserves the right to make changes to the Participants and their Offers at its sole discretion.",
            "This Membership and its Offers are intended for the non-profit use of the individual purchaser of this Membership. Additionally, the use of this Membership and its Offers for advertising purposes, in any form or fashion, is strictly prohibited. Any use of Offers in violation of these Rules will render the Offer VOID, and violators will be prosecuted. Offers may not be reproduced and are void where prohibited, taxed, or restricted by law. Swoodle Pty Ltd, will not be responsible if any establishment breaches its contract or refuses to accept Offers; we will however, use our best efforts to secure compliance. Swoodle Pty Ltd, will not be responsible in the event of Acts of God, fire, casualties, strike or other events beyond its control.",
          ],
          bullets: [],
          afterPara: "",
        },
      ],
    },
    {
      heading: "14. Swoodle Membership Terms and Conditions",
      paragraph: [
        "Swoodle Return policy and guarantee – if for any reason you are not satisfied with your Swoodle Membership, you may within 14 days of purchase contact our office for a full refund. Proof of purchase is required. If your Swoodle Membership was purchased during a promotional campaign period, refunds are bound to the specific terms and conditions of that promotion period. Click Here to view active competition and promotion Terms and conditions.",
      ],
    },
    {
      heading: "15. Payments",
      paragraph: [],
      parts: [
        {
          heading: "15.1. AU Payments",
          paragraphs: ["When purchasing an Swoodle Membership, we accept:"],
          bullets: [
            "Credit & Debit Cards (Visa, MasterCard, American Express,  UnionPay)",
            "Afterpay (For purchases $50- $2,000 from 20 January 2022)* ($49.99)",
          ],
          afterPara:
            "T&Cs apply. For full terms and conditions visit: https://www.afterpay.com/en-AU/terms-of-service",
        },
      ],
    },
    {
      heading: "16. General Promotion Terms",
      paragraph: [],
      details: [
        {
          heading: "",
          bullet:
            "Swoodle reserves the right to amend the dates and details of any promotion at any time.",
        },
        {
          heading: "",
          bullet:
            "Multiple offers cannot be applied to a single Membership order unless specified.",
        },
        {
          heading: "",
          bullet:
            "All times listed on www.swoodle.com.au are AEST unless otherwise specified.",
        },
        {
          heading: "",
          bullet:
            "Promotions do not apply to Memberships purchased through affiliates.",
        },
      ],
    },
    {
      heading: "",
      paragraph: [
        "For further information regarding current and past promotions, visit:  AU: https://www.swoodle.com.au/promotions",
      ],
    },
  ];

  const parseContent = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)/g;
    const parts = text?.split(urlRegex);
    function replaceEmailsWithAnchors(text) {
      const emailRegex = /[\w._%+-]+@[\w.-]+\.[a-zA-Z]{2,}/g;
      return text.replace(
        emailRegex,
        (email) => `<a key={index}
        href="mailto:${email}"
        style="color: #00ADEF;text-decoration: underline;" href="mailto:${email}">${email}</a>`
      );
    }

    return parts?.map((part, index) => {
      if (part?.match(urlRegex)) {
        return (
          <a
            key={index}
            href={part.startsWith("http") ? part : `http://${part}`}
            style={{ color: "#00ADEF", textDecoration: "underline" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {part}
          </a>
        );
      } else if (part?.match(/[\w._%+-]+@[\w.-]+\.[a-zA-Z]{2,}/g)) {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: replaceEmailsWithAnchors(part),
            }}
          ></div>
        );
      } else {
        return part;
      }
    });
  };

  return (
    <div className="w-full h-full flex flex-col items-center">
      <p className="font-poppins font-[500] sm:text-[18px] md:text-[24px] lg:text-[32px] text-[#303030] text-center mt-8">
        PRIVACY
      </p>
      <div className="h-full w-[80%]">
        <div className="flex w-full justify-between items-center mt-8">
          <div className="w-[50%] text-center">
            <p
              className="font-poppins font-[400] sm:text-[16px] md:text-[18px] lg:text-[20px] text-[#303030] text-center cursor-pointer"
              onClick={handlePrivacyClick}
            >
              Privacy Policy
            </p>
          </div>
          <div className="w-[50%] text-center">
            <p
              className="font-poppins font-[400] sm:text-[16px] md:text-[18px] lg:text-[20px] text-[#303030] text-center cursor-pointer"
              onClick={handleTermsClick}
            >
              Terms and Conditions
            </p>
          </div>
        </div>
        <div className="w-full bg-[#FAFAFA] pb-8 mt-2">
          <div className="flex justify-between">
            <div
              className={`${
                currentPath == "/privacy/policy" && "bg-[#00ADEF]"
              } h-[8px] w-[50%]`}
            ></div>
            <div
              className={`${
                currentPath == "/privacy/toc" && "bg-[#00ADEF]"
              } h-[8px] w-[50%]`}
            ></div>
          </div>

          {currentPath == "/privacy/policy"
            ? privacyData?.map((p) => (
                <div className="w-full px-10">
                  <p className="font-poppins font-[600] sm:text-[18px] md:text-[20px] lg:text-[24px] text-[#303030] text-start mt-8">
                    {p?.heading}
                  </p>
                  {p?.paragraph?.length > 0 &&
                    p?.paragraph?.map((para, index) => (
                      <p
                        className={`font-poppins font-[400] sm:text-[12px] md:text-[14px] lg:text-[16px] text-[#303030] text-start ${
                          index == 0 ? "mt-6" : "mt-3"
                        }`}
                      >
                        {parseContent(para)}
                      </p>
                    ))}
                  {p?.details &&
                    p?.details?.map((d) => (
                      <>
                        {d?.heading !== "" ? (
                          <>
                            <div className="w-full flex items-start">
                              <p className="w-[2%] xl:w-[1%] font-poppins font-[500] sm:text-[12px] md:text-[14px] lg:text-[16px] text-[#303030] text-start mt-5">
                                &#x2022;
                              </p>
                              <div className="w-[98%] xl:w-[99%] flex flex-col">
                                <p className="font-poppins font-[500] sm:text-[12px] md:text-[14px] lg:text-[16px] text-[#303030] text-start mt-5">
                                  {d?.heading}
                                </p>
                                <p className="font-poppins font-[400] sm:text-[12px] md:text-[14px] lg:text-[16px] text-[#303030] text-start">
                                  {parseContent(d?.bullet)}
                                </p>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="w-full flex items-start pl-1">
                            <p className="w-[2%] xl:w-[1%] font-poppins font-[400] sm:text-[12px] md:text-[14px] lg:text-[16px] text-[#303030] text-start">
                              &#x2022;
                            </p>
                            <p className="w-[98%] xl:w-[99%] font-poppins font-[400] sm:text-[12px] md:text-[14px] lg:text-[16px] text-[#303030] text-start">
                              {parseContent(d?.bullet)}
                            </p>
                          </div>
                        )}
                      </>
                    ))}
                </div>
              ))
            : currentPath == "/privacy/toc" && (
                <>
                  <p className="font-poppins font-[600] sm:text-[18px] md:text-[20px] lg:text-[24px] text-[#303030] text-start mt-8 w-full px-10">
                    Swoodle Membership Terms and Conditions
                  </p>
                  {termsData?.map((p) => (
                    <div className="w-full px-10">
                      <p className="font-poppins font-[600] sm:text-[18px] md:text-[20px] lg:text-[24px] text-[#303030] text-start mt-8">
                        {p?.heading}
                      </p>
                      {p?.paragraph?.length > 0 &&
                        p?.paragraph?.map((para, index) => (
                          <p
                            className={`font-poppins font-[400] sm:text-[12px] md:text-[14px] lg:text-[16px] text-[#303030] text-start ${
                              index == 0 ? "mt-6" : "mt-3"
                            }`}
                          >
                            {parseContent(para)}
                          </p>
                        ))}
                      {p?.details &&
                        p?.details?.map((d) => (
                          <>
                            {d?.heading !== "" ? (
                              <>
                                <div className="w-full flex items-start">
                                  <p className="w-[2%] font-poppins font-[500] sm:text-[12px] md:text-[14px] lg:text-[16px] text-[#303030] text-start mt-5">
                                    &#x2022;
                                  </p>
                                  <div className="w-[98%] flex flex-col">
                                    <p className="font-poppins font-[500] sm:text-[12px] md:text-[14px] lg:text-[16px] text-[#303030] text-start mt-5">
                                      {d?.heading}
                                    </p>
                                    <p className="font-poppins font-[400] sm:text-[12px] md:text-[14px] lg:text-[16px] text-[#303030] text-start">
                                      {parseContent(d?.bullet)}
                                    </p>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <div className="w-full flex items-start pl-1">
                                <p className="w-[2%] xl:w-[1%] font-poppins font-[400] sm:text-[12px] md:text-[14px] lg:text-[16px] text-[#303030] text-start">
                                  &#x2022;
                                </p>
                                <p className="w-[98%] xl:w-[99%] font-poppins font-[400] sm:text-[12px] md:text-[14px] lg:text-[16px] text-[#303030] text-start">
                                  {parseContent(d?.bullet)}
                                </p>
                              </div>
                            )}
                          </>
                        ))}
                      {p?.parts &&
                        p?.parts?.map((part, i) => (
                          <>
                            <p className="font-poppins font-[600] sm:text-[12px] md:text-[14px] lg:text-[16px] text-[#303030] text-start mt-5">
                              {part?.heading}
                            </p>
                            {part?.paragraphs?.length > 0 &&
                              part?.paragraphs?.map((para, index) => (
                                <p className="font-poppins font-[400] sm:text-[12px] md:text-[14px] lg:text-[16px] text-[#303030] text-start">
                                  {para}
                                </p>
                              ))}
                            {part?.bullets?.length > 0 &&
                              part?.bullets?.map((bullet, index) => (
                                <div className="w-full flex items-start pl-1">
                                  <p className="w-[2%] xl:w-[1%] font-poppins font-[400] sm:text-[12px] md:text-[14px] lg:text-[16px] text-[#303030] text-start">
                                    &#x2022;
                                  </p>
                                  <p className="w-[98%] xl:w-[99%] font-poppins font-[400] sm:text-[12px] md:text-[14px] lg:text-[16px] text-[#303030] text-start">
                                    {bullet}
                                  </p>
                                </div>
                              ))}
                            <p className="font-poppins font-[400] sm:text-[12px] md:text-[14px] lg:text-[16px] text-[#303030] text-start">
                              {part?.afterPara}
                            </p>
                          </>
                        ))}
                    </div>
                  ))}
                </>
              )}
        </div>
        {/* <Privacy privacy={privacy}/> */}
      </div>
    </div>
  );
};

export default Privacy;
