import React from "react";

const RedButton = ({ label, className, onClick }) => {
  return (
    <button
      className={`bg-[#FD2C2C] rounded-[5px] text-white font-poppins text-center ${
        className ? className : null
      }`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default RedButton;
