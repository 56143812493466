import React from "react";
import GreyCross from "../../../Assets/Icons/Grey Cross.svg";
import GreyTick from "../../../Assets/Icons/Grey Tick.svg";
import GreenTick from "../../../Assets/Icons/Green Tick no border.svg";
import RedCross from "../../../Assets/Icons/Red Cross no border.svg";
import WhiteTick from "../../../Assets/Icons/White Tick.svg";
import WhiteCross from "../../../Assets/Icons/White Cross.svg";

const Confirmation = ({ status }) => {
  const ConfirmStatus = (status) => {
    switch (status) {
      case "Active":
        return (
          <div
            className="flex items-center justify-center gap-2 w-[97px] h-[25px] rounded-[20px] bg-[#50CD8933]"
            style={{ border: "0.5px solid #50CD89" }}
          >
            <div className="w-[14px] h-[14px] flex justify-center items-center rounded-[100%] bg-[#50CD89]">
              <img src={WhiteTick} alt="White Tick"></img>
            </div>
            <p className="text-[12px] font-[400]">Approved</p>
          </div>
        );
      case "Inactive":
        return (
          <div
            className="flex items-center justify-center gap-2 w-[97px] h-[25px] rounded-[20px] bg-[#F2D3CD]"
            style={{ border: "0.5px solid #EC826E" }}
          >
            <div className="w-[14px] h-[14px] flex justify-center items-center rounded-[100%] bg-[#EC826E]">
              <img src={WhiteCross} alt="White Cross"></img>
            </div>
            <p className="text-[12px] font-[400]">Declined</p>
          </div>
        );
      case "In Progress":
        return (
          <div className="flex gap-1">
            <div
              className="bg-[#50CD8933] w-[24px] h-[25px] flex items-center justify-center rounded-[100%]"
              style={{ border: "0.3px solid #50CD89" }}
            >
              <img src={GreenTick} alt="Green Tick"></img>
            </div>
            <div
              className="bg-[#EC826E4D] w-[24px] h-[25px] flex items-center justify-center rounded-[100%]"
              style={{ border: "0.3px solid #EC826E" }}
            >
              <img src={RedCross} alt="Red Cross"></img>
            </div>
          </div>
        );
      case "On Hold":
        return (
          <div className="flex gap-1">
            <div
              className="bg-[#D8D8D8] w-[24px] h-[25px] flex items-center justify-center rounded-[100%]"
              style={{ border: "0.3px solid #C4C4C4" }}
            >
              <img src={GreyTick} alt="Grey Tick"></img>
            </div>
            <div
              className="bg-[#D8D8D8] w-[24px] h-[25px] flex items-center justify-center rounded-[100%]"
              style={{ border: "0.3px solid #C4C4C4" }}
            >
              <img src={GreyCross} alt="Grey Cross"></img>
            </div>
          </div>
        );
      default:
        return null; // Handle the case when status is not matched
    }
  };

  return ConfirmStatus(status);
};

export default Confirmation;
