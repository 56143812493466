import React from "react";

const PrimaryOutlineButton = ({ label, className, onClick }) => {
  return (
    <button
      className={`bg-[white] border-[1px] border-[#00ADEF] h-[40px] text-[#00ADEF] font-poppins ${
        className ? className : null
      }`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default PrimaryOutlineButton;
