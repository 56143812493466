import React from "react";
import { ReactComponent as CloseIcon } from "../../../Assets/Icons/popupCloseIcon.svg";
import { ReactComponent as Bullet } from "../../../Assets/Icons/tradingHoursIcon.svg";
import "../../common/common.scss";

const TradingHoursPopup = ({ tradeHours, handleCloseTradeHours }) => {
  return (
    <div className="popup-container">
      <div
        className={`popup flex flex-col justify-evenly items-center sm:w-[470px] bg-white rounded-[10px] drop-shadow-[0_3px_3px_rgba(0,0,0,0.25)] relative pt-5 ${
          tradeHours.length > 3 ? "pb-8 h-[500px] overflow-y-scroll" : "pb-8"
        }`}
      >
        <div className="h-[3%]">
          <CloseIcon
            className="absolute top-5 right-5 cursor-pointer"
            onClick={handleCloseTradeHours}
          />
        </div>
        <div className="h-[92%] w-full flex flex-col justify-start gap-4">
          <span className="font-poppins font-[500] text-[18px] text-left pl-5">
            Trading Hours
          </span>
          <ul
            className={`flex flex-col gap-2 w-[100%] px-5 ${
              tradeHours.length > 3 ? "overflow-y-scroll" : ""
            }`}
          >
            {tradeHours?.map((t, index) => (
              <div
                className={`flex justify-center items-center w-[100%] gap-4 rounded-[10px] bg-white shadow-[0_4px_10px_0px_rgba(0,0,0,0.1)] py-5 px-2 ${
                  index == tradeHours?.length - 1 ? "" : ""
                }`}
              >
                <div className="w-[10%] flex justify-center items-center">
                  <Bullet className="w-[24px] h-[29px]" />
                </div>
                <li className="w-[90%] font-poppins font-[400] text-[14px] text-[#303030] pr-1">
                  <div className="flex justify-between items-center">
                    <p className="font-[600]">
                      {t?.day.charAt(0).toUpperCase() + t?.day.slice(1)}
                    </p>
                    <div className="mr-2">
                      {t?.openTime}
                      <span className="font-[600]"> to </span> {t?.closeTime}
                    </div>
                  </div>
                </li>
              </div>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TradingHoursPopup;
