import React, { useEffect, useState } from "react";
import Header from "../../components/common/WelcomeHeader";
import OfferStep from "../../components/common/OfferForm/OfferStep";
import { useDispatch, useSelector } from "react-redux";
import Location from "../../components/common/OfferForm/Location";
import RulesRegulations from "../../components/common/OfferForm/RulesRegulations";
import CustomizeOffer from "../../components/common/OfferForm/CustomizeOffer";
import OfferCreation from "../../components/common/OfferForm/OfferCreation";
import SubCategorySelection from "../../components/common/OfferForm/SubCategorySelection";
import CategorySelection from "../../components/common/OfferForm/CategorySelection";
import OfferReview from "../../components/common/OfferForm/OfferReview";
import { useLocation, useNavigate } from "react-router-dom";
import { resetOfferState } from "../../redux/slices/offerSlice";
import { createOffer, getCategories, getOfferById } from "../../services/offerService";

const WelcomeOfferLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id = null } = location?.state || "";
  const [stepIndex, setStepIndex] = useState(1);
  const [subCategories, setSubCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(undefined);
  const [payload, setPayload] = useState(null);
  const [categories, setCategories] = useState([]);
  const [editOfferData, setEditOfferData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const getOfferData = async () => {
    const getPromise = await dispatch(getOfferById({ id: id }))?.unwrap();
    if (getPromise?.success === true) {
      setEditOfferData(getPromise?.data);
    }
  };

  const getCategoriesData = async () => {
    const categoryPromise = await dispatch(getCategories())?.unwrap();
    if (categoryPromise?.success === true) {
      setCategories(categoryPromise?.data);
    }
  };

  useEffect(() => {
    getCategoriesData();
  }, []);

  useEffect(() => {
    if (id !== null) {
      setIsEdit(true);
      getOfferData();
    }
  }, [id]);

  const formSteps = [
    { stepNumber: 1, content: "Select Category" },
    { stepNumber: 2, content: "Select Sub Category" },
    { stepNumber: 3, content: "Create Offer" },
    { stepNumber: 4, content: "Customize Your Offer" },
    { stepNumber: 5, content: "Rules and Regulations" },
    { stepNumber: 6, content: "Locations" },
    { stepNumber: 7, content: "Review and Approve" },
  ];

  const handlePrevious = () => {
    setStepIndex(stepIndex - 1);
    setIsEdit(false);
  };

  const setPayloadData = (data) => {
    setPayload(data);
  };

  const handleSaveAndExit = async () => {
    if (payload !== null) {
      const createPromise = await dispatch(createOffer(payload))?.unwrap();
      if (createPromise?.success === true) {
        navigate("/merchant/offer-list");
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      dispatch(resetOfferState());
    };
  }, []);

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <Header btn={true} handleSaveAndExit={handleSaveAndExit}/>
      <div className="w-[80%] py-20">
        <div className="flex justify-between sm:flex-col lg:flex-row">
          <div className="flex items-center justify-between w-full">
            {formSteps?.map((step) => (
              <OfferStep
                stepNumber={step?.stepNumber}
                content={step?.content}
                stepIndex={stepIndex}
              />
            ))}
          </div>
        </div>
        {stepIndex === 1 && (
          <CategorySelection
            setSubCategories={setSubCategories}
            stepIndex={stepIndex}
            setStepIndex={setStepIndex}
            categoryId={categoryId}
            setCategoryId={setCategoryId}
            setPayloadData={setPayloadData}
            categories={categories}
            editOfferData={editOfferData}
            isEdit={isEdit}
          />
        )}
        {stepIndex === 2 && (
          <SubCategorySelection
            categoryId={categoryId}
            subCategories={subCategories}
            setSubCategories={setSubCategories}
            stepIndex={stepIndex}
            setStepIndex={setStepIndex}
            handlePrevious={handlePrevious}
            setPayloadData={setPayloadData}
            editOfferData={editOfferData}
            isEdit={isEdit}
          />
        )}
        {stepIndex === 3 && (
          <OfferCreation
            stepIndex={stepIndex}
            setStepIndex={setStepIndex}
            handlePrevious={handlePrevious}
            setPayloadData={setPayloadData}
            editOfferData={editOfferData}
            isEdit={isEdit}
          />
        )}
        {stepIndex === 4 && (
          <CustomizeOffer
            stepIndex={stepIndex}
            setStepIndex={setStepIndex}
            handlePrevious={handlePrevious}
            setPayloadData={setPayloadData}
            editOfferData={editOfferData}
            isEdit={isEdit}
          />
        )}
        {stepIndex === 5 && (
          <RulesRegulations
            stepIndex={stepIndex}
            setStepIndex={setStepIndex}
            handlePrevious={handlePrevious}
            setPayloadData={setPayloadData}
            editOfferData={editOfferData}
            isEdit={isEdit}
          />
        )}
        {stepIndex === 6 && (
          <Location
            stepIndex={stepIndex}
            setStepIndex={setStepIndex}
            handlePrevious={handlePrevious}
            setPayloadData={setPayloadData}
            editOfferData={editOfferData}
            isEdit={isEdit}
          />
        )}
        {stepIndex === 7 && (
          <OfferReview
            handlePrevious={handlePrevious}
            editOfferData={editOfferData}
            setPayloadData={setPayloadData}
          />
        )}
      </div>
    </div>
  );
};

export default WelcomeOfferLayout;
