import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumsHeader from "../../common/BreadCrums";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Status from "../../common/Status";
import Delete from "../../common/Delete";
import Edit from "../../common/Edit";
import Pagination from "../../common/TablePagination";
import CreateNewButton from "../../common/CreateNewButton";
import {
  deleteFranchise,
  getFranchises,
  toggleFranchiseStatus,
} from "../../../services/franchiseService";
import CancelPopup from "../../common/CancelPopup";
import deleteImg from "../../../Assets/Images/deleteImg.svg";
import Loader from "../../common/Loader";
import { ReactComponent as TradingIcon } from "../../../Assets/Icons/tradingHoursIcon.svg";
import TradingHoursPopup from "../../Admin/FundraiserList/TradingHoursPopup";

const FranchiseListTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.franchiseReducer?.loading);
  const [isTradeOpen, setIsTradeOpen] = useState();
  const [tradeHours, setTradeHours] = useState();

  const items = [
    {
      title: "Franchise",
    },
    {
      title: "Franchise List",
    },
  ];

  const columns = [
    {
      name: "No.",
    },
    {
      name: "Franchise Name",
    },
    {
      name: "Email",
    },
    {
      name: "Contact",
    },
    {
      name: "Merchant Name",
    },
    {
      name: "Location",
    },
    {
      name: "Trading Hours",
    },
    {
      name: "Status",
    },
    {
      name: "Actions",
    },
  ];

  const [deleteClicked, setDeleteClicked] = useState(false);
  const [selectedFranchiseId, setSelectedFranchiseId] = useState(null);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [allFranchises, setAllFranchises] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);

  const getAllFranchises = async () => {
    const franchisePromise = await dispatch(
      getFranchises({ page: currentPage })
    ).unwrap();
    if (franchisePromise?.success === true) {
      setAllFranchises(franchisePromise?.data);
      setCurrentPage(franchisePromise?.currentPage);
      setTotalPages(franchisePromise?.totalPages);
      setCount(franchisePromise?.count);
    }
  };

  useEffect(() => {
    getAllFranchises();
  }, [deleteSuccess, currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleToggle = async (id) => {
    try {
      await dispatch(toggleFranchiseStatus({ id: id })).unwrap();
    } catch (error) {
      console.error("Error toggling location status:", error);
    }
  };

  const handleHoursClick = (location) => {
    setTradeHours(location);
    setIsTradeOpen(true);
  };

  const handleCloseTradeHours = () => {
    setIsTradeOpen(false);
  };

  const handleEditClick = (id) => {
    navigate("/merchant/franchise-form", {
      state: {
        id: id,
      },
    });
  };

  const handleDeleteClick = (id) => {
    setDeleteClicked(true);
    setSelectedFranchiseId(id);
  };

  const handleKeepFranchise = () => {
    setDeleteClicked(false);
  };

  const handleDeleteFranchise = async () => {
    if (selectedFranchiseId !== null) {
      const deletePromise = await dispatch(
        deleteFranchise({ id: selectedFranchiseId })
      )?.unwrap();
      if (deletePromise?.success === true) {
        setDeleteClicked(false);
        setDeleteSuccess(!deleteSuccess);
      }
    }
  };

  return (
    <>
      {loading === true && <Loader />}
      {tradeHours !== null && isTradeOpen === true && (
        <TradingHoursPopup
          tradeHours={tradeHours}
          handleCloseTradeHours={handleCloseTradeHours}
        />
      )}
      <div className="flex justify-between col-span-12">
        <BreadCrumsHeader heading="Franchise List" items={items} />
        <CreateNewButton
          text={"Create New Franchise"}
          navigateTo={"/merchant/franchise-form"}
          iconClassName="sm:left-[1%] md:left-[4%] bottom-[30%]"
        />
      </div>
      {deleteClicked == true ? (
        <CancelPopup
          handleNo={handleKeepFranchise}
          handleYes={handleDeleteFranchise}
          heading={"Delete Franchise"}
          content={"Are you sure you want to delete this franchise?"}
          image={deleteImg}
        />
      ) : null}
      <div className=" col-span-12 mt-8">
        <TableContainer style={{ width: "100%" }}>
          <Table
            sx={{
              minWidth: "1936px",
              borderCollapse: "separate",
              borderSpacing: "0px 8px",
            }}
            aria-label="simple table"
          >
            <TableHead className="bg-[#00ADEF]">
              <TableRow
                className="h-[50px]"
                sx={{ boxShadow: "0px 4px 4px 0px #00000040" }}
              >
                {columns.map((column) => {
                  return <TableCell>{column.name}</TableCell>;
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {allFranchises?.map((franchise, index) => {
                return (
                  <TableRow
                    className="h-[50px]"
                    sx={{
                      boxShadow: "0px 4px 4px 0px #00000040",
                      backgroundColor:
                        (index + 1) % 2 !== 0 ? "#F5F5F5" : "#FFFFFF",
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{franchise?.name}</TableCell>
                    <TableCell>{franchise?.email}</TableCell>
                    <TableCell>{franchise?.phone}</TableCell>
                    <TableCell>{franchise?.merchant?.legalName}</TableCell>
                    <TableCell>{franchise?.googlePlaceId?.address}</TableCell>
                    <TableCell>
                      <div className="w-[80%] flex justify-center cursor-pointer">
                        <TradingIcon
                          className="w-[20px] h-[28px]"
                          onClick={() =>
                            handleHoursClick(franchise?.tradingHours)
                          }
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <Status
                        isEnabled={franchise?.isEnabled}
                        handleToggle={() => handleToggle(franchise?._id)}
                      />
                    </TableCell>
                    <TableCell>
                      <div className="flex justify-start gap-[4%]">
                        <Edit onClick={() => handleEditClick(franchise?._id)} />
                        <Delete
                          onClick={() => handleDeleteClick(franchise?._id)}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default FranchiseListTable;
