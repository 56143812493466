// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../Assets/Images/bannerbg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bannerDiv {
  border-radius: 10px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.styled-link {
  color: #00ADEF;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 600;
}
@media (min-width: 640px) and (max-width: 768px) {
  .styled-link {
    font-size: 14px;
  }
}
@media (min-width: 768px) {
  .styled-link {
    font-size: 16px;
  }
}

.styled-paragraph {
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #303030;
}
@media (min-width: 640px) and (max-width: 768px) {
  .styled-paragraph {
    font-size: 14px;
  }
}
@media (min-width: 768px) {
  .styled-paragraph {
    font-size: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/User/FundraiserBrandDetail/fundraiserbrand.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,yDAAA;EACA,4BAAA;EACA,0BAAA;AACF;;AAEA;EACE,cAAA;EACA,gCAAA;EACA,eAAA;EACA,gBAAA;AACF;AAAE;EALF;IAMI,eAAA;EAGF;AACF;AAFE;EARF;IASI,eAAA;EAKF;AACF;;AAFA;EACE,gCAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AAKF;AAJE;EALF;IAMI,eAAA;EAOF;AACF;AANE;EARF;IASI,eAAA;EASF;AACF","sourcesContent":[".bannerDiv{\n  border-radius: 10px;\n  background-image: url('../../../Assets/Images/bannerbg.png');\n  background-repeat: no-repeat;\n  background-size: 100% 100%;\n}\n\n.styled-link{\n  color: #00ADEF;\n  font-family: Poppins, sans-serif;\n  font-size: 12px;\n  font-weight: 600;\n  @media (min-width: 640px) and (max-width: 768px) {\n    font-size: 14px;\n  }\n  @media (min-width: 768px) {\n    font-size: 16px;\n  }\n}\n\n.styled-paragraph{\n  font-family: Poppins, sans-serif;\n  font-size: 12px;\n  font-weight: 400;\n  color: #303030;\n  @media (min-width: 640px) and (max-width: 768px) {\n    font-size: 14px;\n  }\n  @media (min-width: 768px) {\n    font-size: 16px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
