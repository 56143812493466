import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

console.log("development testing firebase.js");

const firebaseConfig = {
  apiKey: "AIzaSyBguZeBFZjaHf2hFQOzL6XBaW83uxDY7aw",
  authDomain: "swoodle-production.firebaseapp.com",
  projectId: "swoodle-production",
  storageBucket: "swoodle-production.appspot.com",
  messagingSenderId: "804928401291",
  appId: "1:804928401291:web:fe062965fb66b867d5898e",
  measurementId: "G-VZB4Q2E9ZP"
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

const sw = await navigator.serviceWorker.register("/firebase-messaging-sw.js", {
  scope: "/firebase-cloud-messaging-push-scope",
});
export const fetchToken = async () => {
  return await getToken(messaging, {
    serviceWorkerRegistration: sw,
    vapidKey: process.env.REACT_APP_VAPID_KEY,
  });
};
