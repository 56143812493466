import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swoodle from "../../../Assets/Images/Onboard Swoodle Logo.svg";
import "./login.scss";
import Profile from "../../../Assets/Images/Profile.svg";
import emailIcon from "../../../Assets/Icons/emailIcon.svg";
import passwordIcon from "../../../Assets/Icons/passwordIcon.svg";
import TextInput from "../SignUp/TextInput";
import { NavLink, useNavigate } from "react-router-dom";
import GlassEffect from "../../../Assets/Images/blueGlass.svg";
import { useFormik } from "formik";
import { loginSchema } from "../../../utils/validationSchemas";
import { ReactComponent as CheckedBox } from "../../../Assets/Icons/checkedBox.svg";
import { ReactComponent as UncheckedBox } from "../../../Assets/Icons/uncheckedBlackBox.svg";
import { login } from "../../../services/authService";
import { setLoginData } from "../../../redux/slices/authSlice";
import { getUserSubscription } from "../../../services/subscriptionService";
import Loader from "../Loader";
import { getOnboardingStatus } from "../../../services/fundraiserService";
import { getFcmToken } from "../../../utils/connectStore";

const LoginSection = () => {
  const dispatch = useDispatch();
  const subLoading = useSelector((state) => state.subscriptionReducer.loading);
  const authLoading = useSelector((state) => state.authReducer.loading);
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = useState(false);

  const onChecked = () => {
    setChecked(!checked);
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const navigation = async (role) => {
    switch (role) {
      case "merchant":
        navigate("/merchant/dashboard");
        // navigate("/merchant/welcome");
        break;
      case "fundraiser":
        const statusPromise = await dispatch(getOnboardingStatus()).unwrap();
        if (
          statusPromise?.data?.detailsSubmitted === true &&
          statusPromise?.data?.chargesEnabled === true
        ) {
          navigate("/fundraiser/dashboard");
        } else if (
          statusPromise?.data?.detailsSubmitted === true &&
          statusPromise?.data?.chargesEnabled === false
        ) {
          navigate("/complete-setup");
        } else {
          navigate("/fundraiser/welcome");
        }
        break;
      case "admin":
        navigate("/dashboard");
        break;
      default:
        navigate("/dashboard");
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      const promiseResult = await dispatch(
        login({
          email: email,
          password: password,
          fcmToken: getFcmToken()
        })
      ).unwrap();
      if (promiseResult?.success === true) {
        const result = await dispatch(getUserSubscription()).unwrap();
        if (result) {
          navigation(promiseResult?.data?.user?.role);
          dispatch(
            setLoginData({
              email: email,
              password: password,
              fcmToken: getFcmToken()
            })
          );
        }
      }
      console.log("values", values);
    },
  });

  const { email, password } = formik.values;

  const { handleChange, handleBlur, handleSubmit, errors, touched } = formik;

  return (
    <form className="loginSection py-16 w-full h-[100vh]" onSubmit={handleSubmit}>
      {subLoading || authLoading ? <Loader /> : null}
      <div className="flex flex-col items-center">
        <div className="pb-[5%]">
          <img src={Swoodle} alt="Swoodle"></img>
        </div>
        {console.log("Production Commit Tested --yo-bro")}
        <div className="relative sm:w-[370px] md:w-[420px] lg:w-[425px] 2xl:w-[440px] sm:mt-14 md:mt-12 lg:mt-8 2xl:mt-3">
          <img src={GlassEffect} alt="Glass Effect"></img>
          <div className="w-full absolute z-[1] top-0">
            <div className="flex justify-center mt-[-12%] ">
              <img src={Profile} className="w-[26%] h-[26%]" alt="profile"></img>
            </div>
            <div>
              <p className="sm:text-[18px] md:text-[20px] font-[600] text-center text-white font-poppins">
                Login
              </p>
            </div>
            <div className="w-[90%] h-[100%] m-auto">
              <div className={`sm:mt-[5%] 2xl:mt-[6%]`}>
                <TextInput
                  Icon={emailIcon}
                  name={"email"}
                  value={email}
                  placeholder={"Email ID"}
                  inputClass={"w-full rounded-tr-[10px] rounded-br-[10px] border-none"}
                  iconAreaClass={"w-[15%] bg-[#E6E6E6] rounded-tl-[10px] rounded-bl-[10px] border-none"}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={errors.email}
                  touched={touched.email}
                  autoComplete={"new-password"}
                />
              </div>

              <div className={`sm:mt-[4%] 2xl:mt-[4%]`}>
                <TextInput
                  Icon={passwordIcon}
                  type={showPassword ? "text" : "password"}
                  name={"password"}
                  value={password}
                  placeholder={"Password"}
                  inputClass={"w-full rounded-tr-[10px] rounded-br-[10px] border-none"}
                  iconAreaClass={"w-[15%] bg-[#E6E6E6] rounded-tl-[10px] rounded-bl-[10px] border-none"}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={errors.password}
                  touched={touched.password}
                  showPassword={showPassword}
                  togglePassword={togglePassword}
                  autoComplete={"new-password"}
                />
              </div>

              <div className="flex sm:mt-3 2xl:mt-[4%]">
                <div className=" w-[50%] flex items-center">
                  {checked === true ? (
                    <CheckedBox onClick={onChecked} className="inputCheckbox" />
                  ) : (
                    <UncheckedBox
                      onClick={onChecked}
                      className="inputCheckbox"
                    />
                  )}
                  <p className="sm:text-[10px] md:text-[12px] font-[400] text-white font-poppins">
                    {" "}
                    Remember me
                  </p>
                </div>

                <div className="w-[50%] text-right">
                  <p className="sm:text-[10px] md:text-[12px] font-[400] text-white font-poppins">
                    <NavLink to={"/forget-password"}>Forget Password</NavLink>
                  </p>
                </div>
              </div>

              <div className="mt-[5%] mb-[4%]">
                <button
                  type="submit"
                  className="bg-blue-400 w-full sm:h-[45px] md:h-[50px] sm:text-[16px] md:text-[18px] text-white font-[500] font-poppins text-center tracking-[0.15em]"
                  style={{ backgroundColor: "#00ADEF", borderRadius: "10px" }}
                  // onClick={handleSubmit}
                >
                  LOGIN
                </button>
              </div>

              <div>
                <p className="text-white text-center sm:text-[10px] md:text-[12px] font-[500] font-poppins">
                  Don’t have an account?{" "}
                  <NavLink
                    to={"/signup"}
                    className={
                      "font-[700] sm:text-[10px] md:text-[12px] ml-1 font-poppins"
                    }
                  >
                    Register
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default LoginSection;
