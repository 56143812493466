import React from "react";

const Status = ({ status }) => {
  const CheckStatus = (status) => {
    switch (status) {
      case "Active":
        return "text-[#2EBA34] bg-[#2EBA344D]";
      case "In Progress":
        return "text-[#339CFC] bg-[#339CFC4D]";
      case "On Hold":
        return "text-[#FFA800] bg-[#FFA8004D]";
      case "Inactive":
        return "text-[#8C8C8C] bg-[#CECECE4D]";
      default:
        return ""; // Handle the case when status is not matched
    }
  };

  return (
    <div
      className={`w-[113px] h-[25px] rounded-[20px] text-[12px] font-[500] flex justify-center items-center ${CheckStatus(
        status
      )}`}
    >
      {status}
    </div>
  );
};

export default Status;
