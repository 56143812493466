import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getNotificationsByUser } from "../../services/notificationService";
import toast from "../../utils/toast";

const initialState = {
  notifications: null,
  loading: false,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNotificationsByUser?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.notifications = action?.payload?.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder
      .addMatcher(isAnyOf(getNotificationsByUser?.pending), (state) => {
        state.loading = true;
      })
      .addMatcher(
        isAnyOf(getNotificationsByUser?.rejected),
        (state, action) => {
          state.loading = false;
          toast.error(action?.payload?.message);
        }
      );
  },
});

export const {} = notificationSlice?.actions;
export default notificationSlice?.reducer;
