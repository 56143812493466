import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  addBank,
  createConnectedAccount,
  createTransfer,
  getBanks,
  getFundraiserCategories,
  getFundraisersByCategoryId,
  getFundsDeposited,
  getOnboardingStatus,
  getWithdrawals,
  getAccountDetails,
  updateReferredBy,
  getFundraisers,
  getFundraiserInquiries,
  addContent,
  getFundraiserContent
} from "../../services/fundraiserService";
import toast from "../../utils/toast";

const initialState = {
  allFundraisers: null,
  fundraiserCategories: null,
  fundraisers: null,
  fundsDeposited: null,
  bankAdded: null,
  banks: null,
  withdrawals: null,
  transfer: null,
  payoutLink: null,
  onboardingStatus: null,
  accountDetails: null,
  referBy: null,
  inquiries: null,
  contentAdded: null,
  fundraiserContent: null,
  loading: false,
};

const fundraiserSlice = createSlice({
  name: "fundraiser",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFundraiserCategories?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.fundraiserCategories = action.payload.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(getFundraisersByCategoryId?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.fundraisers = action.payload.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(getFundsDeposited?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.fundsDeposited = action.payload.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(addBank?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.bankAdded = action?.payload?.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(getBanks?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.banks = action.payload.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(getWithdrawals?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.withdrawals = action.payload.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(createTransfer?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.transfer = action?.payload?.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(createConnectedAccount?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.payoutLink = action.payload.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(getOnboardingStatus?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.onboardingStatus = action.payload.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(getAccountDetails?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.accountDetails = action.payload.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(updateReferredBy?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.referBy = action?.payload?.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(getFundraisers?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.allFundraisers = action.payload.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(getFundraiserInquiries?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.inquiries = action?.payload?.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(addContent?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.contentAdded = action?.payload?.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(getFundraiserContent?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.fundraiserContent = action.payload.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder
      .addMatcher(
        isAnyOf(
          addBank?.pending,
          createConnectedAccount?.pending,
          createTransfer?.pending,
          getBanks?.pending,
          getFundraiserCategories?.pending,
          getFundraisersByCategoryId?.pending,
          getFundsDeposited?.pending,
          getOnboardingStatus?.pending,
          getWithdrawals?.pending,
          getAccountDetails?.pending,
          updateReferredBy?.pending,
          getFundraisers?.pending,
          getFundraiserInquiries?.pending,
          addContent?.pending,
          getFundraiserContent?.pending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          addBank?.rejected,
          createConnectedAccount?.rejected,
          createTransfer?.rejected,
          getBanks?.rejected,
          getFundraiserCategories?.rejected,
          getFundraisersByCategoryId?.rejected,
          getFundsDeposited?.rejected,
          getOnboardingStatus?.rejected,
          getWithdrawals?.rejected,
          getAccountDetails?.rejected,
          updateReferredBy?.rejected,
          getFundraisers?.rejected,
          getFundraiserInquiries?.rejected,
          addContent?.rejected,
          getFundraiserContent?.rejected
        ),
        (state, action) => {
          state.loading = false;
          toast.error(action?.payload?.message);
        }
      );
  },
});

export const { getSubscriptionData } = fundraiserSlice?.actions;
export default fundraiserSlice?.reducer;
