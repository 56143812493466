import React from "react";
import { Route, Routes } from "react-router-dom";
// import WelcomeOnboard from "./pages/User/WelcomeOnboard";
import OnBoardLayout from "../components/User/MerchantOnboard/OnBoardLayout";
import OnBoardBusiness from "../pages/User/OnBoardBusiness";
import OnBoardInformation from "../pages/User/OnBoardInformation";
import OnBoardSubmitInformation from "../pages/User/OnBoardSubmitInformation";
import FundraiserSubscription from "../pages/User/FundraiserSubscription";
import MembershipActivation from "../pages/User/MembershipActivation";
import MembershipConfirmation from "../pages/User/MembershipConfirmation";
import MembershipActivated from "../pages/User/MembershipActivated";
import NewMemberSetup from "../pages/User/NewMemberSetup";
import FundraiserBrand from "../pages/User/FundraiserBrand";
import OrderConfirmation from "../pages/User/OrderConfirmation";
import Subscription from "../pages/User/Subscription";
import ExistingLogin from "../pages/User/ExistingLogin";
import UserForgetPassword from "../pages/User/ForgetPassword";
import UserOTPVerification from "../pages/User/OTPVerification";
import UserResetPassword from "../pages/User/ResetPassword";
import ExistingSubscription from "../pages/User/ExistingSubscription";
import SubscriptionFailure from "../pages/User/SubscriptionFailure";

const UserRoutes = () => {
  return (
    <Routes>
      {/* <Route
                path="/welcome-onboard/:verificationCode"
                element={<WelcomeOnboard />}
              /> */}
      <Route
        path="/business-profile"
        element={<OnBoardLayout element={<OnBoardBusiness />} />}
      />
      <Route
        path="/more-information"
        element={<OnBoardLayout element={<OnBoardInformation />} />}
      />
      <Route
        path="/submit-information"
        element={<OnBoardLayout element={<OnBoardSubmitInformation />} />}
      />

      <Route path="/subscription" element={<Subscription />} />
      <Route
        path="/fundraiser-subscription"
        element={<FundraiserSubscription />}
      />
      <Route path="/membership-activation" element={<MembershipActivation />} />
      <Route path="/member-setup" element={<NewMemberSetup />} />
      <Route
        path="/membership-confirmation"
        element={<MembershipConfirmation />}
      />
      <Route path="/membership-activated" element={<MembershipActivated />} />
      <Route path="/fundraiser-brand/:id" element={<FundraiserBrand />} />
      <Route path="/success" element={<OrderConfirmation />} />
      <Route path="/existing-login" element={<ExistingLogin />} />
      <Route path="/user-forgetPassword" element={<UserForgetPassword />} />
      <Route path="/user-otpVerification" element={<UserOTPVerification />} />
      <Route path="/user-resetPassword" element={<UserResetPassword />} />
      <Route path="/existing-subscription" element={<ExistingSubscription />} />
      <Route path="/subscription-failed" element={<SubscriptionFailure />} />
    </Routes>
  );
};

export default UserRoutes;
