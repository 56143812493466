import React from "react";
import { useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FormBg from "../../../Assets/Images/Fundraiser/membershipActivation.svg";
import Profile from "../../../Assets/Images/Profile.svg";
import emailIcon from "../../../Assets/Icons/emailIcon.svg";
import TextInput from "../../common/SignUp/TextInput";
import FundraiserHeader from "../common/FundraiserHeader";
import FundraiserBodyHeader from "../common/FundraiserBodyHeader";
import PrimaryButton from "../../common/PrimaryButton";
import { useFormik } from "formik";
import { membershipActivationSchema } from "../../../utils/validationSchemas";
import { emailVerification } from "../../../services/authService";
import Loader from "../../common/Loader";
import { setEmail } from "../../../redux/slices/authSlice";

const Activation = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.authReducer.loading);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      // confirmEmail: "",
    },
    validationSchema: membershipActivationSchema,
    onSubmit: async (values) => {
      console.log("values", values);
      // Handle form submission
      try {
        const result = await dispatch(
          emailVerification({ email: email })
        ).unwrap();
        if (result?.success === true) {
          dispatch(setEmail({ email: email }))
          if (result?.data == true) {
            navigate("/existing-login", {
              state: {
                email: email,
              },
            });
          } else {
            navigate("/member-setup");
          }
        }
      } catch (error) {
        // handle error here
        throw error;
      }
    },
  });

  const { email, confirmEmail } = formik.values;

  const { handleChange, handleBlur, handleSubmit, errors, touched } = formik;

  return (
    <div className="pb-24">
      <FundraiserHeader />

      {loading && <Loader />}
      <div className="flex flex-col items-center">
        <FundraiserBodyHeader
          heading={"Membership Activation"}
          content={"Please enter your email address"}
          contentClass={"w-[100%] sm:w-[80%] md:w-[90%] text-[#8C8C8C]"}
        />

        <div className="relative mt-12 sm:mt-24 mb-14">
          <img src={FormBg} className="w-[280px] sm:w-[350px]"></img>

          <div className="absolute top-2 sm:top-0 w-full mt-[-14%]">
            <div className="flex justify-center">
              <img src={Profile} className="w-[80px] sm:w-[104px]"></img>
            </div>

            <div className="px-[6%] sm:px-[8%] mt-8 sm:mt-10">
              <TextInput
                Icon={emailIcon}
                name={"email"}
                value={email}
                placeholder={"Email ID"}
                inputClass={"w-full rounded-tr-[10px] rounded-br-[10px] border-none"}
                iconAreaClass={"w-[18%] bg-[#E1E1E1] rounded-tl-[10px] rounded-bl-[10px] border-none"}
                inputDivClass={"mb-3 sm:mb-4"}
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.email}
                touched={touched.email}
                autoComplete={"email"}
              />
              {/* <TextInput
                Icon={emailIcon}
                name={"confirmEmail"}
                value={confirmEmail}
                placeholder={"Confirm Email ID"}
                inputWidth={"100%"}
                iconAreaClass={"w-[18%] bg-[#E1E1E1]"}
                inputDivClass={"mb-3 sm:mb-4"}
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.confirmEmail}
                touched={touched.confirmEmail}
              /> */}

              <PrimaryButton
                className={
                  "mt-5 sm:mt-9 w-[100%] text-[18px] font-[600] h-[50px] sm:h-[45px] md:h-[50px] rounded-[10px]"
                }
                label="Continue"
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activation;
