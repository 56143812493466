import React, { useState } from "react";
import BreadCrums from "../../common/BreadCrums";
import FormLayout from "../../common/FormLayout";
import WithdrawMoney from "./WithdrawMoney";
import SecurityVerification from "./SecurityVerification";
import WithDrawSuccess from "./WithDrawSuccess";
import RightArrow from "../../../Assets/Icons/arrowRight.svg";

const Withdraw = () => {
  const items = [
    {
      title: "Dashboard",
    },
    {
      title: "Withdraw",
    },
  ];
  const [pageContent, setPageContent] = useState("withdrawMoney");

  return (
    <>
      <BreadCrums Heading="Withdraw" items={items} />
      {pageContent == "withdrawMoney" ? (
        <FormLayout
          title={"Withdraw Money"}
          content={<WithdrawMoney setPageContent={setPageContent} />}
        />
      ) : pageContent == "security" ? (
        <FormLayout
          title={"Security Verification"}
          content={<SecurityVerification setPageContent={setPageContent}/>}
          icon={RightArrow}
          cont={"withdrawMoney"}
          setPageContent={setPageContent}
        />
      ) : pageContent == "success" ? (
        <FormLayout
          title={"Withdraw to Bank"}
          content={<WithDrawSuccess setPageContent={setPageContent}/>}
          pageContent={pageContent}
        />
      ) : null}
    </>
  );
};

export default Withdraw;
