import React, { useState, useEffect } from "react";
import "./dashboard.scss";
import Frequency from "./Frequency";
import NewOffers from "./NewOffers";
import OffersChart from "./OffersChart";

const Funds = () => {
  const [frequencies, setFrequencies] = useState([
    "Today",
    "Weekly",
    "Monthly",
    "Yearly"
  ]);

  return (
    <div className="summary w-full p-4 offersChart">
      <div className="flex justify-between w-full">
        <span className="text-[20px] font-poppins font-[600] text-[black] whitespace-nowrap">
          Funds
        </span>
        <Frequency frequencies={frequencies} className='frequency sm:mt-3 sm:w-[50%] md:w-[50%] lg:w-[40%] xl:w-[30%] xl:mt-0 py-2 px-[1%] mr-[4%]'/>
      </div>
      {/* <NewOffers /> */}
      <OffersChart className="sm:my-[7%] 2xl:my-[8%] px-[3%] " barThickness={12}/>
    </div>
  );
};

export default Funds;
