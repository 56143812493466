import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BreadCrumsHeader from "../BreadCrums";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Status from "../Status";
import Delete from "../Delete";
import Edit from "../Edit";
import Pagination from "../TablePagination";
import CreateNewButton from "../CreateNewButton";
import { ReactComponent as Image } from "../../../Assets/Icons/Image.svg";
import { ReactComponent as LocationIcon } from "../../../Assets/Icons/Location.svg";
import { ReactComponent as ApprovedSubCategoryIcon } from "../../../Assets/Icons/approvedsubCategoryIcon.svg";
import { ReactComponent as SubCategoryIcon } from "../../../Assets/Icons/subCategoryIcon.svg";
import { ReactComponent as UnapprovedSubCatIcon } from "../../../Assets/Icons/unapprovedSubCatIcon.svg";
import { ReactComponent as ApprovedRulesIcon } from "../../../Assets/Icons/approvedRulesIcon.svg";
import { ReactComponent as RulesIcon } from "../../../Assets/Icons/rulesIcon.svg";
import { ReactComponent as UnapprovedRulesIcon } from "../../../Assets/Icons/unapprovedRulesIcon.svg";
import descriptionIcon from "../../../Assets/Icons/descriptionIcon.svg";
import {
  deleteOffer,
  getOffersByMerchant,
  toggleOfferStatus,
} from "../../../services/offerService";
import ImagePopup from "../ImagePopup";
import LocationPopup from "../LocationPopup";
import CancelPopup from "../CancelPopup";
import deleteImg from "../../../Assets/Images/deleteImg.svg";
import { separateDateTimeAndConvertToLocale } from "../../../utils/utilFunctions";
import Loader from "../../common/Loader";
import DetailsPopup from "../DetailsPopup";
import SubCategoryPopup from "../SubCategoryPopup";
import Confirmation from "./Confirmation";
import infoIcon from "../../../Assets/Icons/infoIcon.svg";

const MerchantOfferlist = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.offerReducer?.loading);
  const [allOffers, setAllOffers] = useState();
  const [isImageOpen, setIsImageOpen] = useState(false);
  const [isLocationOpen, setIsLocationOpen] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const [isSubCategoryOpen, setIsSubCategoryOpen] = useState(false);
  const [isRulesOpen, setIsRulesOpen] = useState(false);
  const [clickedOffer, setClickedOffer] = useState(null);
  const [infoClicked, setInfoClicked] = useState(false);

  const items = [
    {
      title: "Offers",
    },
    {
      title: "Offers List",
    },
  ];

  const columns = [
    {
      name: "No.",
    },
    {
      name: "Title",
    },
    {
      name: "Category",
    },
    {
      name: "Sub Category",
    },
    {
      name: "Rules & Regulations",
    },
    {
      name: "Start Date",
    },
    {
      name: "Start Time",
    },
    {
      name: "End Date",
    },
    {
      name: "End Time",
    },
    {
      name: "Offer Percentage",
    },
    {
      name: "Merchant Name",
    },
    {
      name: "Locations",
    },
    {
      name: "Description",
    },
    {
      name: "Image",
    },
    {
      name: "Confirmation",
    },
    {
      name: "Status",
    },
    {
      name: "Actions",
    },
  ];

  const getOffersData = async () => {
    const offersPromise = await dispatch(
      getOffersByMerchant({ page: currentPage })
    )?.unwrap();
    if (offersPromise?.success === true) {
      setAllOffers(offersPromise?.data);
      setCurrentPage(offersPromise?.currentPage);
      setTotalPages(offersPromise?.totalPages);
      setCount(offersPromise?.count);
    }
  };

  useEffect(() => {
    getOffersData();
  }, [deleteSuccess, currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleImageIconClick = (offer) => {
    setClickedOffer(offer);
    setIsImageOpen(true);
  };

  const handleCloseImage = () => {
    setIsImageOpen(false);
    setClickedOffer(null);
  };

  const handleLocationClick = (offer) => {
    setClickedOffer(offer);
    setIsLocationOpen(true);
  };

  const handleDescriptionClick = (offer) => {
    setClickedOffer(offer);
    setIsDescriptionOpen(true);
  };

  const handleSubCategoryClick = (offer) => {
    setClickedOffer(offer);
    setIsSubCategoryOpen(true);
  };

  const handleRulesClick = (offer) => {
    setClickedOffer(offer);
    setIsRulesOpen(true);
  };

  const handleCloseLocation = () => {
    setIsLocationOpen(false);
    setClickedOffer(null);
  };

  const handleCloseDescription = () => {
    setIsDescriptionOpen(false);
    setClickedOffer(null);
  };

  const handleCloseSubCategory = () => {
    setIsSubCategoryOpen(false);
    setClickedOffer(null);
  };

  const handleCloseRules = () => {
    setIsRulesOpen(false);
    setClickedOffer(null);
  };

  const handleEditClick = (id) => {
    navigate("/merchant/offer-form", {
      state: {
        id: id,
      },
    });
  };

  const handleDeleteClick = (offer) => {
    setDeleteClicked(true);
    setClickedOffer(offer);
  };

  const handleKeepOffer = () => {
    setDeleteClicked(false);
    setClickedOffer(null);
  };

  const handleDeleteOffer = async () => {
    setDeleteClicked(false);
    if (clickedOffer?._id !== null) {
      const deletePromise = await dispatch(
        deleteOffer({ id: clickedOffer?._id })
      )?.unwrap();
      if (deletePromise?.success === true) {
        setDeleteSuccess(!deleteSuccess);
        setClickedOffer(null);
      }
    }
  };

  const handleToggle = async (id) => {
    try {
      await dispatch(toggleOfferStatus({ id: id })).unwrap();
    } catch (error) {
      console.error("Error toggling location status:", error);
    }
  };

  const handleCloseInfo = () => {
    setInfoClicked(false);
    setClickedOffer(null);
  };

  const handleInfoClick = (offer) => {
    setClickedOffer(offer);
    setInfoClicked(true);
  };

  return (
    <>
      {loading === true && <Loader />}
      {clickedOffer?.thumbnailImage !== null && isImageOpen === true && (
        <ImagePopup
          image={clickedOffer?.thumbnailImage}
          handleCloseImage={handleCloseImage}
        />
      )}
      {isLocationOpen === true && (
        <LocationPopup
          locations={clickedOffer?.tempLocations}
          handleCloseLocation={handleCloseLocation}
        />
      )}
      {clickedOffer?.description !== "" && isDescriptionOpen === true && (
        <DetailsPopup
          content={clickedOffer?.description}
          handleClose={handleCloseDescription}
          icon={descriptionIcon}
          popupClassName={"w-[335px] sm:w-[470px] pb-10"}
        />
      )}
      {clickedOffer?.subCategory?.length > 0 && isSubCategoryOpen === true && (
        <SubCategoryPopup
          subCategories={clickedOffer?.subCategory}
          handleCloseSubCategory={handleCloseSubCategory}
          icon={<SubCategoryIcon className="w-[10%]" />}
          heading="Subcategories"
        />
      )}
      {clickedOffer?.ruleAndRegulation?.length > 0 && isRulesOpen === true && (
        <SubCategoryPopup
          subCategories={clickedOffer?.ruleAndRegulation}
          handleCloseSubCategory={handleCloseRules}
          icon={<RulesIcon className="w-[10%]" />}
          heading="Rules and Regulations"
        />
      )}
      <div className="flex justify-between col-span-12">
        <BreadCrumsHeader heading="Offers List" items={items} />
        <CreateNewButton
          text={"Create New Offer"}
          navigateTo={"/merchant/offer-form"}
          iconClassName="sm:left-[1%] md:left-[8%] bottom-[30%]"
        />
      </div>
      {deleteClicked == true ? (
        <CancelPopup
          handleNo={handleKeepOffer}
          handleYes={handleDeleteOffer}
          heading={"Delete Offer"}
          content={"Are you sure you want to delete this offer?"}
          image={deleteImg}
        />
      ) : null}
      {infoClicked === true && (
        <DetailsPopup
          content={clickedOffer?.reason}
          handleClose={handleCloseInfo}
          icon={infoIcon}
          popupClassName={"w-[335px] sm:w-[470px] pb-10"}
        />
      )}
      <div className=" col-span-12 mt-8">
        <TableContainer style={{ width: "100%" }}>
          <Table
            sx={{
              minWidth: "1936px",
              borderCollapse: "separate",
              borderSpacing: "0px 8px",
            }}
            aria-label="simple table"
          >
            <TableHead className="bg-[#00ADEF]">
              <TableRow
                className="h-[50px]"
                sx={{ boxShadow: "0px 4px 4px 0px #00000040" }}
              >
                {columns.map((column) => {
                  return <TableCell>{column.name}</TableCell>;
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {allOffers?.map((offer, index) => {
                return (
                  <TableRow
                    className="h-[50px]"
                    sx={{
                      boxShadow: "0px 4px 4px 0px #00000040",
                      backgroundColor:
                        (index + 1) % 2 !== 0 ? "#F5F5F5" : "#FFFFFF",
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{offer?.title}</TableCell>
                    <TableCell>{offer?.category?.title}</TableCell>
                    {/* <TableCell>{offer?.subCategory[0]?.title}</TableCell> */}
                    <TableCell>
                      {offer?.subCategory?.length > 0 ? (
                        <div className="w-[80%] flex justify-center cursor-pointer">
                          {offer?.subCategory?.some(
                            (sub) =>
                              sub?.status === "pending" ||
                              sub?.status === "rejected"
                          ) ? (
                            <UnapprovedSubCatIcon
                              className="w-[30px] h-[30px]"
                              onClick={() => handleSubCategoryClick(offer)}
                            />
                          ) : (
                            <ApprovedSubCategoryIcon
                              className="w-[30px] h-[30px]"
                              onClick={() => handleSubCategoryClick(offer)}
                            />
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell>
                      {offer?.ruleAndRegulation?.length > 0 ? (
                        <div className="w-[80%] flex justify-center cursor-pointer">
                          {offer?.ruleAndRegulation?.some(
                            (sub) =>
                              sub?.status === "pending" ||
                              sub?.status === "rejected"
                          ) ? (
                            <UnapprovedRulesIcon
                              className="w-[30px] h-[30px]"
                              onClick={() => handleRulesClick(offer)}
                            />
                          ) : (
                            <ApprovedRulesIcon
                              className="w-[30px] h-[30px]"
                              onClick={() => handleRulesClick(offer)}
                            />
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell>
                      {
                        separateDateTimeAndConvertToLocale(offer?.startDate)
                          ?.localDate
                      }
                    </TableCell>
                    <TableCell>{offer?.tradingHours[0]?.startTime}</TableCell>
                    <TableCell>
                      {
                        separateDateTimeAndConvertToLocale(offer?.endDate)
                          ?.localDate
                      }
                    </TableCell>
                    <TableCell>{offer?.tradingHours[0]?.endTime}</TableCell>
                    <TableCell>{`${offer?.discountRange}%`}</TableCell>
                    <TableCell>{offer?.merchant?.legalName}</TableCell>
                    <TableCell>
                      <div className="w-[80%] flex justify-center cursor-pointer">
                        <LocationIcon
                          onClick={() => handleLocationClick(offer)}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="w-[80%] flex justify-center cursor-pointer">
                        <img
                          src={descriptionIcon}
                          className="w-[30px] h-[30px] cursor-pointer"
                          onClick={() => handleDescriptionClick(offer)}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      {offer?.thumbnailImage !== undefined ? (
                        <img
                          src={offer?.thumbnailImage}
                          className="h-[24px] w-[24px] cursor-pointer border border-[#00ADEF] rounded"
                          onClick={() => handleImageIconClick(offer)}
                        />
                      ) : (
                        <Image
                          className="cursor-pointer"
                          onClick={() => handleImageIconClick(offer)}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <Confirmation
                        status={offer?.status}
                        handleInfoClick={() => handleInfoClick(offer)}
                      />
                    </TableCell>
                    <TableCell>
                      <Status
                        isEnabled={offer?.isEnabled}
                        handleToggle={() => handleToggle(offer?._id)}
                      />
                    </TableCell>
                    <TableCell>
                      <div className="flex justify-start gap-[4%]">
                        {offer?.status !== "approved" && (
                          <Edit onClick={() => handleEditClick(offer?._id)} />
                        )}
                        <Delete onClick={() => handleDeleteClick(offer)} />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default MerchantOfferlist;
