import React, { useEffect, useState } from "react";
import PrimaryButton from "../../PrimaryButton";
import GreyButton from "../../GreyButton";
import MobileViews from "./MobileViews";
import {
  createOffer,
  getNearestOffersByMerchant,
  getOfferById,
} from "../../../../services/offerService";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getLocationById } from "../../../../services/franchiseService";
import Loader from "../../Loader";

const OfferReview = ({ handlePrevious, editOfferData, setPayloadData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const offerId = useSelector(
    (state) => state?.offerReducer?.createdOffer?._id
  );
  const loading = useSelector((state) => state?.offerReducer?.loading);
  const [offerData, setOfferData] = useState(null);
  const [firstLocation, setFirstLocation] = useState(null);
  const [merchantData, setMerchantData] = useState(null);

  const getOfferData = async () => {
    const getPromise = await dispatch(getOfferById({ id: offerId }))?.unwrap();
    if (getPromise?.success === true) {
      setOfferData(getPromise?.data);
    }
  };

  const getLoctionData = async () => {
    const location = await dispatch(
      getLocationById({
        id: offerData?.tempLocations[0]?._id,
      })
    )?.unwrap();
    if (location?.success === true) {
      setFirstLocation(location?.data);
    }
  };

  const getMerchantData = async () => {
    const payload = {
      lat: firstLocation?.googlePlaceId?.lat,
      lng: firstLocation?.googlePlaceId?.lng,
      merchantId: offerData?.merchant?._id,
    };
    const getPromise = await dispatch(
      getNearestOffersByMerchant(payload)
    )?.unwrap();
    if (getPromise?.success === true) {
      setMerchantData(getPromise?.data);
    }
  };

  useEffect(() => {
    getOfferData();
  }, []);

  useEffect(() => {
    if (offerData !== null) {
      getLoctionData();
    }
  }, [offerData]);

  useEffect(() => {
    if (offerData !== null && firstLocation !== null) {
      getMerchantData();
    }
  }, [offerData, firstLocation]);

  const savePayload = {
    category: offerData?.category?._id,
    subCategory: offerData?.subCategory?.map((s) => s?._id),
    title: offerData?.title,
    offerType: offerData?.offerType,
    isFeatured: offerData?.isFeatured,
    isHot: offerData?.isHot,
    discountRange: offerData?.discountRange,
    moneySaved: offerData?.moneySaved,
    thumbnailImage: offerData?.thumbnailImage,
    previewImages: offerData?.previewImages,
    description: offerData?.description,
    redemptionDays: offerData?.redemptionDays,
    startDate: offerData?.startDate,
    endDate: offerData?.endDate,
    tradingHours: offerData?.tradingHours,
    ruleAndRegulation: offerData?.ruleAndRegulation?.map((r) => r?._id),
    locations: offerData?.tempLocations?.map((l) => l?._id),
    discountTitle: "Up to 50% off on selected items",
    id: offerData?._id,
    isCreated: false,
  };

  useEffect(() => {
    setPayloadData(savePayload);
  }, [offerData]);

  const handleSubmit = async () => {
    const payload = {
      category: offerData?.category?._id,
      subCategory: offerData?.subCategory?.map((s) => s?._id),
      title: offerData?.title,
      offerType: offerData?.offerType,
      isFeatured: offerData?.isFeatured,
      isHot: offerData?.isHot,
      discountRange: offerData?.discountRange,
      moneySaved: offerData?.moneySaved,
      thumbnailImage: offerData?.thumbnailImage,
      previewImages: offerData?.previewImages,
      description: offerData?.description,
      redemptionDays: offerData?.redemptionDays,
      startDate: offerData?.startDate,
      endDate: offerData?.endDate,
      tradingHours: offerData?.tradingHours,
      ruleAndRegulation: offerData?.ruleAndRegulation?.map((r) => r?._id),
      locations: offerData?.tempLocations?.map((l) => l?._id),
      discountTitle: "Up to 50% off on selected items",
      id: offerData?._id,
      isCreated: true,
    };
    const createPromise = await dispatch(createOffer(payload))?.unwrap();
    if (createPromise?.success === true) {
      navigate("/merchant/offer-list");
    }
  };

  return (
    <div className="w-full flex flex-col items-center justify-center mt-20">
      {loading === true && <Loader />}
      <div className="sm:w-[100%] md:w-[80%] lg:w-[70%] xl:w-[55%] offerform">
        <p className="font-poppins font-[500] sm:text-[12px] md:text-[14px] lg:text-[16px] text-[#303030]">
          Review and Approve Offer
        </p>
        <p className="font-poppins font-[400] sm:text-[10px] md:text-[12px] text-[#767676] mb-5">
          Customers will visit your business location(s) to redeem your campaign
          offer
        </p>
        <MobileViews offerData={offerData} merchantData={merchantData}/>
        <div className="w-full text-end mt-20">
          <GreyButton
            className="w-[40%] lg:w-[25%] h-[45px] rounded-[5px] text-[16px] font-[500] font-montserrat"
            label="Previous"
            onClick={handlePrevious}
          />
          <PrimaryButton
            className={
              "w-[40%] lg:w-[25%] h-[45px] rounded-[5px] text-[16px] font-[500] font-montserrat ms-2"
            }
            label={editOfferData !== null ? "Resubmit":"Submit"}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default OfferReview;
