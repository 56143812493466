import React from "react";
import "./common.scss";
import { ReactComponent as CheckedBox } from "../../Assets/Icons/checkedBox.svg";
import { ReactComponent as UncheckedBox } from "../../Assets/Icons/uncheckedBox.svg";

const Checkbox = ({ divClassName, text, checked, onChecked, textClass, UncheckedIcon }) => {
  return (
    <>
      <div className={divClassName}>
        {checked == true ? (
          <CheckedBox onClick={onChecked} className="checkbox" />
        ) : (
          UncheckedIcon ? <UncheckedIcon onClick={onChecked} className="checkbox" />:<UncheckedBox onClick={onChecked} className="checkbox" />
        )}
        <label className={textClass}>{text}</label>
      </div>
    </>
  );
};

export default Checkbox;
