import React, { useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../Assets/Icons/popupCloseIcon.svg";
import "./common.scss";
import CheckboxSelection from "./CheckboxSelection";
import PrimaryButton from "./PrimaryButton";
import { useFormik } from "formik";
import InputTextArea from "./InputTextArea";
import { rejectedReasonSchema } from "../../utils/validationSchemas";
import { useLocation } from "react-router-dom";
import { ReactComponent as DeclinedIcon } from "../../Assets/Icons/declinedIcon.svg";
import { ReactComponent as PendingIcon } from "../../Assets/Icons/pendingIcon.svg";

const SubCategoryPopup = ({
  subCategories,
  handleCloseSubCategory,
  icon,
  heading,
  setIsApprovalSubmitted,
  selectedSubCategoriesIds,
  setSelectedSubCategoriesIds,
  setNonSelectedSubCatIds,
  setReason,
}) => {
  const location = useLocation();
  const currentPath = location?.pathname;
  const [showReasonBox, setShowReasonBox] = useState(false);
  const [subCate, setSubCate] = useState([]);
  const pendingSub = subCategories?.filter((s) => s?.status == "pending");
  const checkOptions = pendingSub?.map((p) => p?.title);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);

  const formik = useFormik({
    initialValues: {
      reason: "",
    },
    validationSchema: showReasonBox === true ? rejectedReasonSchema : "",
    onSubmit: async (values) => {
      if (checkOptions?.length !== subCate?.length) {
        setShowReasonBox(true);
        if (values?.reason !== "") {
          setIsApprovalSubmitted(true);
        }
      } else {
        setShowReasonBox(false);
        setIsApprovalSubmitted(true);
      }
    },
  });

  const { reason } = formik.values;

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (checkOptions?.length === subCate?.length) {
      setShowReasonBox(false);
      setFieldValue("reason", "");
    }
  }, [subCate]);

  useEffect(() => {
    if (reason !== "") {
      setReason(reason);
    }
  }, [reason]);

  const handleSubChecked = (op) => {
    const isSelected = selectedSubCategories?.find((s) => s?.title === op);

    if (isSelected) {
      const updatedSubCategories = selectedSubCategories?.filter(
        (s) => s?.title !== op
      );
      setSelectedSubCategories(updatedSubCategories);
      setSubCate(updatedSubCategories?.map((u) => u?.title));

      const updatedIds = selectedSubCategoriesIds?.filter((subCategoryId) => {
        const sub = subCategories?.find((subCat) => subCat.title === op);
        return sub && sub?._id !== subCategoryId;
      });
      setSelectedSubCategoriesIds(updatedIds);
    } else {
      const sub = subCategories?.find((subCat) => subCat?.title === op);

      if (sub) {
        setSelectedSubCategories((prev) => [...prev, sub]);
        setSubCate((prev) => [...prev, sub?.title]);
        setSelectedSubCategoriesIds((prev) => [...prev, sub._id]);
      }
    }
  };

  useEffect(() => {
    if (!currentPath?.includes("merchant/")) {
      const nonSelectedIds = pendingSub
        ?.filter((sub) => !selectedSubCategoriesIds?.includes(sub?._id))
        ?.map((sub) => sub?._id);
      setNonSelectedSubCatIds(nonSelectedIds);
    }
  }, [pendingSub?.length, selectedSubCategoriesIds?.length]);

  return (
    <div className="popup-container">
      <div
        className={`popup flex flex-col justify-evenly items-center sm:w-[470px] bg-white rounded-[10px] drop-shadow-[0_3px_3px_rgba(0,0,0,0.25)] relative pt-5 ${
          pendingSub?.length <= 3
            ? "pb-8"
            : subCategories?.length > 3
            ? "pb-8 h-[500px] overflow-y-scroll"
            : "pb-8"
        }`}
      >
        <div className="h-[3%]">
          <CloseIcon
            className="absolute top-5 right-5 cursor-pointer"
            onClick={handleCloseSubCategory}
          />
        </div>
        <div className="h-[92%] w-full flex flex-col justify-start gap-4">
          <span className="font-poppins font-[500] text-[18px] text-left pl-5">
            {heading}
          </span>
          <div className="flex flex-col justify-start">
            {pendingSub?.length > 0 && !currentPath?.includes("merchant/") ? (
              <>
                <p className=" pl-5 mb-2 lg:text-[14px] sm:text-[12px] font-[500] font-poppins text-[#303030]">
                  Add Suggestion
                </p>
                <div className="w-full flex justify-center items-center">
                  <div className="w-full mx-5 mb-5 rounded-[10px] border border-[#00ADEF] border-opacity-50 p-4 flex flex-col justify-between shadow-[0_4px_10px_0_rgba(0,0,0,0.1)]">
                    <CheckboxSelection
                      options={checkOptions}
                      divClassName="mt-1 mb-2"
                      internalDivClass="flex flex-col items-start"
                      secondRadioClass="mt-3"
                      textClass="sm:text-[10px] md:text-[12px] lg:text-[14px] font-[400] text-[#303030] ml-4"
                      checkClass="flex items-center"
                      label={""}
                      width={"100%"}
                      name="subCate"
                      value={subCate}
                      // error={errors.titles}
                      // touched={touched.titles}
                      // setFieldValue={setFieldValue}
                      onChecked={handleSubChecked}
                      postIcon={null}
                    />
                    {showReasonBox === true && (
                      <InputTextArea
                        label={""}
                        divClassName="relative mt-5 w-full"
                        placeholder="Give reason for rejected suggestions"
                        labelClassName=""
                        textareaClassName="w-full h-[130px] p-2 rounded-[10px] bg-[#F7F7F7] border border-[#EC826E] text-[#303030] lg:text-[14px] sm:text-[12px] font-[400] outline-none resize-none font-poppins"
                        name="reason"
                        value={reason}
                        handleBlur={handleBlur}
                        error={errors.reason}
                        touched={touched.reason}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    <div className="w-full flex justify-end items-end">
                      <PrimaryButton
                        className={
                          "sm:w-[20%] md:w-[25%] lg:w-[25%] font-poppins sm:text-[10px] md:text-[12px] text-center font-[500] sm:h-[30px] md:h-[35px] rounded-[10px]"
                        }
                        label="Submit"
                        onClick={handleSubmit}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : pendingSub?.length > 0 && currentPath?.includes("merchant/") ? (
              <ul
                className={`flex flex-col gap-2 w-[100%] px-5 ${
                  subCategories?.length > 3 ? "overflow-y-scroll" : ""
                }`}
              >
                {subCategories?.map((sub, index) => (
                  <div
                    className={`relative flex justify-center items-center w-[100%] gap-4 rounded-[10px] bg-white shadow-[0_4px_10px_0px_rgba(0,0,0,0.1)] py-5 px-2 ${
                      sub?.status === "pending"
                        ? "border border-yellow-400"
                        : sub?.status === "rejected"
                        ? "border border-red-400"
                        : ""
                    }`}
                  >
                    {sub?.status === "pending" ? (
                      <PendingIcon className="absolute right-2 top-2" />
                    ) : sub?.status === "rejected" ? (
                      <DeclinedIcon className="absolute right-2 top-2" />
                    ) : null}
                    {icon}
                    <li className="w-[90%] font-poppins font-[400] text-[14px] text-[#303030] pr-1">
                      {sub?.title}
                    </li>
                  </div>
                ))}
              </ul>
            ) : (
              <ul
                className={`flex flex-col gap-2 w-[100%] px-5 ${
                  subCategories?.length > 3 ? "overflow-y-scroll" : ""
                }`}
              >
                {subCategories?.map((sub, index) => (
                  <div
                    className={`relative flex justify-center items-center w-[100%] gap-4 rounded-[10px] bg-white shadow-[0_4px_10px_0px_rgba(0,0,0,0.1)] py-5 px-2 ${
                      sub?.status === "rejected" ? "border border-red-400" : ""
                    }`}
                  >
                    {sub?.status === "rejected" &&
                      <DeclinedIcon className="absolute right-2 top-2" />}
                    {icon}
                    <li className="w-[90%] font-poppins font-[400] text-[14px] text-[#303030] pr-1">
                      {sub?.title}
                    </li>
                  </div>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubCategoryPopup;
