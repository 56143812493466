import React, { useEffect, useState } from "react";
import SelectOption from "./SelectOption";
import { ReactComponent as AddIcon } from "../../../Assets/Icons/addNewIcon.svg";
import Input from "../FormInputs";
import { useDispatch, useSelector } from "react-redux";
import { createOffer, createSubCategory } from "../../../services/offerService";
import { useFormik } from "formik";
import PrimaryButton from "../PrimaryButton";
import GreyButton from "../GreyButton";
import { subCategorySchema } from "../../../utils/validationSchemas";
import Loader from "../Loader";
import declinedIcon from "../../../Assets/Icons/declinedIcon.svg";
import pendingIcon from "../../../Assets/Icons/pendingIcon.svg";
import toast from "../../../utils/toast";

const SubCategorySelection = ({
  categoryId,
  subCategories,
  setSubCategories,
  stepIndex,
  setStepIndex,
  handlePrevious,
  setPayloadData,
  editOfferData,
  isEdit,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.offerReducer?.loading);
  const offerData = useSelector((state) => state?.offerReducer?.createdOffer);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [selectedSubCategoriesIds, setSelectedSubCategoriesIds] = useState([]);
  const [addClicked, setAddClicked] = useState(false);
  const [newSubCategory, setNewSubCategory] = useState(null);
  const [subNames, setSubNames] = useState([]);
  const [approvedSubCategories, setApprovedSubCategories] = useState(
    subCategories?.filter((sub) => sub?.status === "approved")
  );
  const [offerPendingSub, setOfferPendingSub] = useState([]);

  useEffect(() => {
    if (editOfferData !== null && isEdit == true) {
      editOfferData?.subCategory?.map((s) => {
        if (s?.status === "pending" || s?.status === "rejected") {
          setOfferPendingSub((prev) => [...prev, s]);
          handleClick(s?._id, s?.title);
        } else {
          handleClick(s?._id, s?.title);
        }
      });
    } else {
      offerData?.subCategory?.map((s) => {
        if (s?.status === "pending" || s?.status === "rejected") {
          setOfferPendingSub((prev) => [...prev, s]);
          handleClick(s?._id, s?.title);
        } else {
          handleClick(s?._id, s?.title);
        }
      });
    }
  }, [offerData, editOfferData]);

  const initialValues = {
    subCategory: [],
  };

  const payload = {
    category: categoryId,
    subCategory: selectedSubCategoriesIds,
    id: offerData?._id,
    isCreated: false,
  };

  useEffect(() => {
    setPayloadData(payload);
  }, [categoryId, selectedSubCategoriesIds, offerData?._id]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: subCategorySchema,

    onSubmit: async (values) => {
      console.log("create values", values);

      const hasRejectedSubCategory = selectedSubCategories?.some(
        (subCat) => subCat.status === "rejected"
      );
      if (hasRejectedSubCategory) {
        toast?.error("You cannot proceed with rejected sub category selected.");
        return;
      }
      const createPromise = await dispatch(createOffer(payload))?.unwrap();
      if (createPromise?.success === true) {
        setStepIndex(stepIndex + 1);
      }
    },
  });

  const { subCategory } = formik.values;

  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    setValues,
  } = formik;

  const handleClick = (id, title) => {
    const isSelected = selectedSubCategories.find(
      (subCat) => subCat?._id === id
    );

    if (isSelected) {
      const updatedSubCategories = selectedSubCategories.filter(
        (subCat) => subCat?._id !== id
      );
      setSelectedSubCategories(updatedSubCategories);

      const updatedIds = selectedSubCategoriesIds.filter(
        (subCategoryId) => subCategoryId !== id
      );
      setSelectedSubCategoriesIds(updatedIds);

      const updatedNames = subNames.filter((t) => t !== title);
      setSubNames(updatedNames);
      setFieldValue("subCategory", updatedNames);
    } else {
      const subCategory = subCategories?.find((subCat) => subCat?._id === id);
      setSelectedSubCategories((prev) => [...prev, subCategory]);
      setSelectedSubCategoriesIds((prev) => [...prev, subCategory?._id]);
      setSubNames((prev) => [...prev, subCategory?.title]);
      setFieldValue("subCategory", [...subNames, subCategory?.title]);
    }
  };

  const isSubCategoryClicked = (subCat) => {
    return selectedSubCategories.find(
      (selectedCat) => selectedCat?._id === subCat?._id
    );
  };

  const handleAddClick = () => {
    setAddClicked(true);
  };

  const handleNewSubCategoryChange = (e) => {
    setNewSubCategory(e.target.value);
  };

  const handleNewSubCategorySubmit = async () => {
    if (newSubCategory !== "") {
      const payload = {
        title: newSubCategory,
        status: true,
        category: categoryId,
        offer: offerData?._id,
      };
      const createPromise = await dispatch(
        createSubCategory(payload)
      )?.unwrap();
      if (createPromise?.success === true) {
        setOfferPendingSub((prev) => [...prev, createPromise?.data]);
        setSubCategories((prev) => [...prev, createPromise?.data]);
        setAddClicked(false);
        setSelectedSubCategories((prev) => [...prev, createPromise?.data]);
        setSelectedSubCategoriesIds((prev) => [
          ...prev,
          createPromise?.data?._id,
        ]);
        setSubNames((prev) => [...prev, createPromise?.data?.title]);
        setFieldValue("subCategory", [...subNames, createPromise?.data?.title]);
        setNewSubCategory("");
      }
    }
  };

  return (
    <div className="w-full flex flex-col items-center justify-center mt-20">
      {loading === true && <Loader />}
      <div className="sm:w-[100%] md:w-[80%] lg:w-[70%] xl:w-[55%] ">
        <p className="font-poppins font-[500] sm:text-[12px] md:text-[14px] lg:text-[16px] text-[#303030]">
          Which of the below does this offer relate to?
        </p>
        <p className="font-poppins font-[400] sm:text-[10px] md:text-[12px] text-[#767676]">
          Select all that apply
        </p>
        <div className="mt-5">
          {approvedSubCategories?.length > 0 &&
            approvedSubCategories?.map((subCat) => (
              <SelectOption
                icon={null}
                title={subCat?.title}
                clicked={isSubCategoryClicked(subCat)}
                handleClick={() => handleClick(subCat?._id, subCat?.title)}
                postIcon={null}
              />
            ))}
          {offerPendingSub?.length > 0 &&
            offerPendingSub?.map((subCat) => (
              <div key={subCat?._id}>
                {subCat?.status == "rejected" && (
                  <SelectOption
                    icon={null}
                    title={subCat?.title}
                    clicked={isSubCategoryClicked(subCat)}
                    handleClick={() => handleClick(subCat?._id, subCat?.title)}
                    postIcon={declinedIcon}
                  />
                )}
                {subCat?.status == "pending" && (
                  <SelectOption
                    icon={null}
                    title={subCat?.title}
                    clicked={isSubCategoryClicked(subCat)}
                    handleClick={() => handleClick(subCat?._id, subCat?.title)}
                    postIcon={pendingIcon}
                  />
                )}
              </div>
            ))}
          {approvedSubCategories?.length == 0 &&
            offerPendingSub?.length == 0 &&
            offerPendingSub?.length == 0 && (
              <p className="font-poppins font-[500] sm:text-[12px] md:text-[14px] lg:text-[16px] text-[#303030] text-center">
                The Selected Category does not have any sub category to
                select!!!
              </p>
            )}
        </div>
        {errors?.subCategory && touched?.subCategory ? (
          <span className="text-red-600 sm:text-[10px] md:text-[12px] font-[600] ms-1">
            {errors?.subCategory}
          </span>
        ) : null}
        {addClicked && (
          <>
            <Input
              type="text"
              placeholder="Add new service"
              width="100%"
              radius="5px"
              InputDivClass="w-[100%] mt-8"
              name="newSubCategory"
              value={newSubCategory}
              handleChange={handleNewSubCategoryChange}
            />
            <p className="font-poppins font-[400] sm:text-[10px] md:text-[12px] text-[#818181] text-start">
              Request will be send to admin for approval.
            </p>
            <div className="w-full text-end">
              <PrimaryButton
                className={
                  "w-[40%] lg:w-[25%] h-[45px] rounded-[5px] text-[16px] font-[500] font-montserrat ms-2"
                }
                label="Submit"
                onClick={handleNewSubCategorySubmit}
              />
            </div>
          </>
        )}
        <div
          className="mt-5 flex items-center gap-2 cursor-pointer"
          onClick={handleAddClick}
        >
          <AddIcon />
          <p className="font-poppins font-[400] sm:text-[12px] md:text-[14px] text-[#00ADEF] text-center leading-[18px]">
            Add another type of service
          </p>
        </div>
        <div className="w-full text-end mt-20">
          <GreyButton
            className="w-[40%] lg:w-[25%] h-[45px] rounded-[5px] text-[16px] font-[500] font-montserrat"
            label="Previous"
            onClick={handlePrevious}
          />
          <PrimaryButton
            className={
              "w-[40%] lg:w-[25%] h-[45px] rounded-[5px] text-[16px] font-[500] font-montserrat ms-2"
            }
            label="Next"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default SubCategorySelection;
