import React, { useEffect, useState } from "react";
import { fundraiserGettingStartedSchema } from "../../../utils/validationSchemas";
import { useFormik } from "formik";
import Input from "../../common/FormInputs";
import Selector from "../../common/Selector";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as DownArrow } from "../../../Assets/Icons/greyDownArrow.svg";
import GoogleMaps from "../../common/GoogleMaps";
import InputTextArea from "../../common/InputTextArea";
import PrimaryButton from "../../common/PrimaryButton";
import PhoneFormInput from "../../common/PhoneInput";
import {
  createReason,
  getReasons,
  sendFundraiserInvite,
} from "../../../services/onboardingService";
import msgIcon from "../../../Assets/Icons/envelopIcon.svg";
import CheckboxSelection from "../../common/CheckboxSelection";
import { getFundraiserCategories } from "../../../services/fundraiserService";
import Loader from "../../common/Loader";
import DetailsPopup from "../../common/DetailsPopup";

const GettingStarted = () => {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [placeId, setPlaceId] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const [categoryIds, setCategoryIds] = useState([]);
  const [emailSent, setEmailSent] = useState(false);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [selectedReasonsIds, setSelectedReasonsIds] = useState([]);
  const [allReasons, setAllReasons] = useState([]);

  const loading = useSelector((state) => state?.onboardingReducer?.loading);

  const getCategoriesData = async () => {
    const categoryPromise = await dispatch(getFundraiserCategories())?.unwrap();
    if (categoryPromise?.success === true) {
      console.log("categoryPromise?.data", categoryPromise?.data);
      setCategories(categoryPromise?.data);
    }
  };

  const getReasonsData = async () => {
    const reasonsPromise = await dispatch(
      getReasons({ isApproved: true })
    )?.unwrap();
    if (reasonsPromise?.success === true) {
      setAllReasons(reasonsPromise?.data);
    }
  };

  const fundraiserCategoryOptions = categories?.map((cat) => ({
    value: cat?.title ?? "abc",
    label: cat?.title ?? "dsc",
  }));

  useEffect(() => {
    getCategoriesData();
    getReasonsData();
  }, []);

  const checkOptions = allReasons.map((reason) => reason.reason);

  // Adding the static "Other" option
  checkOptions.push("Other");

  const initialValues = {
    fundraiserName: "",
    fundraiserAddress: "",
    websiteUrl: "",
    personName: "",
    personContact: "",
    email: "",
    fundraiserCategory: [],
    aboutOrganization: "",
    reasons: [],
    newReason: null,
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: fundraiserGettingStartedSchema,

    onSubmit: async (values) => {
      console.log("started create values", values);
      if (values?.newReason !== null) {
        const createPayload = {
          reason: values?.newReason,
        };
        const createPromise = await dispatch(
          createReason(createPayload)
        )?.unwrap();
        if (createPromise?.success === true) {
          const payload = {
            fundraiserName: values?.fundraiserName,
            googlePlaceId: placeId,
            website: values?.websiteUrl,
            contactPersonName: values?.personName,
            contactPersonNumber: values?.personContact,
            email: values?.email,
            category: categoryIds,
            description: values?.aboutOrganization,
            reason: [...selectedReasonsIds, createPromise?.data?._id],
          };
          const sendPromise = await dispatch(
            sendFundraiserInvite(payload)
          )?.unwrap();
          if (sendPromise?.success === true) {
            setEmailSent(true);
          }
        }
      } else {
        const payload = {
          fundraiserName: values?.fundraiserName,
          googlePlaceId: placeId,
          website: values?.websiteUrl,
          contactPersonName: values?.personName,
          contactPersonNumber: values?.personContact,
          email: values?.email,
          category: categoryIds,
          description: values?.aboutOrganization,
          reason: selectedReasonsIds,
        };
        const sendPromise = await dispatch(
          sendFundraiserInvite(payload)
        )?.unwrap();
        if (sendPromise?.success === true) {
          setEmailSent(true);
        }
      }
    },
  });

  const {
    fundraiserName,
    fundraiserAddress,
    websiteUrl,
    personName,
    personContact,
    email,
    fundraiserCategory,
    aboutOrganization,
    reasons,
    newReason,
  } = formik.values;

  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    setValues,
  } = formik;

  const handleLocationClick = () => {
    setShowMap(true);
  };

  const handleLocationSelect = (selectedLocation) => {
    setFieldValue("fundraiserAddress", selectedLocation?.placeName);
    setPlaceId(selectedLocation?.placeId);
  };

  const handleCategoryChange = (e) => {
    if (e) {
      setFieldValue("fundraiserCategory", e);
    }
  };

  useEffect(() => {
    if (fundraiserCategory?.length !== 0 && categories?.length !== 0) {
      const catIds = fundraiserCategory?.map((fundraiserCategoryName) => {
        const matchingCategories = categories?.filter(
          (cat) => cat?.title === fundraiserCategoryName
        );
        return matchingCategories?.map((cat) => cat?._id);
      });
      setCategoryIds(catIds?.flat());
    }
  }, [fundraiserCategory, categories]);

  const handleReasonChecked = (op) => {
    if (op === "Other") {
      const isSelected = reasons.find((v) => v === op);
      if (isSelected) {
        const updatedValues = reasons?.filter((rule) => rule !== op);
        setFieldValue("reasons", updatedValues);
      } else {
        setFieldValue("reasons", [...reasons, op]);
      }
    } else {
      const isSelected = selectedReasons.find((reas) => reas?.reason === op);

      if (isSelected) {
        const updatedReasons = selectedReasons.filter(
          (reas) => reas?.reason !== op
        );
        setSelectedReasons(updatedReasons);
        setFieldValue(
          "reasons",
          updatedReasons?.map((u) => u?.reason)
        );

        const updatedIds = selectedReasonsIds.filter((reasonId) => {
          const reason = allReasons.find((subCat) => subCat.reason === op);
          return reason && reason._id !== reasonId;
        });
        setSelectedReasonsIds(updatedIds);
      } else {
        const reason = allReasons.find((subCat) => subCat.reason === op);

        if (reason) {
          setSelectedReasons((prev) => [...prev, reason]);
          setFieldValue("reasons", [...reasons, reason?.reason]);
          setSelectedReasonsIds((prev) => [...prev, reason._id]);
        }
      }
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="w-full py-10 h-screen flex flex-col items-center offerform">
        {emailSent === true && (
          <DetailsPopup
            content={
              "Please review your email for the invitation to join Swoodle."
            }
            icon={msgIcon}
            popupClassName={"w-[335px] sm:w-[470px] pb-10"}
          />
        )}
        <p className="font-poppins font-[500] sm:text-[18px] md:text-[24px] lg:text-[32px] text-[#303030]">
          Get Started with Swoodle Today
        </p>
        <p className="font-poppins font-[400] sm:text-[12px] md:text-[14px] lg:text-[18px] text-[#767676] leading-[27px] mt-8">
          Fundraising Made Easy!
        </p>
        <p className="font-poppins font-[400] sm:text-[12px] md:text-[14px] lg:text-[18px] text-[#767676] leading-[27px] mb-5">
          Create your account and start your journey
        </p>
        <div className="flex flex-col items-center sm:w-[70%] md:w-[60%] lg:w-[50%] xl:w-[45%] 2xl:w-[40%] getStarted">
          <Input
            type="text"
            label=""
            placeholder="Fundraiser Name"
            width="100%"
            radius="5px"
            InputDivClass="w-full mt-4"
            name="fundraiserName"
            value={fundraiserName}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors.fundraiserName}
            touched={touched.fundraiserName}
          />
          <Input
            type="text"
            label=""
            placeholder="Fundraiser Address"
            width="100%"
            radius="5px"
            InputDivClass="w-full mt-4"
            name="fundraiserAddress"
            value={fundraiserAddress}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors.fundraiserAddress}
            touched={touched.fundraiserAddress}
            onClick={handleLocationClick}
          />
          {showMap && (
            <GoogleMaps
              onLocationSelect={handleLocationSelect}
              searchContainerClass="sm:w-[45%] md:w-[48%] lg:w-[53%] xl:w-[58%] 2xl:w-[59%] sm:left-[42%] md:left-[40%] lg:left-[36%] xl:left-[32%] 2xl:left-[31%]"
            />
          )}
          <Input
            type="text"
            label=""
            placeholder="Website URL"
            width="100%"
            radius="5px"
            InputDivClass="w-full mt-4"
            name="websiteUrl"
            value={websiteUrl}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors.websiteUrl}
            touched={touched.websiteUrl}
          />
          <Input
            type="text"
            label=""
            placeholder="Contact Person Name"
            width="100%"
            radius="5px"
            InputDivClass="w-full mt-4"
            name="personName"
            value={personName}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors.personName}
            touched={touched.personName}
          />
          <PhoneFormInput
            label={""}
            divClassName="w-full"
            name="personContact"
            value={personContact}
            handleBlur={handleBlur}
            error={errors.personContact}
            touched={touched.personContact}
            setFieldValue={setFieldValue}
            inputStyle={{
              width: "100%",
              border: "none",
            }}
            buttonStyle={{ border: "none" }}
            buttonClass="!rounded-l-[5px] !bg-[#D9D9D9] md:!w-[13%] sm:!w-[15%] lg:!w-[9%] xl:!w-[7%] 2xl:!w-[6%]"
            containerClass="!rounded-[5px] border border-[#D9D9D9]"
            inputClass="lg:!h-[45px] md:!h-[45px] !rounded-[5px] !bg-[#FFFFFF] !text-[#303030] lg:!text-[16px] sm:!text-[14px] !font-[400] !outline-none !font-poppins md:!pl-[17%] sm:!pl-[17%] lg:!pl-[11%] xl:!pl-[9%] 2xl:!pl-[8%]"
            labelClass="mb-5"
            errorClass={
              "text-red-600 sm:text-[10px] md:text-[12px] font-[600] ms-1"
            }
          />
          <Input
            type="text"
            label=""
            placeholder="Email Address"
            width="100%"
            radius="5px"
            InputDivClass="w-full mt-4"
            name="email"
            value={email}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors.email}
            touched={touched.email}
          />
          <Selector
            value={fundraiserCategory}
            label={""}
            labelClassName="lg:text-[14px] sm:text-[12px] font-[400] text-[#303030] mb-2 font-poppins lg:leading-[27px] sm:leading-[21px]"
            divClassName="mt-4 w-full multipleField"
            selectClassName="w-full rounded-[5px] bg-[#FFFFFF] text-[#303030] lg:text-[16px] sm:text-[10px] font-[400] outline-none !font-poppins !focus:outline-none"
            flexClassName="gap-1"
            options={fundraiserCategoryOptions}
            suffixIcon={<DownArrow />}
            placeholder={"Select Fundraiser Category"}
            onChange={handleCategoryChange}
            handleBlur={handleBlur}
            error={errors.fundraiserCategory}
            touched={touched.fundraiserCategory}
            multiple={true}
          />
          <InputTextArea
            label={"Tell more about your organization"}
            placeholder={"Please tell us about your organisation cause..."}
            divClassName="relative mt-4 w-full"
            labelClassName="invitationLabels"
            textareaClassName="w-full h-[150px] px-[1%] pt-2 rounded-[5px] bg-[#FFFFFF] border border-[#D9D9D9] text-[#303030] lg:text-[14px] sm:text-[12px] font-[400] outline-none resize-none font-poppins"
            name="aboutOrganization"
            value={aboutOrganization}
            handleBlur={handleBlur}
            error={errors.aboutOrganization}
            touched={touched.aboutOrganization}
            setFieldValue={setFieldValue}
          />
          <p className="mt-4 invitationLabels text-start w-full">Reasons</p>
          <div className="px-4 py-3 rounded-[5px] bg-[#FFFFFF] border border-[#D9D9D9] w-full flex flex-col justify-start items-start">
            <p className="lg:text-[14px] sm:text-[12px] font-[400] font-poppins text-[#AAAAAA] tracking-[2%]">
              Please share with us some reasons why you are looking for help and
              support through the Swoodle network?
            </p>
            <CheckboxSelection
              options={checkOptions}
              divClassName="mt-1"
              internalDivClass="flex flex-col items-start"
              secondRadioClass="mt-3"
              textClass="sm:text-[10px] md:text-[12px] lg:text-[14px] font-[400] text-[#303030] ml-4"
              checkClass="flex items-start"
              label={""}
              width={"100%"}
              name="reasons"
              value={reasons}
              error={errors.reasons}
              touched={touched.reasons}
              setFieldValue={setFieldValue}
              onChecked={handleReasonChecked}
              postIcon={null}
            />
            {reasons?.includes("Other") && (
              <InputTextArea
                label={""}
                placeholder={"Write other reason..."}
                divClassName="relative mt-4 w-full"
                labelClassName="invitationLabels"
                textareaClassName="w-full h-[100px] px-4 pt-3 rounded-[10px] bg-[#F2F2F2] text-[#303030] lg:text-[14px] sm:text-[12px] font-[400] outline-none resize-none font-poppins"
                name="newReason"
                value={newReason}
                handleBlur={handleBlur}
                error={errors.newReason}
                touched={touched.newReason}
                setFieldValue={setFieldValue}
              />
            )}
          </div>

          <p className="text-[10px] sm:text-[12px] md:text-[14px] font-poppins font-[400] mt-8 text-center w-[90%]">
            By clicking sign up, I agree to the
            <span className="text-[#00ADEF] font-[500] cursor-pointer" onClick={() => window.open("/privacy/toc", "_blank")}> Terms of Use </span>
            that I have read the
            <span className="text-[#00ADEF] font-[500] cursor-pointer" onClick={() => window.open("/privacy/policy", "_blank")}> Privacy Policy.</span>
          </p>

          <PrimaryButton
            className={
              "sm:w-[50%] md:w-[60%] lg:w-[70%] font-poppins sm:text-[16px] md:text-[18px] lg:text-[20px] text-center font-[500] sm:h-[50px] md:h-[55px] lg:h-[60px] rounded-[10px] my-10"
            }
            label="SIGN UP"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </>
  );
};

export default GettingStarted;
