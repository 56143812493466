import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./dashboard.scss";
import BreadCrumsHeader from "../BreadCrums";
import Box from "./Box";
import MerchantIcon from "../../../Assets/Icons/merchantIcon.svg";
import InactiveMerchantsIcon from "../../../Assets/Icons/inactiveMerchantsIcon.svg";
import CustomerIcon from "../../../Assets/Icons/customerIcon.svg";
import OfferIcon from "../../../Assets/Icons/offerIcon.svg";
import ActiveMerchantsIcon from "../../../Assets/Icons/ActiveMerchantsIcon.svg";
import FundsIcon from "../../../Assets/Icons/FundsIcon.svg";
import AssetsIcon from "../../../Assets/Icons/BankIcon.svg";
import Summary from "./Summary";
import MerchantOffer from "./MerchantOffer";
import OffersCategory from "./OffersCategory";
import Funds from "./Funds";
import AccountsDetails from "./AccountsDetails";
import Withdrawals from "./Withdrawals";
import CreateNewButton from "../CreateNewButton";
import { useDispatch, useSelector } from "react-redux";
import { roleEnum } from "../../../utils/utilFunctions";
import { getOnboardingStatus } from "../../../services/fundraiserService";
import Loader from "../Loader";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fundraiserLoading = useSelector(
    (state) => state.fundraiserReducer.loading
  );
  const location = useLocation();
  const currentPath = location.pathname;

  const items = [
    {
      title: "Welcome to Swoodle",
    },
  ];
  const adminBoxes = [
    {
      text: "Total Merchants",
      number: "56",
      icon: MerchantIcon,
    },
    {
      text: "Total Offers",
      number: "110",
      icon: OfferIcon,
    },
    {
      text: "Customers",
      number: "350",
      icon: CustomerIcon,
    },
    {
      text: "Inactive Merchants",
      number: "20",
      icon: InactiveMerchantsIcon,
    },
  ];

  const merchantBoxes = [
    {
      text: "Total Merchants",
      number: "56",
      icon: MerchantIcon,
    },
    {
      text: "Total Offers",
      number: "110",
      icon: OfferIcon,
    },
    {
      text: "Active Merchants",
      number: "350",
      icon: CustomerIcon,
    },
    {
      text: "Inactive Merchants",
      number: "20",
      icon: InactiveMerchantsIcon,
    },
  ];

  const fundraiserBoxes = [
    {
      text: "EOM Funds",
      number: "56",
      icon: FundsIcon,
    },
    {
      text: "Swoodle Members",
      number: "110",
      icon: AssetsIcon,
    },
    {
      text: "Swoodle Newbies",
      number: "350",
      icon: ActiveMerchantsIcon,
    },
    {
      text: "Total Merchants",
      number: "20",
      icon: MerchantIcon,
    },
  ];

  const getStatus = async () => {
    const statusPromise = await dispatch(getOnboardingStatus()).unwrap();
    if (
      statusPromise?.data?.detailsSubmitted === true &&
      statusPromise?.data?.chargesEnabled === false
    ) {
      navigate("/complete-setup");
    } else if (
      statusPromise?.data?.detailsSubmitted === false &&
      statusPromise?.data?.chargesEnabled === false
    ) {
      navigate("/fundraiser/welcome");
    }
  };

  useEffect(() => {
    if (currentPath.includes(roleEnum.fundraiser)) {
      getStatus();
    }
  }, []);
  return (
    <>
      {currentPath.includes(roleEnum.merchant) ? (
        <>
          <BreadCrumsHeader heading="Dashboard" items={items} form={true} />
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full gap-3 my-[2%]">
            {merchantBoxes?.map((b) => (
              <Box
                text={b?.text}
                number={b?.number}
                icon={b?.icon}
                mainClassName="box p-3 h-[113px]"
                textClassName="text-[14px] font-poppins font-[600] text-[white]"
                numberClassName="text-[36px] font-[700] text-[white] font-montserrat"
              />
            ))}
          </div>
          <div className="w-full flex sm:flex-col lg:flex-row mt-16 justify-between items-stretch">
            <div className="sm:w-full lg:w-[49.5%]">
              <Summary />
            </div>
            <div className="sm:w-full lg:w-[49.5%]">
              <OffersCategory />
            </div>
          </div>
        </>
      ) : currentPath.includes(roleEnum.fundraiser) ? (
        <>
          {fundraiserLoading ? <Loader /> : null}
          <div className="flex justify-between col-span-12">
            <BreadCrumsHeader heading="Dashboard" items={items} />
            {/* <CreateNewButton
              text={"Merchant Invite"}
              navigateTo={""}
              iconClassName="sm:left-[1%] md:left-[8%] bottom-[30%]"
            /> */}
          </div>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full gap-3 my-[2%]">
            {fundraiserBoxes?.map((b) => (
              <Box
                text={b?.text}
                number={b?.number}
                icon={b?.icon}
                mainClassName="box p-3 h-[113px]"
                textClassName="text-[14px] font-poppins font-[600] text-[white]"
                numberClassName="text-[36px] font-[700] text-[white] font-montserrat"
              />
            ))}
          </div>
          <div className="sm:w-full lg:w-full">
            <Funds />
          </div>
          <div className="w-full flex sm:flex-col lg:flex-row mt-16 justify-between">
            <div className="sm:w-full lg:w-[49.5%]">
              <AccountsDetails />
            </div>
            <div className="sm:w-full lg:w-[49.5%]">
              <Withdrawals />
            </div>
          </div>
        </>
      ) : (
        <>
          <BreadCrumsHeader heading="Dashboard" items={items} form={true} />
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full gap-3 my-[2%]">
            {adminBoxes?.map((b) => (
              <Box
                text={b?.text}
                number={b?.number}
                icon={b?.icon}
                mainClassName="box p-3 h-[113px]"
                textClassName="text-[14px] font-poppins font-[600] text-[white]"
                numberClassName="text-[36px] font-[700] text-[white] font-montserrat"
              />
            ))}
          </div>
          <div className="w-full flex sm:flex-col lg:flex-row mt-16 justify-between items-stretch">
            <div className="sm:w-full lg:w-[49.5%]">
              <Summary />
            </div>
            <div className="sm:w-full lg:w-[49.5%]">
              <MerchantOffer />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AdminDashboard;
