import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import BreadCrumsHeader from "../../common/BreadCrums";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import Pagination from "../../common/TablePagination";
import CreateNewButton from "../../common/CreateNewButton";
import { ReactComponent as RedNose } from "../../../Assets/Images/rednose.svg";

const FundraiserReferralListTable = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const items = [
    {
      title: "Refer a Merchant",
    },
    {
      title: "Referral List",
    },
  ];

  const columns = [
    {
      name: "No.",
    },
    {
      name: "Merchant Name",
    },
    {
      name: "Country",
    },
    {
      name: "Province/ State",
    },
    {
      name: "City",
    },
    {
      name: "Post Code",
    },
    {
      name: "Location",
    },
    {
      name: "Website",
    },
    {
      name: "First Name",
    },
    {
      name: "Last Name",
    },
    {
      name: "Email",
    },
    {
      name: "Contact",
    },
  ];

  const rows = [
    {
      no: "1",
      merchantName: "McDonalds",
      country: "Australia",
      province: "New South Wales",
      city: "Sydney",
      postCode: "5000",
      location: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
      website: "www.abc.com",
      firstName: "Darrell",
      lastName: "Steward",
      email: "georgia.young@example.com",
      contact: "(+33)6 55 59 16 45",
    },
    {
      no: "1",
      merchantName: "McDonalds",
      country: "Australia",
      province: "New South Wales",
      city: "Sydney",
      postCode: "5000",
      location: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
      website: "www.abc.com",
      firstName: "Darrell",
      lastName: "Steward",
      email: "georgia.young@example.com",
      contact: "(+33)6 55 59 16 45",
    },
    {
      no: "1",
      merchantName: "McDonalds",
      country: "Australia",
      province: "New South Wales",
      city: "Sydney",
      postCode: "5000",
      location: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
      website: "www.abc.com",
      firstName: "Darrell",
      lastName: "Steward",
      email: "georgia.young@example.com",
      contact: "(+33)6 55 59 16 45",
    },
    {
      no: "1",
      merchantName: "McDonalds",
      country: "Australia",
      province: "New South Wales",
      city: "Sydney",
      postCode: "5000",
      location: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
      website: "www.abc.com",
      firstName: "Darrell",
      lastName: "Steward",
      email: "georgia.young@example.com",
      contact: "(+33)6 55 59 16 45",
    },
    {
      no: "1",
      merchantName: "McDonalds",
      country: "Australia",
      province: "New South Wales",
      city: "Sydney",
      postCode: "5000",
      location: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
      website: "www.abc.com",
      firstName: "Darrell",
      lastName: "Steward",
      email: "georgia.young@example.com",
      contact: "(+33)6 55 59 16 45",
    },
    {
      no: "1",
      merchantName: "McDonalds",
      country: "Australia",
      province: "New South Wales",
      city: "Sydney",
      postCode: "5000",
      location: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
      website: "www.abc.com",
      firstName: "Darrell",
      lastName: "Steward",
      email: "georgia.young@example.com",
      contact: "(+33)6 55 59 16 45",
    },
    {
      no: "1",
      merchantName: "McDonalds",
      country: "Australia",
      province: "New South Wales",
      city: "Sydney",
      postCode: "5000",
      location: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
      website: "www.abc.com",
      firstName: "Darrell",
      lastName: "Steward",
      email: "georgia.young@example.com",
      contact: "(+33)6 55 59 16 45",
    },
    {
      no: "1",
      merchantName: "McDonalds",
      country: "Australia",
      province: "New South Wales",
      city: "Sydney",
      postCode: "5000",
      location: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
      website: "www.abc.com",
      firstName: "Darrell",
      lastName: "Steward",
      email: "georgia.young@example.com",
      contact: "(+33)6 55 59 16 45",
    },
    {
      no: "1",
      merchantName: "McDonalds",
      country: "Australia",
      province: "New South Wales",
      city: "Sydney",
      postCode: "5000",
      location: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
      website: "www.abc.com",
      firstName: "Darrell",
      lastName: "Steward",
      email: "georgia.young@example.com",
      contact: "(+33)6 55 59 16 45",
    },
    {
      no: "1",
      merchantName: "McDonalds",
      country: "Australia",
      province: "New South Wales",
      city: "Sydney",
      postCode: "5000",
      location: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
      website: "www.abc.com",
      firstName: "Darrell",
      lastName: "Steward",
      email: "georgia.young@example.com",
      contact: "(+33)6 55 59 16 45",
    },
    {
      no: "1",
      merchantName: "McDonalds",
      country: "Australia",
      province: "New South Wales",
      city: "Sydney",
      postCode: "5000",
      location: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
      website: "www.abc.com",
      firstName: "Darrell",
      lastName: "Steward",
      email: "georgia.young@example.com",
      contact: "(+33)6 55 59 16 45",
    },
    {
      no: "1",
      merchantName: "McDonalds",
      country: "Australia",
      province: "New South Wales",
      city: "Sydney",
      postCode: "5000",
      location: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
      website: "www.abc.com",
      firstName: "Darrell",
      lastName: "Steward",
      email: "georgia.young@example.com",
      contact: "(+33)6 55 59 16 45",
    },
    {
      no: "1",
      merchantName: "McDonalds",
      country: "Australia",
      province: "New South Wales",
      city: "Sydney",
      postCode: "5000",
      location: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
      website: "www.abc.com",
      firstName: "Darrell",
      lastName: "Steward",
      email: "georgia.young@example.com",
      contact: "(+33)6 55 59 16 45",
    },
    {
      no: "1",
      merchantName: "McDonalds",
      country: "Australia",
      province: "New South Wales",
      city: "Sydney",
      postCode: "5000",
      location: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
      website: "www.abc.com",
      firstName: "Darrell",
      lastName: "Steward",
      email: "georgia.young@example.com",
      contact: "(+33)6 55 59 16 45",
    },
    {
      no: "1",
      merchantName: "McDonalds",
      country: "Australia",
      province: "New South Wales",
      city: "Sydney",
      postCode: "5000",
      location: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
      website: "www.abc.com",
      firstName: "Darrell",
      lastName: "Steward",
      email: "georgia.young@example.com",
      contact: "(+33)6 55 59 16 45",
    },
  ];

  const [page, setPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);

  const counterPagesLength = Math.ceil(rows.length / rowsPerPage);

  function hanldePageChange(currentpage) {
    setPage(currentpage);
  }

  return (
    <>
      <div className="flex flex-col gap-5">
        <div className="flex justify-between col-span-12">
          <RedNose />
          <CreateNewButton
            text={"Refer New Merchant"}
            navigateTo={"/fundraiser/refer-merchant"}
            iconClassName="sm:left-[1%] md:left-[6%] bottom-[30%]"
          />
        </div>
        <BreadCrumsHeader heading="Referral List" items={items} form={true} />
      </div>

      <div className=" col-span-12 mt-8">
        <TableContainer style={{ width: "100%" }}>
          <Table
            sx={{
              minWidth: "1936px",
              borderCollapse: "separate",
              borderSpacing: "0px 8px",
            }}
            aria-label="simple table"
          >
            <TableHead className="bg-[#00ADEF]">
              <TableRow
                className="h-[50px]"
                sx={{ boxShadow: "0px 4px 4px 0px #00000040" }}
              >
                {columns.map((column) => {
                  return <TableCell>{column.name}</TableCell>;
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              ).map((row) => {
                return (
                  <TableRow
                    className="h-[50px]"
                    sx={{
                      boxShadow: "0px 4px 4px 0px #00000040",
                      backgroundColor: row.no % 2 !== 0 ? "#F5F5F5" : "#FFFFFF",
                    }}
                  >
                    <TableCell>{row.no}</TableCell>
                    <TableCell>{row.merchantName}</TableCell>
                    <TableCell>{row.country}</TableCell>
                    <TableCell>{row.province}</TableCell>
                    <TableCell>{row.city}</TableCell>
                    <TableCell>{row.postCode}</TableCell>
                    <TableCell>{row.location}</TableCell>
                    <TableCell>{row.website}</TableCell>
                    <TableCell>{row.firstName}</TableCell>
                    <TableCell>{row.lastName}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.contact}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          totalPages={counterPagesLength}
          handlePageChange={hanldePageChange}
        />
      </div>
    </>
  );
};

export default FundraiserReferralListTable;
