import React from "react";
import { Select } from "antd";
import "./common.scss";
import { ReactComponent as SearchIcon } from "../../Assets/Icons/searchIconGrey.svg";
import { ReactComponent as VerticalLine } from "../../Assets/Icons/verticalLine.svg";

const Selector = ({
  label,
  labelClassName,
  divClassName,
  selectClassName,
  errorClassName,
  flexClassName,
  options,
  onChange,
  suffixIcon,
  placeholder,
  defaultValue,
  value,
  handleBlur,
  error,
  touched,
  selectFundraiser,
  multiple,
}) => {
  return (
    <div className={divClassName}>
      <p className={labelClassName}>{label}</p>
      <div className={`flex flex-col ${flexClassName}`}>
        {selectFundraiser ? (
          <Select
            value={value}
            placeholder={
              <div className="pl-3 flex items-center gap-2 font-poppins font-[400] text-[12px] md:text-[14px]">
                <SearchIcon className="w-[12px] sm:w-[15px]"/>
                {placeholder}
              </div>
            }
            className={selectClassName}
            onChange={onChange}
            onBlur={handleBlur}
            suffixIcon={
              <div className="flex gap-2 items-center">
                <VerticalLine className="py-1 mr-2" />
                {suffixIcon}
              </div>
            }
            // showSearch= {true}
            mode={multiple === true ? "multiple" : undefined}
            options={options}
            defaultValue={defaultValue}
          />
        ) : (
          <Select
            value={value}
            placeholder={
              <div className="pl-3 font-poppins font-[400] text-[12px] md:text-[14px]">
                {placeholder}
              </div>
            }
            className={selectClassName}
            onChange={onChange}
            onBlur={handleBlur}
            suffixIcon={<div className="flex items-center">{suffixIcon}</div>}
            mode={multiple === true ? "multiple" : undefined}
            options={options}
            defaultValue={defaultValue}
          />
        )}
        {error && touched ? (
          <div className="ms-1">
            <span
              className={`text-red-600 sm:text-[10px] md:text-[12px] font-[600] text-start ${errorClassName}`}
            >
              {error}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Selector;
