import React, {useState} from 'react'
import './dashboard.scss'


const NewOffers = () => {
  const [offersCount, setOffersCount] = useState(25);

  return (
    <div className='newOffers w-[100%] mt-8 p-[0.35rem]'>
      <div className='flex items-center justify-start w-full gap-5'>
        <div className='bg-white rounded-[5px] px-8 text-center'>
          <span className='text-[24px] font-poppins font-[600] text-[black]'>{offersCount}</span>
        </div>
        <span className='text-[20px] font-poppins font-[400] text-[black] whitespace-nowrap'>New Offers</span>
      </div>
    </div>
  )
}

export default NewOffers
