import React from 'react'
import Header from '../../../User/MerchantOnboard/OnBoardLayout/header'

const MerchantOnboarding = ({element}) => {
  return (
    <div className='w-full h-full'>
      <Header />
      {element}
    </div>
  )
}

export default MerchantOnboarding
