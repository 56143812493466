import axios from "../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createAsset = createAsyncThunk(
  "assetAndPromotion",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post("assetAndPromotion", payload);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const getAssets = createAsyncThunk(
  "getAssetAndPromotion",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(
        `assetAndPromotion?page=${payload?.page}`
      );
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const toggleAssetStatus = createAsyncThunk(
  "assetAndPromotion/status",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.patch(
        `assetAndPromotion/status/${payload?.id}`
      );
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const editAsset = createAsyncThunk(
  "assetAndPromotion/update",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.patch(`assetAndPromotion/${payload?.id}`, payload);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const deleteAsset = createAsyncThunk(
  "assetAndPromotion/delete",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.delete(`assetAndPromotion/${payload?.id}`);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);
