// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sameCheckbox {
  accent-color: #00ADEF;
  height: 16px;
  width: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/User/MerchantOnboard/BusinessProfile/business.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,YAAA;EACA,WAAA;AACJ","sourcesContent":[".sameCheckbox{\n    accent-color: #00ADEF;\n    height: 16px;\n    width: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
