// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-modal .ant-modal-content {
  padding: 32px 36px !important;
}
@media (max-width: 640px) {
  .ant-modal .ant-modal-content {
    padding: 32px 28px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/User/MembershipConfirmation/membershipConfirmation.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;AACJ;AAAI;EAFJ;IAGQ,6BAAA;EAGN;AACF","sourcesContent":[".ant-modal .ant-modal-content {\n    padding: 32px 36px !important;\n    @media (max-width: 640px) {\n        padding: 32px 28px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
