import Axios from "axios";
import S3 from "react-aws-s3";
window.Buffer = window.Buffer || require("buffer").Buffer;

const axios = Axios.create({
  baseURL: process.env.REACT_APP_S3_URL,
});

export const config = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  dirName: process.env.REACT_APP_DIR_NAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
};

export const getFileFromS3 = async (url) => {
  let file = await axios.get(url, {
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  });
  return file.data;
};

export const deleteFile = async (url) => {
  const ReactS3Client = new S3(config);
  return await ReactS3Client.deleteFile(url)
    .then((response) => {
      console.log(response);
    })
    .catch((err) => console.error(err));
};

export const deleteAndUploadFile = async (data, filename) => {
  const ReactS3Client = new S3(config);

  return await ReactS3Client.deleteFile(filename)
    .then(async (response) => {
      console.log(response);
      await uploadFile(data, filename);
      let status = true;
      return status;
    })
    .catch((err) => console.error(err));
};

export const uploadFile = async (file, filename) => {
  console.log('file, filename', file, filename)
  const ReactS3Client = new S3(config);
  return await ReactS3Client.uploadFile(file, filename)
    .then((data) => {
      console.log(data.location);
      return data?.location
    })
    .catch((err) => console.error(err));
};
