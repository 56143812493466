import axios from "axios";
import {
  getTokenData,
  getRefreshToken,
  getUserData,
  setTokenData,
} from "./connectStore";
import { getAccessToken } from "../services/authService";

let authTokenRequest;

function getAuthToken() {
  if (!authTokenRequest) {
    authTokenRequest = makeActualAuthenticationRequest();
    authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest);
  }

  return authTokenRequest;
}

function resetAuthTokenRequest() {
  authTokenRequest = null;
}

async function makeActualAuthenticationRequest() {
  try {
    const refresh = getRefreshToken();
    const res = await getAccessToken({ refreshToken: refresh });
    const { accessToken, refreshToken } = res;

    // Simulating a successful authentication response for demonstration purposes
    const fakeAuthenticationResponse = {
      accessToken,
      refreshToken,
    };

    await new Promise((resolve) => setTimeout(resolve, 1000));

    return fakeAuthenticationResponse;
  } catch (error) {
    throw new Error("Authentication failed");
  }
}

// Create an instance of Axios with custom configurations
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

//interceptor to include the API key in the request headers
instance.interceptors.request.use(
  (config) => {
    config.headers["api-key"] = process.env.REACT_APP_API_KEY;
    if (
      config.url === "subscription/user" ||
      config?.url === "subscription/createCheckoutSession" ||
      config?.url?.startsWith("location") ||
      config?.url === "location/merchant" ||
      config?.url?.startsWith("location/status") ||
      config?.url === "subCategory/create" ||
      config?.url === "fundraiser/funds" ||
      config?.url?.startsWith("payout") ||
      config?.url === "auth/updateReferredBy" ||
      config?.url === "fundraiser/updateContent" ||
      config?.url === "ruleAndRegulation" ||
      config?.url?.startsWith("admin/approveReject") || 
      config?.url === "auth/logout" ||
      config?.url === "notification" ||
      config?.url?.startsWith("assetAndPromotion") || 
      config?.url?.startsWith("offer")
    ) {
      config.headers["Authorization"] = `Bearer ${getTokenData()}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//interceptor to implement refreshToken logic on response
instance.interceptors.response.use(
  undefined,
  (err) => {
    const originalRequest = err?.config;

    const error = err?.response;
    if (error?.status === 401 && error?.config && !originalRequest?._retry) {
      return getAuthToken().then((resp) => {
        const userData = getUserData();
        setTokenData({
          ...userData,
          token: resp?.accessToken,
          refreshToken: resp?.refreshToken,
        });

        if (
          error?.config.url === "subscription/user" ||
          error?.config?.url === "subscription/createCheckoutSession" ||
          error?.config?.url?.startsWith("location") ||
          error?.config?.url === "location/merchant" ||
          error?.config?.url?.startsWith("location/status") ||
          error?.config?.url === "subCategory/create" ||
          error?.config?.url === "fundraiser/funds" ||
          error?.config?.url?.startsWith("payout") ||
          error?.config?.url === "auth/updateReferredBy" ||
          error?.config?.url === "fundraiser/updateContent" ||
          error?.config?.url === "ruleAndRegulation" ||
          error?.config?.url?.startsWith("admin/approveReject") ||
          error?.config?.url === "auth/logout" || 
          error?.config?.url === "notification" ||
          error?.config?.url?.startsWith("assetAndPromotion") || 
          error?.config?.url?.startsWith("offer")
        ) {
          originalRequest.headers.Authorization = `Bearer ${resp?.accessToken}`;
        }
        error.config.__isRetryRequest = true;
        return instance(error.config);
      });
    }
    return error;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
