import React from 'react'
import ReferFormPage from '../../components/Fundraiser/ReferForm';


const ReferForm = () => {
  return (
    <ReferFormPage />
  )
}

export default ReferForm;