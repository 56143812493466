// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input.TermsCheckbox {
  accent-color: #00ADEF;
  margin-right: 5%;
  height: 15px;
  width: 15px;
  margin-left: 1%;
}`, "",{"version":3,"sources":["webpack://./src/components/common/SignUp/signup.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,gBAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;AACJ","sourcesContent":["input.TermsCheckbox{\n    accent-color: #00ADEF;\n    margin-right: 5%;\n    height: 15px;\n    width: 15px; \n    margin-left: 1%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
