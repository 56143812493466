import React from 'react'
import './dashboard.scss'


const Box = ({text, number, icon, mainClassName, textClassName, numberClassName}) => {
  return (
    <div className={mainClassName}>
      <div className='h-full w-full flex justify-between'>
        <div className='flex flex-col'>
          <span className={textClassName}>{text}</span>
          <span className={numberClassName}>{number}</span>
        </div>
        {icon ? <div className='flex items-center justify-center h-full'>
          <div className="w-[60px] h-[60px] rounded-full bg-white flex items-center justify-center">
            <img src={icon}/>
          </div>
        </div>: null}
      </div>
    </div>
  )
}

export default Box
