// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 320px) and (max-width: 340px) {
  .inputFieldsDiv {
    margin-top: 0.25rem !important;
  }
  .continueButton {
    margin-top: 0.25rem !important;
    height: 40px !important;
  }
}

.mobileBgWithMap {
  width: 360px;
}
@media (min-width: 320px) and (max-width: 360px) {
  .mobileBgWithMap {
    width: 550px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/User/NewMemberSetup/newMember.scss"],"names":[],"mappings":"AACE;EADF;IAEI,8BAAA;EACF;EAGF;IAEI,8BAAA;IACA,uBAAA;EACF;AANF;;AASA;EACE,YAAA;AACF;AAAE;EAFF;IAGI,YAAA;EAGF;AACF","sourcesContent":[".inputFieldsDiv {\n  @media (min-width: 320px) and (max-width: 340px) {\n    margin-top: 0.25rem !important;\n  }\n}\n\n.continueButton {\n  @media (min-width: 320px) and (max-width: 340px) {\n    margin-top: 0.25rem !important;\n    height: 40px !important;\n  }\n}\n\n.mobileBgWithMap {\n  width: 360px;\n  @media (min-width: 320px) and (max-width: 360px) {\n    width: 550px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
