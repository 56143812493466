// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loginSection {
  background: rgba(0, 173, 239, 0.5019607843);
}

.inputCheckbox {
  margin-right: 15px;
  height: 15px;
  width: 15px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/common/ResetPassword/resetPassword.scss"],"names":[],"mappings":"AAAA;EAEI,2CAAA;AAAJ;;AAIA;EACI,kBAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;AADJ","sourcesContent":[".loginSection{\n    // background-color: #00ADEF80;\n    background: #00ADEF80;\n\n}\n\n.inputCheckbox{\n    margin-right: 15px;\n    height: 15px;\n    width: 15px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
