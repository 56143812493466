import React, { useEffect } from "react";
import "./common.scss";
import { useFormik } from "formik";
import { ReactComponent as DeclineIcon } from "../../Assets/Icons/declineIcon.svg";
import { ReactComponent as CloseIcon } from "../../Assets/Icons/popupCloseIcon.svg";
import { rejectedReasonSchema } from "../../utils/validationSchemas";
import InputTextArea from "./InputTextArea";
import PrimaryButton from "./PrimaryButton";

const RejectOfferPopup = ({
  handleClose,
  content,
  popupClassName,
  setReason,
  handleOfferRejection
}) => {
  const formik = useFormik({
    initialValues: {
      reason: "",
    },
    validationSchema: rejectedReasonSchema,
    onSubmit: async (values) => {
      handleOfferRejection();
    },
  });

  const { reason } = formik.values;

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (reason !== "") {
      setReason(reason);
    }
  }, [reason]);

  return (
    <div className="popup-container">
      <div
        className={`popup flex flex-col items-center bg-white rounded-[10px] drop-shadow-[0_3px_3px_rgba(0,0,0,0.25)] relative ${popupClassName}`}
      >
        <div className="h-[3%]">
          <CloseIcon
            className="absolute top-3 right-3 cursor-pointer w-[12px] h-[12px]"
            onClick={handleClose}
          />
        </div>
        <div className="flex flex-col justify-center items-center w-[85%]">
          <DeclineIcon className="w-[40px] h-[40px] my-4 cursor-pointer" />
          <p className="text-[14px] sm:text-[16px] font-[400] font-montserrat text-[#303030] text-center px-4 break-words w-[95%]">
            {content}
          </p>
          <InputTextArea
            label={""}
            divClassName="relative mt-5 w-full"
            placeholder="Give reason for rejection"
            labelClassName=""
            textareaClassName="w-full h-[196px] px-3 py-2 rounded-[10px] bg-[#EEEEEE] text-[#303030] lg:text-[14px] sm:text-[12px] font-[400] outline-none resize-none font-poppins"
            name="reason"
            value={reason}
            handleBlur={handleBlur}
            error={errors.reason}
            touched={touched.reason}
            setFieldValue={setFieldValue}
          />
          <div className="w-full flex justify-end items-end">
            <PrimaryButton
              className={
                "sm:w-[20%] md:w-[25%] lg:w-[25%] font-poppins sm:text-[12px] md:text-[14px] text-center font-[500] sm:h-[35px] md:h-[40px] rounded-[10px] mt-5"
              }
              label="Submit"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RejectOfferPopup;
