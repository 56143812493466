// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invitationInputs {
  height: 45px;
  font-size: 14px;
  font-weight: 400;
  color: #303030 !important;
  border: 1px solid #D9D9D9;
  padding-left: 2%;
  outline: none;
  font-family: Poppins, sans-serif;
}

.invitationLabels {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #303030;
  margin-bottom: 21px;
}

.invite .sendButton {
  background-color: #00ADEF;
  color: white;
  border-radius: 5px;
  height: 45px;
}

.ant-breadcrumb-link {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #8C8C8C;
}

.ant-breadcrumb-separator {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 14px;
  margin-top: 2px;
  margin-inline: 15px !important;
  color: rgba(0, 0, 0, 0.45);
}`, "",{"version":3,"sources":["webpack://./src/components/Admin/InvitationForm/invitationform.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,yBAAA;EACA,gBAAA;EACA,aAAA;EACA,gCAAA;AACJ;;AAEA;EACI,gCAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;AACJ;;AAEA;EACI,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;AACJ;;AAIA;EACI,gCAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AADJ;;AAIA;EACI,gCAAA;EACA,gBAAA;EACA,eAAA;EACA,eAAA;EACA,8BAAA;EACA,0BAAA;AADJ","sourcesContent":[".invitationInputs{\n    height: 45px;\n    font-size: 14px;\n    font-weight: 400;\n    color: #303030 !important;\n    border: 1px solid #D9D9D9;\n    padding-left: 2%;\n    outline: none;\n    font-family: Poppins, sans-serif;\n}\n\n.invitationLabels{\n    font-family: Poppins, sans-serif;\n    font-size: 14px;\n    font-weight: 400;\n    color: #303030;\n    margin-bottom: 21px;\n}\n\n.invite .sendButton{\n    background-color: #00ADEF;\n    color: white;\n    border-radius: 5px;\n    height: 45px;\n}\n\n// BreadCrum Built-in Class\n\n.ant-breadcrumb-link{\n    font-family: Poppins, sans-serif;\n    font-size: 14px;\n    font-weight: 500;\n    color: #8C8C8C;\n}\n\n.ant-breadcrumb-separator {\n    font-family: Poppins, sans-serif;\n    font-weight: 600;\n    font-size: 14px;\n    margin-top: 2px;\n    margin-inline: 15px !important;\n    color: rgba(0, 0, 0, 0.45);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
