import React from "react";
import RadioButton from "./RadioButton";

const RadioSelection = ({
  label,
  divClassName,
  mapClass,
  internalDivClass,
  secondRadioClass,
  textClass,
  name,
  value,
  error,
  touched,
  setFieldValue,
  options,
}) => {
  return (
    <div className={divClassName}>
      <p className="lg:text-[16px] sm:text-[14px] font-[400] text-[#303030] mb-3 font-poppins">
        {label}
      </p>
      <div className={mapClass}>
        {options?.map((op, index) => (
          <div className={internalDivClass}>
            <div
              class={`flex items-center ${index > 0 ? secondRadioClass : ""}`}
            >
              <RadioButton
                divClassName="flex items-start"
                text={op}
                checked={value === op}
                onChecked={() => {
                  setFieldValue(name, op);
                }}
                textClass={textClass}
              />
            </div>
          </div>
        ))}
      </div>
      {error && touched ? (
        <div className="ms-1">
          <span className="text-red-600 sm:text-[10px] md:text-[12px] font-[600] text-start">
            {error}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default RadioSelection;
