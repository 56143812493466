import React from "react";
import { ReactComponent as UpwardIcon } from "../../../../Assets/Icons/upwardBlueIcon.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { separateDateTimeAndConvertToLocale } from "../../../../utils/utilFunctions";

const OfferDetails = ({ offerData }) => {
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="min-h-[437px] sm:w-[210px] lg:w-[210px] 2xl:w-[210px] bg-white drop-shadow-[0_4px_4px_rgba(0,0,0,0.15)] flex flex-col justify-around items-center py-2">
      <div className="min-h-[200px] w-[90%] bg-[#00ADEF] rounded-[5px] flex flex-col items-center justify-start p-3">
        {offerData?.previewImages?.length > 1 ? (
          <Slider className="w-full h-[80px]" {...settings}>
            {offerData?.previewImages?.map((url) => (
              <img src={url} className="w-full h-[80px]" />
            ))}
          </Slider>
        ) : (
          <img src={offerData?.previewImages[0]} className="w-full h-[80px]" />
        )}
        <div className="flex flex-col items-start justify-between w-full py-3">
          <p className="text-white font-poppins font-[600] text-[9px]">
            {offerData?.title}
          </p>
          <p className="text-white font-poppins font-[600] text-[6px] py-3 w-full break-all leading-relaxed">
            {offerData?.description}
          </p>
        </div>
        <div className="bg-white h-[20px] w-[80px] rounded-[3px] flex justify-center items-center">
          <p className="text-[#303030] font-poppins font-[600] text-[8px]">
            Redeem
          </p>
        </div>
        <p className="text-white font-poppins font-[500] text-[6px] py-2">
          Valid untill -{" "}
          {separateDateTimeAndConvertToLocale(offerData?.endDate)?.localDate}
        </p>
      </div>
      <div className="w-[90%]">
        <div className="w-[full] border border-[#00ADEF] rounded-[3px] px-2 py-1 mt-3">
          <div className="w-full flex justify-between items-center mb-1">
            <p className="text-[#303030] font-poppins font-[400] text-[8px]">
              Categories
            </p>
            <UpwardIcon className="w-[10px] h-[6px]" />
          </div>
          <p className="text-[#8C8C8C] font-poppins font-[400] text-[8px] flex justify-start items-center gap-1">
            <span className="font-[700] mb-[1.5px]">.</span>{" "}
            {offerData?.category?.title}
          </p>
        </div>
        <div className="w-[full] border border-[#00ADEF] rounded-[3px] px-2 py-1 mt-1">
          <div className="w-full flex justify-between items-center mb-1">
            <p className="text-[#303030] font-poppins font-[400] text-[8px]">
              Rules & Regulations
            </p>
            <UpwardIcon className="w-[10px] h-[6px]" />
          </div>
          {offerData?.ruleAndRegulation?.map((rule) => (
            <p className="text-[#8C8C8C] font-poppins font-[400] text-[8px] flex justify-start items-start gap-1">
              <span className="font-[700] pb-[1px]">.</span> {rule?.title}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OfferDetails;
