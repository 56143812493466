import React from "react";
import RedButton from "./RedButton";
import GreyOutlineButton from "./GreyOutlineButton";
import "./common.scss";

const CancelPopup = ({ handleNo, handleYes, heading, content, image }) => {
  return (
    <div className="popup-container">
      <div className="popup flex flex-col justify-evenly items-center w-[335px] sm:w-[470px] h-[275px] sm:h-[255px] bg-white rounded-[10px] drop-shadow-[0_3px_3px_rgba(0,0,0,0.25)] popup">
        <img src={image} />
        <div className="flex flex-col justify-center items-center sm:gap-2">
          <p className="text-[18px] sm:text-[20px] font-[500] font-poppins text-[#303030] mb-2">
            {heading}
          </p>
          <p className="text-[12px] sm:text-[14px] font-[400] font-poppins text-[#8C8C8C]">
            {content}
          </p>
        </div>
        <div className="w-full flex flex-col sm:flex-row  justify-center items-center gap-2 sm:gap-5">
          <GreyOutlineButton
            className="h-[45px] sm:h-[50px] w-[100px] font-[500] drop-shadow-[0_4px_4px_rgba(0,0,0,0.05)]"
            label="No"
            onClick={handleNo}
          />
          <RedButton
            className="h-[45px] sm:h-[50px] w-[100px] font-[500] drop-shadow-[0_4px_4px_rgba(236,130,110,0.3)]"
            label="Yes"
            onClick={handleYes}
          />
        </div>
      </div>
    </div>
  );
};

export default CancelPopup;
