// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 1024px) {
  .ant-picker-input > input {
    font-size: 16px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/User/MerchantOnboard/MoreInformation/info.scss"],"names":[],"mappings":"AACI;EADJ;IAEO,0BAAA;EACL;AACF","sourcesContent":[".ant-picker-input >input {\n    @media (min-width: 1024px) {\n       font-size: 16px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
