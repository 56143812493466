import { setFcmToken, setUserData } from "../redux/slices/authSlice";
import {store} from "../redux/store";

const setTokenData = (userData) => {
  store?.dispatch(setUserData(userData))
};

const setToken = (appToken) => {
  const oldUserData = store?.getState()?.authReducer?.tokenData;
  store?.dispatch(setUserData({...oldUserData, token: appToken}));
}

const getTokenData = () => {
  console.log('getStore========>', store);
  return store?.getState()?.authReducer?.tokenData?.token;
};
const getRefreshToken = () => {
  return store?.getState()?.authReducer?.tokenData?.refreshToken;
};
const getUserData = () => {
  return store?.getState()?.authReducer?.tokenData;
};

const setFcm = (fcm) => {
  store?.dispatch(setFcmToken(fcm))
}

const getFcmToken = () => {
  return store?.getState()?.authReducer?.fcmToken;
};

export { setTokenData, getTokenData, getRefreshToken, getUserData, setToken, setFcm, getFcmToken };
