import React from "react";
import { Switch, ConfigProvider } from "antd";

const Status = ({isEnabled, handleToggle}) => {
  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Switch: {
              colorPrimary: "#00ADEF",
              trackHeight: 22,
              trackPadding: 3
            },
          },
          token: {
            fontSize: 12,
            colorPrimaryHover: "#009BD6"
          }
        }}
      >
        <Switch
          className="bg-[#D9D9D9] !font-[600] !font-poppins"
          checkedChildren="Enabled"
          unCheckedChildren="Disabled"
          defaultChecked={isEnabled}
          onChange={handleToggle}
        />
      </ConfigProvider>
    </div>
  );
};

export default Status;
