import React, { useEffect, useState } from "react";
import bank from "../../../Assets/Icons/BankIcon.svg";
import Checkbox from "../../common/Checkbox";
import Input from "../../common/FormInputs";
import { useFormik } from "formik";
import { withdrawSchema } from "../../../utils/validationSchemas";
import "./withdraw.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  createTransfer,
  getAccountDetails,
} from "../../../services/fundraiserService";
import Loader from "../../common/Loader";

const WithdrawMoney = ({ setPageContent }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.fundraiserReducer?.loading);
  const [balance, setBalance] = useState();
  const [bankDetails, setBankDetails] = useState();

  const getDetails = async () => {
    const getPromise = await dispatch(getAccountDetails())?.unwrap();
    if (getPromise?.success === true) {
      setBalance(getPromise?.data?.balance);
      setBankDetails(getPromise?.data?.bank);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);
  const formik = useFormik({
    initialValues: {
      amount: "",
      bankCheckBox: true,
    },
    validationSchema: withdrawSchema,
    onSubmit: async (values) => {
      console.log("values", values);
      const createPromise = await dispatch(
        createTransfer({ amount: values?.amount })
      ).unwrap();
      if (createPromise?.success === true) {
        setPageContent("security");
      }
    },
  });

  const { amount, bankCheckBox } = formik.values;

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = formik;

  return (
    <div className="w-full">
      {loading === true && <Loader />}
      <div className="flex justify-between">
        <p className="sm:text-[14px] md:text-[16px] text-[#303030] my-3 font-poppins font-[600]">
          Amount Available
        </p>
        <p className="sm:text-[18px] md:text-[22px] font-poppins font-[600] my-2">
          {balance} $
        </p>
      </div>
      <hr className="w-full" />
      <div className="flex justify-between">
        <Input
          type="text"
          label="Enter Amount To Withdraw"
          placeholder="300"
          width="100%"
          radius="5px"
          InputDivClass="w-[100%] mt-5 withdrawform"
          name="amount"
          value={amount}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.amount}
          touched={touched.amount}
        />
      </div>
      <div className="flex mt-6 justify-between">
        <p className="text-[#8C8C8C] sm:text-[12px] md:text-[14px] font-poppins font-[600]">
          Remaining Balance
        </p>
        <p className="text-[#8C8C8C] sm:text-[12px] md:text-[14px] font-poppins font-[600]">
          {balance - amount} $
        </p>
      </div>
      <div className=" mt-10 h-[141px] w-full rounded-[10px] border-[1px] border-[#00ADEF]">
        <div className="flex items-center h-full w-full">
          <div className="flex justify-start items-center sm:gap-5 md:gap-10 lg:gap-20 md:w-[85%] lg:w-[65%] xl:w-[50%] 2xl:w-[40%]">
            <img
              src={bank}
              className="ml-[7%] sm:h-[25px] md:h-[35px] lg:h-[45px]"
            />
            <div className="">
              <p className="text-[#303030] my-1 sm:text-[14px] md:text-[16px] lg:text-[18px] font-poppins font-[600]">
                {bankDetails?.bankName}
              </p>
              <p className="text-[#8C8C8C] sm:text-[12px] md:text-[14px] lg:text-[16px] font-poppins font-[400]">
                XXXX XXXX XXXX {bankDetails?.bankAccount}
              </p>
            </div>
          </div>
          <div className="flex justify-end mr-[3%] md:w-[15%] lg:w-[35%] xl:w-[50%] 2xl:w-[60%] ">
            <Checkbox
              divClassName="flex items-start"
              checked={bankCheckBox}
              onChecked={() => {
                setFieldValue("bankCheckBox", !bankCheckBox);
              }}
              textClass="lg:text-[14px] sm:text-[12px] font-[500] text-[#303030] ml-4"
            />
          </div>
        </div>
      </div>
      <div className="text-center">
        <p className="mt-[10%] text-[#8C8C8C] sm:text-[12px] md:text-[14px] font-poppins font-[400]">
          By clicking withdraw, you confirm the withdrawal details above
        </p>
        <button
          className="bg-[#00ADEF] font-montserrat font-[500] sm:mt-[10%] md:mt-[5%] text-white sm:text-[14px] md:text-[16px] lg:text-[18px] rounded-lg sm:w-[50%] md:w-[30%] lg:w-[25%] 2xl:w-[20%] h-[48px]"
          onClick={handleSubmit}
        >
          Withdraw
        </button>
      </div>
    </div>
  );
};

export default WithdrawMoney;
