import React, { useState } from "react";
import OnboardSwoodleLogo from "../../../Assets/Images/Onboard Swoodle Logo.svg";
import PrimaryButton from "../../common/PrimaryButton";
import TextInput from "../../common/SignUp/TextInput";
import emailIcon from "../../../Assets/Icons/emailIcon.svg";
import passwordIcon from "../../../Assets/Icons/passwordIcon.svg";
import { setLoginData } from "../../../redux/slices/authSlice";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../services/authService";
import { loginSchema } from "../../../utils/validationSchemas";
import { NavLink, useNavigate } from "react-router-dom";
import { getUserSubscription } from "../../../services/subscriptionService";
import Checkbox from "../../common/Checkbox";
import { ReactComponent as UncheckedIcon } from "../../../Assets/Icons/uncheckedBlackBox.svg";
import Loader from "../../common/Loader";
import { getFcmToken } from "../../../utils/connectStore";

const AccountLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [rememberCheckbox, setRememberCheckbox] = useState(false);
  const loading = useSelector((state) => state?.onboardingReducer?.loading);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      const promiseResult = await dispatch(
        login({
          email: email,
          password: password,
          fcmToken: getFcmToken()
        })
      ).unwrap();
      if (promiseResult?.success === true) {
        navigate("/fundraiser/welcome");
        dispatch(
          setLoginData({
            email: email,
            password: password,
            fcmToken: getFcmToken()
          })
        );
      }
      console.log("values", values);
    },
  });

  const { email, password } = formik.values;

  const { handleChange, handleBlur, handleSubmit, errors, touched } = formik;

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSignupClick = () => {
    // navigate("/create-account")
  };

  return (
    <div className="w-full py-10 h-screen flex flex-col items-center offerform">
      {loading && <Loader />}
      <p className="font-poppins font-[500] sm:text-[18px] md:text-[22px] lg:text-[26px] text-[#303030]">
        Welcome to Swoodle! Let’s set up your Account!
      </p>
      <div className="flex flex-col items-center sm:w-[70%] md:w-[60%] lg:w-[50%] xl:w-[45%] 2xl:w-[40%]">
        <img src={OnboardSwoodleLogo} className="pt-[6%] pb-[2%] w-[60%]"></img>
        <p className="font-poppins font-[500] sm:text-[18px] md:text-[22px] lg:text-[26px] text-[#303030]">
          FUNDRAISER
        </p>
        <p className="font-poppins font-[400] sm:text-[16px] md:text-[18px] lg:text-[18px] xl:text-[22px] text-[#303030] mt-10 mb-3 text-center">
          Your Account
        </p>
        <div className="flex flex-col justify-start items-start gap-2 w-full">
          <TextInput
            Icon={emailIcon}
            name={"email"}
            value={email}
            placeholder={"Email ID"}
            inputClass={
              "w-full rounded-tr-[5px] rounded-br-[5px] border border-[#D9D9D9]"
            }
            iconAreaClass={
              "w-[11%] bg-[#E6E6E6] rounded-tl-[5px] rounded-bl-[5px] border border-[#D9D9D9]"
            }
            inputDivClass={"h-[55px] sm:h-[65px]"}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors.email}
            touched={touched.email}
            autoComplete={"new-password"}
          />

          <TextInput
            Icon={passwordIcon}
            type={showPassword ? "text" : "password"}
            name={"password"}
            value={password}
            placeholder={"Password"}
            inputClass={
              "w-full rounded-tr-[5px] rounded-br-[5px] border border-[#D9D9D9]"
            }
            iconAreaClass={
              "w-[11%] bg-[#E6E6E6] rounded-tl-[5px] rounded-bl-[5px] border border-[#D9D9D9]"
            }
            inputDivClass={"h-[55px] sm:h-[65px]"}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors.password}
            touched={touched.password}
            showPassword={showPassword}
            togglePassword={togglePassword}
            autoComplete={"new-password"}
          />
          <div className="w-full flex items-center justify-between sm:mt-0 2xl:mt-[0%]">
            <div className=" w-[60%] flex items-center customize">
              <Checkbox
                text="Remember me"
                divClassName="flex items-center justify-center"
                checked={rememberCheckbox}
                onChecked={() => {
                  setRememberCheckbox(!rememberCheckbox);
                }}
                textClass="lg:text-[14px] sm:text-[12px] font-[400] font-poppins text-[#767676] ml-4 whitespace-nowrap"
                UncheckedIcon={UncheckedIcon}
              />
            </div>

            <div className="w-[50%] text-right">
              <p className="lg:text-[14px] sm:text-[12px] font-[400] text-[#767676] font-poppins">
                <NavLink to={""}>Forget Password</NavLink>
              </p>
            </div>
          </div>
        </div>
        <p className="text-[10px] sm:text-[12px] md:text-[14px] text-center font-poppins font-[400] mt-2 w-[90%]">
          <span className="text-[#00ADEF] font-[500]"> Need Help? </span>
        </p>
        <p className="text-[10px] sm:text-[12px] md:text-[14px] text-center font-poppins font-[400] mt-2 w-[90%]">
          Don’t have a Fundraiser account?
          <span
            className="text-[#00ADEF] font-[500] cursor-pointer"
            onClick={handleSignupClick}
          >
            &nbsp; Sign up with Swoodle
          </span>
        </p>
        <p className="text-[10px] sm:text-[12px] md:text-[14px] text-center font-poppins font-[400] mt-8 w-[90%]">
          Login to swoodle Fundraiser center
        </p>
        <div className="text-center w-full">
          <PrimaryButton
            className={
              "w-full font-poppins sm:text-[14px] lg:text-[16px] text-center font-[500] sm:h-[45px] md:h-[50px] lg:h-[55px] rounded-[10px] my-5 tracking-wide"
            }
            label="LOG INTO SWOODLE FUNDRAISER CENTRE"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountLogin;
