import { Breadcrumb } from "antd";
import React from "react";

const WelcomeList = ({ heading, text, icon }) => {
  return (
    <div className="w-full flex justify-between items-start">
      {icon}
      <div className="flex flex-col w-[90%]">
        <p className="font-poppins font-[500] text-[18px] text-start text-[#303030]">
          {heading}
        </p>
        <p className="font-poppins font-[400] text-[14px] text-start text-[#767676] mt-2">
          {text}
        </p>
      </div>
    </div>
  );
};

export default WelcomeList;
