import React, { useState, useEffect } from "react";

import Swoodle from "../../../Assets/Images/swoodleLogo.svg";
import Backtick from "../../../Assets/Images/backtick.svg";
import { ReactComponent as DownArrow } from "../../../Assets/Icons/Down Arrow.svg";

import FundariserSidebar from "../common/FundraiserSidebar";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FundraiserHeader from "../common/FundraiserHeader";
import PrimaryButton from "../../common/PrimaryButton";
import Selector from "../../common/Selector";
import { useFormik } from "formik";
import {
  subscriptionSchema,
  fundraiserSchema,
} from "../../../utils/validationSchemas";
import {
  getFundraiserCategories,
  getFundraisersByCategoryId,
  updateReferredBy,
} from "../../../services/fundraiserService";
import { checkout } from "../../../services/subscriptionService";
import Loader from "../../common/Loader";

function Subscription() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedPlan = useSelector(
    (state) => state?.subscriptionReducer?.selectedPlan
  );
  const loading = useSelector((state) => state?.subscriptionReducer?.loading);
  const location = useLocation();
  const currentPath = location.pathname;
  const content = useSelector((state) => state.fundraiserReducer.fundraiserContent);

  const [fundraiserCategories, setFundraiserCategories] = useState([]);
  const [fundraisers, setFundraisers] = useState([]);

  const getCategories = async () => {
    try {
      const result = await dispatch(getFundraiserCategories()).unwrap();
      setFundraiserCategories(result?.data);
    } catch (error) {
      console.error("Error fetching categories", error);
      throw error;
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const initialValues = {
    category: "",
    fundraiser: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema:
      content == null ? subscriptionSchema : fundraiserSchema,

    onSubmit: async (values) => {
      try {
        const checkoutPromise = await dispatch(
          checkout({ priceId: selectedPlan?.priceId })
        )?.unwrap();
        if (checkoutPromise?.success === true) {
          window.location.href = checkoutPromise?.data?.url;
        } else {
          navigate("/subscription-failed");
        }
      } catch (err) {
        console.log("err", err);
      }
    },
  });

  const { category, fundraiser } = formik.values;

  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
  } = formik;

  const handleCategoryChange = (e) => {
    setFieldValue("fundraiser", undefined);
    setFieldValue("category", e);
    fundraiserCategories?.map(async (fc) => {
      if (fc?.title === e) {
        await getFundraisersNames(fc?._id);
      }
    });
  };

  const handleFundraiserChange = (e) => {
    setFieldValue("fundraiser", e);
  };
  const fundraiserCategoriesOptions = fundraiserCategories?.map((op) => ({
    value: op?.title ?? "abc",
    label: op?.title ?? "dcs",
  }));

  const getFundraisersNames = async (id) => {
    try {
      const result = await dispatch(
        getFundraisersByCategoryId({ id: id })
      ).unwrap();
      setFundraisers(result?.data);
    } catch (error) {
      console.log("Error fetching categories", error);
      throw error;
    }
  };

  const uniqueNames = new Set();

  const fundraisersOptions = fundraisers
    ?.map((op) => {
      const name = op?.user?.name ?? "abc";
      if (!uniqueNames.has(name)) {
        uniqueNames.add(name);
        return {
          value: name,
          label: name,
        };
      }
      return null;
    })
    .filter((option) => option !== null);

  const titles = [
    { name: "Fundraising", completed: true },
    {
      name: "Payment Details",
      completed:
        currentPath.includes("/more-information") ||
        currentPath.includes("/submit-information")
          ? true
          : false,
    },
  ];

  const updateRefer = () => {
    fundraisers?.map(async (f) => {
      if (f?.user?.name === fundraiser) {
        const payload = { referredBy: f?.user?._id };
        const updatePromise = await dispatch(
          updateReferredBy(payload)
        )?.unwrap();
        if (updatePromise?.success === true) {
        }
      }
    });
  };

  useEffect(() => {
    updateRefer();
  }, [fundraisers, fundraiser]);
  return (
    <>
      <FundraiserHeader />
      {loading && <Loader />}
      {!loading && (
        <div className="flex w-full">
          <div className="w-[0%] sm:w-[25%] md:w-[23%] lg:w-[28%] 2xl:w-[30%]">
            <FundariserSidebar titles={titles} />
          </div>
          <div className="w-[100%] sm:w-[65%] md:w-[55%] lg:w-[45%] 2xl:w-[40%] bg-[#FAFAFA] flex flex-col px-4 sm:px-7">
            {content !== null ? (
              <div className="w-[100%] flex justify-between mt-5 mb-10 sm:mt-12 sm:mb-24">
                <div className="flex">
                  <div className="pt-[9px] cursor-pointer">
                    <img src={Backtick} className="w-[9px]" />
                  </div>
                  <div className="flex flex-col pl-[30px]">
                    <span className="font-poppins font-[500] text-[18px] sm:text-[22px] mb-2 text-[#303030]">
                      Fundraising
                    </span>
                    <span className="flex justify-end font-[400] font-poppins text-[14px] text-[#303030] ">
                      {content?.user?.name}
                    </span>
                  </div>
                </div>
                <img src={content?.logo} className="w-[40px] sm:w-[50px]" />
              </div>
            ) : (
              <>
                <div className="w-[100%] flex justify-between items-start mt-5 mb-10 sm:my-12 gap-1 sm:gap-0">
                  <div className="flex flex-col">
                    <span className="font-poppins font-[500] text-[18px] sm:text-[22px] mb-2 text-[#303030]">
                      Choose Your Fundraiser?
                    </span>
                    <span className="flex justify-end font-[400] font-poppins text-[14px] text-[#303030] ">
                      20% of your Membership purchase goes directly to support
                      your choosen fundraising cause.
                    </span>
                  </div>
                  <img
                    src={Swoodle}
                    className="w-[60px] sm:w-[80px] md:w-[100px] lg:w-[120px] mt-[4px]"
                  />
                </div>
                <div className="mb-12 selectFundraiser">
                  <Selector
                    label={""}
                    labelClassName="lg:text-[14px] sm:text-[12px] font-[400] text-[#303030] mb-2 font-poppins lg:leading-[27px] sm:leading-[21px]"
                    divClassName="w-[100%]"
                    selectClassName="w-full mb-2 h-[40px] sm:h-[45px] rounded-[8px] bg-[#FFFFFF] text-[#303030] text-[14px] font-[400] outline-none !font-poppins !focus:outline-none !border-none shadow-[0_4px_4px_0px_rgba(0,0,0,0.06)]"
                    flexClassName="gap-1"
                    options={fundraiserCategoriesOptions}
                    suffixIcon={<DownArrow />}
                    placeholder={"Select Fundraiser Category"}
                    selectFundraiser={true}
                    onChange={handleCategoryChange}
                    handleBlur={handleBlur}
                    error={errors.category}
                    touched={touched.category}
                  />
                  {category !== "" && (
                    <Selector
                      label={""}
                      labelClassName="lg:text-[14px] sm:text-[12px] font-[400] text-[#303030] mb-2 font-poppins lg:leading-[27px] sm:leading-[21px]"
                      divClassName="w-[100%]"
                      selectClassName="w-full h-[40px] sm:h-[45px] rounded-[8px] bg-[#FFFFFF] text-[#303030] text-[14px] font-[400] outline-none !font-poppins !focus:outline-none !border-none shadow-[0_4px_4px_0px_rgba(0,0,0,0.06)]"
                      flexClassName="gap-1"
                      options={fundraisersOptions}
                      suffixIcon={<DownArrow />}
                      placeholder={"Select Fundraiser"}
                      value={fundraiser}
                      selectFundraiser={true}
                      onChange={handleFundraiserChange}
                      handleBlur={handleBlur}
                      error={errors.fundraiser}
                      touched={touched.fundraiser}
                    />
                  )}
                </div>
              </>
            )}

            {/* Current Plan Card */}

            <div className="w-[100%]">
              <div className="w-full sm:px-11 flex justify-between items-center">
                <div className="">
                  <span className="font-[500] text-[16px] font-poppins text-[#303030] overflow-wrap">
                    Your order details
                  </span>
                </div>
                <div className="">
                  <span className="flex font-[600] text-[16px] font-poppins font text-[#303030]  overflow-wrap ">
                    ${selectedPlan?.amount ? selectedPlan?.amount : ""}
                  </span>
                </div>
              </div>

              <div
                className="rounded-[10px] border-[2px] border-[#00ADEF4D] mt-5 sm:mt-[37px]"
                style={{ boxShadow: "0px 4px 6px 0px #00000040" }}
              >
                <div className="flex justify-between bg-[#00ADEF1A] px-5 py-4 sm:px-9">
                  <span className=" text-[#303030] font-poppins font-[600] text-[16px] sm:text-[18px] overflow-wrap ">
                    CURRENT PLAN
                  </span>
                  <span className="flex  text-[#303030] font-poppins font-[600] text-[16px] sm:text-[18px] overflow-wrap ">
                    AMOUNT
                  </span>
                </div>
                <div className="px-5 sm:px-9 rounded-bl-[10px] rounded-br-[10px]">
                  <div
                    className="flex justify-between py-9"
                    style={{ borderBottom: "1px solid #DFE1E6" }}
                  >
                    <span className="text-[#303030] font-poppins font-[400] text-[14px]">
                      Upto{" "}
                      {selectedPlan?.duration ? selectedPlan?.duration : ""}
                    </span>
                    <span className="text-[#303030] font-poppins font-[600] text-[14px] ">
                      ${selectedPlan?.amount ? selectedPlan?.amount : ""}
                    </span>
                  </div>
                  <div
                    className="flex justify-between py-5"
                    style={{ borderBottom: "1px solid #DFE1E6" }}
                  >
                    <span className="text-[#303030] font-poppins font-[400] text-[14px]">
                      Total
                    </span>
                    <span className="text-[#303030] font-poppins font-[600] text-[14px]">
                      ${selectedPlan?.amount ? selectedPlan?.amount : ""}
                    </span>
                  </div>
                  <div className="my-4">
                    <p className="font-poppins font-[400] text-[12px] text-[#8C8C8C]">
                      $14 donated to Red Nose Australia
                    </p>
                  </div>
                </div>
              </div>
              <div className="text-center mt-12">
                <PrimaryButton
                  label={"Buy Subscription"}
                  className={
                    "w-[65%] sm:w-[55%] text-[14px] font-[600] h-[40px] sm:h-[40px] md:h-[45px] rounded-[10px]"
                  }
                  onClick={handleSubmit}
                />
              </div>
              <div className="mt-12 text-center mb-12">
                <span className="font-[400] text-[14px] font-poppins">
                  By selecting a plan, you agree to our
                  <span className="text-[#00ADEF] font-[400] font-poppins cursor-pointer" onClick={() => window.open("/privacy/toc", "_blank")}>
                    {" "}
                    Terms of Services
                  </span>{" "}
                  and
                  <span className="text-[#00ADEF] font-[400] font-poppins cursor-pointer" onClick={() => window.open("/privacy/policy", "_blank")}>
                    {" "}
                    Privacy Policy
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="w-[0%] sm:w-[10%] md:w-[22%] lg:w-[27%] 2xl:w-[30%]"></div>
        </div>
      )}
    </>
  );
}

export default Subscription;
