import React from "react";
import { Route, Routes } from "react-router-dom";
import ForgetPassword from "../pages/common/ForgetPassword";
import Login from "../pages/common/Login";
import SignUp from "../pages/common/SignUp";
import OTPVerification from "../pages/common/OTPVerification";
import ResetPassword from "../pages/common/ResetPassword";
import Welcome from "../pages/common/Welcome";
import AccountSetup from "../pages/common/AccountSetup";
import MerchantOnboardLayout from "../components/Merchant/MerchantOnboarding/MerchantOnboardLayout";
import Privacy from "../components/common/Privacy";

const CommonRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/fundraiser/welcome" element={<Welcome />} />
      <Route path="/complete-setup" element={<AccountSetup />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="merchant/signup" element={<SignUp />} />
      <Route path="fundraiser/signup" element={<SignUp />} />
      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route path="/otp-verification" element={<OTPVerification />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route
        path="/privacy/:doc"
        element={<MerchantOnboardLayout element={<Privacy />} />}
      />
    </Routes>
  );
};

export default CommonRoutes;
