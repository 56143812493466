import React from "react";
import Header from "../WelcomeHeader";
import { useSelector } from "react-redux";
import Loader from "../Loader";
import FundraiserWelcome from "./FundraiserWelcome";
import { roleEnum } from "../../../utils/utilFunctions";
import { useLocation } from "react-router-dom";
import MerchantWelcome from "./MerchantWelcome";

const CommonWelcome = () => {
  const loading = useSelector((state) => state.fundraiserReducer.loading)
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div className="w-full flex flex-col justify-center items-center">
      {loading ? <Loader /> : null}
      <Header />
      {currentPath.includes(roleEnum.fundraiser) ? <FundraiserWelcome/>: <MerchantWelcome/>}
    </div>
  );
};

export default CommonWelcome;
