// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listItemDiv {
  color: #8C8C8C;
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins, sans-serif;
}

.listItemDiv:hover {
  background-color: rgba(0, 173, 239, 0.0784313725);
  color: #00ADEF;
}

.selectedListItem {
  background-color: rgba(0, 173, 239, 0.0784313725);
  color: #00ADEF;
  border-left: 6px solid #00ADEF;
  padding-left: 21px !important;
}

.subListItemText {
  color: #8C8C8C;
  font-size: 14px;
  font-weight: 400;
  font-family: Poppins, sans-serif;
}

.subListItemText:hover {
  color: #00ADEF;
}

.selectedSubListItem {
  color: #00ADEF;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Sidebar/sidebar.scss"],"names":[],"mappings":"AAGA;EACI,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gCAAA;AAFJ;;AAKA;EACI,iDAAA;EACA,cAAA;AAFJ;;AAKA;EACI,iDAAA;EACA,cAAA;EAEA,8BAAA;EACA,6BAAA;AAHJ;;AAOA;EACI,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gCAAA;AAJJ;;AAOA;EACI,cAAA;AAJJ;;AAOA;EACI,cAAA;AAJJ","sourcesContent":["\n////////////////////////////// Custom Sidebar Css //////////////////////////////\n\n.listItemDiv{\n    color: #8C8C8C;\n    font-size: 14px;\n    font-weight: 500;\n    font-family: Poppins, sans-serif;\n}\n\n.listItemDiv:hover{\n    background-color: #00ADEF14;\n    color: #00ADEF;\n}\n\n.selectedListItem{\n    background-color: #00ADEF14;\n    color: #00ADEF;\n\n    border-left: 6px solid #00ADEF;\n    padding-left: 21px !important;\n    // padding-right: 21px !important;\n}\n\n.subListItemText{\n    color: #8C8C8C;\n    font-size: 14px;\n    font-weight: 400;\n    font-family: Poppins, sans-serif;\n}\n\n.subListItemText:hover{\n    color: #00ADEF;\n}\n\n.selectedSubListItem{\n    color: #00ADEF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
