import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/common/Dashboard";
import FeaturedForm from "../pages/common/FeaturedForm";
import NewOffer from "../pages/common/NewOffer";
import OfferList from "../pages/common/OfferList";
import FranchiseForm from "../pages/Merchant/FranchiseForm";
import FranchiseList from "../pages/Merchant/FranchiseList";
import Welcome from "../pages/common/Welcome";
import MerchantOnboardLayout from "../components/Merchant/MerchantOnboarding/MerchantOnboardLayout";
import WelcomeOfferLayout from "../pages/Merchant/WelcomeOfferLayout";
import GettingStarted from "../pages/Merchant/GettingStarted";
// import SetUpAccount from "../components/Merchant/MerchantOnboarding/SetUpAccountOld";
import CreateAccount from "../pages/Merchant/CreateAccount";
import AccountCreated from "../pages/Merchant/AccountCreated";
import AccountLogin from "../pages/Merchant/AccountLogin";
import SetUpAccount from "../pages/Merchant/SetUpAccount";

const MerchantRoutes = () => {
  return (
    <Routes>
      <Route
        path="/merchant/dashboard"
        element={<FeaturedForm element={<Dashboard />} />}
      />
      <Route
        path="/merchant/offer-form"
        element={<FeaturedForm element={<NewOffer merchant={true} />} />}
      />
      <Route
        path="/merchant/offer-list"
        element={<FeaturedForm element={<OfferList />} />}
      />
      <Route
        path="/merchant/franchise-form"
        element={<FeaturedForm element={<FranchiseForm />} />}
      />
      <Route
        path="/merchant/franchise-list"
        element={<FeaturedForm element={<FranchiseList />} />}
      />
      <Route path="/merchant/welcome" element={<Welcome />} />
      <Route path="/merchant/welcome-offer" element={<WelcomeOfferLayout />} />
      <Route
        path="/merchant/getting-started"
        element={<MerchantOnboardLayout element={<GettingStarted />} />}
      />
      {/* <Route
                path="/account-setup"
                element={<MerchantOnboardLayout element={<SetUpAccount1/>} />}
              /> */}
      <Route path="/account-setup" element={<SetUpAccount />} />
      {/* <Route
                path="/create-account"
                element={<MerchantOnboardLayout element={<CreateAccount/>} />}
              /> */}
      <Route
        path="/welcome-onboard/:verificationCode"
        element={<MerchantOnboardLayout element={<CreateAccount />} />}
      />
      <Route
        path="/account-created"
        element={<MerchantOnboardLayout element={<AccountCreated />} />}
      />
      <Route
        path="/account-login"
        element={<MerchantOnboardLayout element={<AccountLogin />} />}
      />
    </Routes>
  );
};

export default MerchantRoutes;
