import React from "react";
import OnboardSwoodleLogo from "../../../Assets/Images/Onboard Swoodle Logo.svg";
import MerchantSwoodleImg from "../../../Assets/Images/merchantSwoodleImg.svg";
import "./setup.css";

const SetUpAccount = () => {
  return (
    <div className="layoutDiv">
      <div className="headerDiv">
        <img className="headerImg" src={OnboardSwoodleLogo}></img>
      </div>
      <div className="parentDiv">
        <p className="headingStyle">
          Welcome to Swoodle! Let’s set up your Account!
        </p>
        <div className="internalDiv">
          <img
            src={OnboardSwoodleLogo}
            className="swoodleLogoStyle"
            alt="Swoodle Logo"
          />
          <p className="headingStyle">MERCHANT</p>
          <img
            src={MerchantSwoodleImg}
            className="swoodleImgStyle"
            alt="Merchant Swoodle"
          />
          <div className="textContainer">
            <p className="textHeadStyle">
              Welcome! Let’s Start Promoting Your Business
            </p>
            <p className="textStyle" style={{ marginTop: "20px" }}>
              Dear Merchant,
            </p>
            <p className="textStyle" style={{ marginTop: "8px" }}>
              The Swoodle team welcomes you to our platform!All that’s left is
              to set your password for your Merchant Centre account, and then
              you can begin building Swoodle campaigns with the self-service
              Campaign Manager.Simply click on the “Set Up Your Account” button
              below, create a password and you’ll be able to start building your
              campaign.
            </p>
            <p className="textStyle" style={{ marginTop: "20px" }}>
              Regards,
            </p>
            <p className="textStyle" style={{ marginTop: "4px" }}>
              The Swoodle Team
            </p>
          </div>
          <div className="buttonContainer">
            <button className="buttonStyle" onClick={() => {}}>
              Set Up Your Account
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetUpAccount;
