import React, { useEffect, useState } from "react";
import { gettingStartedSchema } from "../../../utils/validationSchemas";
import { useFormik } from "formik";
import Input from "../../common/FormInputs";
import Selector from "../../common/Selector";
import { getCategories } from "../../../services/offerService";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as DownArrow } from "../../../Assets/Icons/greyDownArrow.svg";
import GoogleMaps from "../../common/GoogleMaps";
import InputTextArea from "../../common/InputTextArea";
import PrimaryButton from "../../common/PrimaryButton";
import PhoneFormInput from "../../common/PhoneInput";
import { sendInvite } from "../../../services/onboardingService";
import msgIcon from "../../../Assets/Icons/envelopIcon.svg";
import Loader from "../../common/Loader";
import DetailsPopup from "../../common/DetailsPopup";

const GettingStarted = () => {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [placeId, setPlaceId] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const [categoryIds, setCategoryIds] = useState([]);
  const [emailSent, setEmailSent] = useState(false);
  const loading = useSelector((state) => state?.onboardingReducer?.loading);

  const getCategoriesData = async () => {
    const categoryPromise = await dispatch(getCategories())?.unwrap();
    if (categoryPromise?.success === true) {
      setCategories(categoryPromise?.data);
    }
  };

  const businessCategoryOptions = categories?.map((cat) => ({
    value: cat?.title ?? "abc",
    label: cat?.title ?? "dsc",
  }));

  useEffect(() => {
    getCategoriesData();
  }, []);

  const initialValues = {
    businessName: "",
    businessAddress: "",
    websiteUrl: "",
    personName: "",
    personContact: "",
    email: "",
    businessCategory: [],
    aboutBusiness: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: gettingStartedSchema,

    onSubmit: async (values) => {
      console.log("create values", values);
      const payload = {
        businessName: values?.businessName,
        googlePlaceId: placeId,
        website: values?.websiteUrl,
        contactPersonName: values?.personName,
        contactPersonNumber: values?.personContact,
        email: values?.email,
        category: categoryIds,
        description: values?.aboutBusiness,
      };
      const sendPromise = await dispatch(sendInvite(payload))?.unwrap();
      if (sendPromise?.success === true) {
        setEmailSent(true);
      }
    },
  });

  const {
    businessName,
    businessAddress,
    websiteUrl,
    personName,
    personContact,
    email,
    businessCategory,
    aboutBusiness,
  } = formik.values;

  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    setValues,
  } = formik;

  const handleLocationClick = () => {
    setShowMap(true);
  };

  const handleLocationSelect = (selectedLocation) => {
    setFieldValue("businessAddress", selectedLocation?.placeName);
    setPlaceId(selectedLocation?.placeId);
  };

  const handleCategoryChange = (e) => {
    if (e) {
      setFieldValue("businessCategory", e);
    }
  };

  useEffect(() => {
    if (businessCategory?.length !== 0 && categories?.length !== 0) {
      const catIds = businessCategory?.map((businessCategoryName) => {
        const matchingCategories = categories?.filter(
          (cat) => cat?.title === businessCategoryName
        );
        return matchingCategories?.map((cat) => cat?._id);
      });
      setCategoryIds(catIds?.flat());
    }
  }, [businessCategory, categories]);

  return (
    <div className="w-full py-10 h-screen flex flex-col items-center offerform">
      {loading && <Loader />}
      {emailSent === true && (
        <DetailsPopup
          content={"Please review your email for the invitation to join Swoodle."}
          icon={msgIcon}
          popupClassName={"w-[335px] sm:w-[470px] pb-10"}
        />
      )}
      <p className="font-poppins font-[500] sm:text-[18px] md:text-[24px] lg:text-[32px] text-[#303030]">
        Get Started with Swoodle Today
      </p>
      <p className="font-poppins font-[400] sm:text-[12px] md:text-[14px] lg:text-[18px] text-[#767676] leading-[27px] mt-8">
        It's Easy!
      </p>
      <p className="font-poppins font-[400] sm:text-[12px] md:text-[14px] lg:text-[18px] text-[#767676] leading-[27px] mb-5">
        Fill out the below to start and your journey today with $0 upfront
        costs!
      </p>
      <div className="flex flex-col items-center sm:w-[70%] md:w-[60%] lg:w-[50%] xl:w-[45%] 2xl:w-[40%] getStarted">
        <Input
          type="text"
          label=""
          placeholder="Business Name"
          width="100%"
          radius="5px"
          InputDivClass="w-full mt-4"
          name="businessName"
          value={businessName}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.businessName}
          touched={touched.businessName}
        />
        <Input
          type="text"
          label=""
          placeholder="Business Address"
          width="100%"
          radius="5px"
          InputDivClass="w-full mt-4"
          name="businessAddress"
          value={businessAddress}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.businessAddress}
          touched={touched.businessAddress}
          onClick={handleLocationClick}
        />
        {showMap && (
          <GoogleMaps
            onLocationSelect={handleLocationSelect}
            searchContainerClass="sm:w-[45%] md:w-[48%] lg:w-[53%] xl:w-[58%] 2xl:w-[59%] sm:left-[42%] md:left-[40%] lg:left-[36%] xl:left-[32%] 2xl:left-[31%]"
          />
        )}
        <Input
          type="text"
          label=""
          placeholder="Website URL"
          width="100%"
          radius="5px"
          InputDivClass="w-full mt-4"
          name="websiteUrl"
          value={websiteUrl}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.websiteUrl}
          touched={touched.websiteUrl}
        />
        <Input
          type="text"
          label=""
          placeholder="Contact Person Name"
          width="100%"
          radius="5px"
          InputDivClass="w-full mt-4"
          name="personName"
          value={personName}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.personName}
          touched={touched.personName}
        />
        <PhoneFormInput
          label={""}
          divClassName="w-full"
          name="personContact"
          value={personContact}
          handleBlur={handleBlur}
          error={errors.personContact}
          touched={touched.personContact}
          setFieldValue={setFieldValue}
          inputStyle={{
            width: "100%",
            border: "none",
          }}
          buttonStyle={{ border: "none" }}
          buttonClass="!rounded-l-[5px] !bg-[#D9D9D9] md:!w-[13%] sm:!w-[15%] lg:!w-[9%] xl:!w-[7%] 2xl:!w-[6%]"
          containerClass="!rounded-[5px] border border-[#D9D9D9]"
          inputClass="lg:!h-[45px] md:!h-[45px] !rounded-[5px] !bg-[#FFFFFF] !text-[#303030] lg:!text-[16px] sm:!text-[14px] !font-[400] !outline-none !font-poppins md:!pl-[17%] sm:!pl-[17%] lg:!pl-[11%] xl:!pl-[9%] 2xl:!pl-[8%]"
          labelClass="mb-5"
          errorClass={
            "text-red-600 sm:text-[10px] md:text-[12px] font-[600] ms-1"
          }
        />
        <Input
          type="text"
          label=""
          placeholder="Email Address"
          width="100%"
          radius="5px"
          InputDivClass="w-full mt-4"
          name="email"
          value={email}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.email}
          touched={touched.email}
        />
        <Selector
          value={businessCategory}
          label={""}
          labelClassName="lg:text-[14px] sm:text-[12px] font-[400] text-[#303030] mb-2 font-poppins lg:leading-[27px] sm:leading-[21px]"
          divClassName="mt-4 w-full multipleField"
          selectClassName="w-full rounded-[5px] bg-[#FFFFFF] text-[#303030] lg:text-[16px] sm:text-[10px] font-[400] outline-none !font-poppins !focus:outline-none"
          flexClassName="gap-1"
          options={businessCategoryOptions}
          suffixIcon={<DownArrow />}
          placeholder={"Select Business Category"}
          onChange={handleCategoryChange}
          handleBlur={handleBlur}
          error={errors.businessCategory}
          touched={touched.businessCategory}
          multiple={true}
        />
        <InputTextArea
          label={"Tell more about your business"}
          placeholder={
            "Please share with us the reasons why customers should choose to visit your establishment and make purchases/ use your services"
          }
          divClassName="relative mt-4 w-full"
          labelClassName="invitationLabels"
          textareaClassName="w-full h-[150px] px-[1%] pt-2 rounded-[5px] bg-[#FFFFFF] border border-[#D9D9D9] text-[#303030] lg:text-[14px] sm:text-[12px] font-[400] outline-none resize-none font-poppins"
          name="aboutBusiness"
          value={aboutBusiness}
          handleBlur={handleBlur}
          error={errors.aboutBusiness}
          touched={touched.aboutBusiness}
          setFieldValue={setFieldValue}
        />
        <p className="text-[10px] sm:text-[12px] md:text-[14px] font-poppins font-[400] mt-8 text-center w-[90%]">
          By clicking sign up, I agree to the
          <span className="text-[#00ADEF] font-[500] cursor-pointer" onClick={() => window.open("/privacy/toc", "_blank")}> Terms of Use </span>
          that I have read the
          <span className="text-[#00ADEF] font-[500] cursor-pointer" onClick={() => window.open("/privacy/policy", "_blank")}> Privacy Policy.</span>
        </p>

        <PrimaryButton
          className={
            "sm:w-[50%] md:w-[60%] lg:w-[70%] font-poppins sm:text-[16px] md:text-[18px] lg:text-[20px] text-center font-[500] sm:h-[50px] md:h-[55px] lg:h-[60px] rounded-[10px] my-10"
          }
          label="SIGN UP"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default GettingStarted;
