import React from "react";
import Header from "../WelcomeHeader";
import { ReactComponent as IncompleteImg } from "../../../Assets/Images/incompleteImg.svg";
import PrimaryButton from "../PrimaryButton";
import { useDispatch } from "react-redux";
import { createConnectedAccount } from "../../../services/fundraiserService";

const CompleteSetup = () => {
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const linkPromise = await dispatch(createConnectedAccount()).unwrap();
    window.location.href = linkPromise?.data;
  }

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <Header />
      <div className="sm:w-[80%] md:w-[70%] lg:w-[60%] py-20">
        <p className="font-poppins font-[500] text-[28px] text-center text-[#303030]">
          Complete Your Account Setup
        </p>
        <p className="font-poppins font-[500] text-[18px] text-start text-[#303030] mt-10">
          Account Setup
        </p>
        <p className="font-poppins font-[400] text-[14px] text-start text-[#767676] mt-2">
          It appears that your account setup is not yet complete. We require
          additional information about your business, along with the necessary
          legal documents. Kindly finalize your account details to gain access
          to the Swoodle fundraising portal and begin utilizing its features.
        </p>
        <div className="mt-20 flex justify-center items-center">
          <IncompleteImg />
        </div>
        <div className="w-full text-end mt-20">
          <PrimaryButton
            className={
              "w-[30%] font-montserrat text-[14px] text-center font-[500] h-[45px] rounded-[5px]"
            }
            label="Complete Account Setup"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default CompleteSetup;
