import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

function DropZone(props) {
  const maxSize = 10485760;

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      props.rejectedFiles(rejectedFiles);
    }

    if (acceptedFiles.length > 0) {
      props.acceptedFiles(acceptedFiles);
    }
  }, []);

  const {
    isDragActive,
    getRootProps,
    getInputProps,
    isDragReject,
    acceptedFiles,
    rejectedFiles,
    getFilesFromEvent,
  } = useDropzone({
    onDrop,
    minSize: 0,
    maxSize,
    noClick: false,
    noKeyboard: true,
    accept: props.accept,
    multiple: props.multiple,
  });

  const isFileTooLarge =
    rejectedFiles?.length > 0 && rejectedFiles[0].size > maxSize;

  const getFiles = getFilesFromEvent?.map((file) => {
    console.log("file ", file);
  });

  return (
    <div className="h-full w-full">
      <div {...getRootProps()} className="w-full h-full">
        <input {...getInputProps()} onClick={(e) => (e.target.value = null)} />
        <div className="h-full w-full flex flex-col items-center">
          {isDragActive && !isDragReject ? (
            <div className="flex justify-center items-center mt-[10%]">
              <span className="sm:text-[16px] md:text-[20px] font-[400] text-[#8C8C8C] mb-1 font-nunito text-center">
                Drop your file here...
              </span>
            </div>
          ) : (
            !isDragReject && (
              <div className="h-full w-full flex flex-col justify-center gap-4 items-center">
                {props?.img}
                <div className="flex flex-col items-center justify-center">
                  <span className="sm:text-[16px] md:text-[20px] font-[400] text-[#8C8C8C] mb-1 font-nunito">
                    Drag & Drop
                  </span>
                  <span className="sm:text-[16px] md:text-[20px] font-[400] text-[#8C8C8C] font-nunito">
                    Your files here Or{" "}
                    <span className="text-[#00ADEF]">Browse</span> to upload
                  </span>
                </div>
              </div>
            )
          )}
          <span className="sm:text-[10px] md:text-[12px] font-[400] text-[#8C8C8C] font-nunito">
            {props.supportText}
          </span>
          <input
            {...getInputProps()}
            onClick={(e) => (e.target.value = null)}
          />
          {isDragReject && (
            <div className="flex justify-center items-center mt-[10%]">
              <span className="sm:text-[16px] md:text-[20px] font-[400] text-[#8C8C8C] mb-1 font-nunito text-center">
                "File type must be png or jpeg!"
              </span>
            </div>
          )}
          {isFileTooLarge && (
            <div className="sm:text-[16px] md:text-[20px] font-[400] text-[#8C8C8C] mb-1 font-nunito text-center mt-[10%]">
              File is too large.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DropZone;
