import React from "react";
import "./common.scss";

const SuccessPopup = ({ content, image }) => {
  return (
    <div className="popup-container">
      <div className="popup relative flex flex-col justify-center gap-3 items-center w-[335px] sm:w-[400px] h-[200px] sm:h-[230px] bg-white rounded-[10px] drop-shadow-[0_3px_3px_rgba(0,0,0,0.25)] popup">
        <img src={image} className="w-[70px] h-[70px] "/>
        <div className="flex flex-col justify-center items-center sm:gap-2">
          <p className="text-[32px] sm:text-[34px] font-[700] font-montserrat text-[#303030]">
            SUCCESS!
          </p>
          <p className="text-[14px] sm:text-[16px] font-[400] font-montserrat text-[#303030]">
            {content}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SuccessPopup;
