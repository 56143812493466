import React from "react";

const MerchantFormInput = ({
  label,
  width,
  placeholder,
  name,
  value,
  type,
  handleChange,
  handleBlur,
  error,
  touched,
  onClick
}) => {
  return (
    <div className="mt-5" style={{ width: width }}>
      <p className="lg:text-[14px] sm:text-[12px] font-[400] text-[#303030] mb-2 font-poppins lg:leading-[27px] sm:leading-[21px]">
        {label}
      </p>
      <input
        name={name}
        value={value}
        placeholder={placeholder}
        type={type ? type :"text"}
        className="w-full lg:h-[40px] sm:h-[40px] rounded-[8px] bg-[#FFFFFF] text-[#303030] lg:text-[16px] sm:text-[14px] font-[400] outline-none ps-4 font-poppins"
        style={{ boxShadow: "0px 4px 4px 0px #0000000F" }}
        onChange={handleChange}
        onBlur={handleBlur}
        onClick={onClick}
      />
      {error && touched ? (
        <span className="text-red-600 sm:text-[10px] md:text-[12px] font-[600] ms-1 mt-1">{error}</span>
        ) : null}
    </div>
  );
};

export default MerchantFormInput;