import React, { useEffect, useState } from "react";
import Input from "../FormInputs";
import { ReactComponent as DownArrow } from "../../../Assets/Icons/greyDownArrow.svg";
import Selector from "../Selector";
import { Slider } from "antd";
import RadioSelection from "../RadioSelection";
import InputTextArea from "../InputTextArea";
import UploadDocument from "./UploadDocument";
import UploadDocuments from "./UploadDocuments";
import { useFormik } from "formik";
import PrimaryButton from "../PrimaryButton";
import GreyButton from "../GreyButton";
import { offerCreationSchema } from "../../../utils/validationSchemas";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";
import { createOffer } from "../../../services/offerService";
import { ReactComponent as DollarIcon } from "../../../Assets/Icons/dollarIcon.svg";

const OfferCreation = ({
  stepIndex,
  setStepIndex,
  handlePrevious,
  setPayloadData,
  editOfferData,
  isEdit,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.offerReducer?.loading);
  const offerData = useSelector((state) => state?.offerReducer?.createdOffer);
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  const [previewImgsUrl, setPreviewImgsUrl] = useState([]);
  const [logoFileImage, setLogoFileImage] = useState();
  const [logoFileImage1, setLogoFileImage1] = useState(null);
  const [logoFileImage2, setLogoFileImage2] = useState(null);
  const [logoFileImage3, setLogoFileImage3] = useState(null);
  const [logoFileImage4, setLogoFileImage4] = useState(null);

  const fillUpForm = (data) => {
    setFieldValue("title", data?.title !== undefined ? data?.title : "");
    switch (data?.offerType) {
      case "free_offer":
        setFieldValue("offerType", "Free Offer");
        break;
      case "discount_offer":
        setFieldValue("offerType", "Discounted Offer");
        break;
      case "two_for_one_offer":
        setFieldValue("offerType", "2for1 Offer");
        break;
      case "bundle_offer":
        setFieldValue("offerType", "Bundle offer");
        break;
      default:
        setFieldValue("offerType", "");
    }
    setFieldValue(
      "offer",
      data?.isFeatured === true
        ? "Featured Offers"
        : data?.isHot === true
        ? "Hot Offers"
        : ""
    );
    setRangeValues([0, data?.discountRange !== 0 ? data?.discountRange : 25]);
    setFieldValue(
      "range",
      data?.discountRange !== 0 ? data?.discountRange : 25
    );
    setFieldValue("savedMoney", data?.moneySaved !== 0 ? data?.moneySaved : "");
    setFieldValue(
      "offerDescription",
      data?.description !== undefined ? data?.description : ""
    );
    setFieldValue(
      "thumbnail",
      data?.thumbnailImage !== undefined ? data?.thumbnailImage : ""
    );
    setThumbnailUrl(
      data?.thumbnailImage !== undefined ? data?.thumbnailImage : ""
    );
    setLogoFileImage(
      data?.thumbnailImage !== undefined ? data?.thumbnailImage : ""
    );
    setFieldValue("previewImgs", data?.previewImages);
    setPreviewImgsUrl(data?.previewImages);
    data?.previewImages?.map((p) => {
      if (p.includes(`${offerData?._id}-previewImg1`)) {
        setLogoFileImage1(p);
      } else if (p.includes(`${offerData?._id}-previewImg2`)) {
        setLogoFileImage2(p);
      } else if (p.includes(`${offerData?._id}-previewImg3`)) {
        setLogoFileImage3(p);
      } else if (p.includes(`${offerData?._id}-previewImg4`)) {
        setLogoFileImage4(p);
      }
    });
  };

  useEffect(() => {
    if (editOfferData !== null && isEdit == true) {
      fillUpForm(editOfferData);
    } else {
      fillUpForm(offerData);
    }
  }, [offerData, editOfferData]);

  const initialValues = {
    title: "",
    offerType: undefined,
    offer: "",
    savedMoney: "",
    offerDescription: "",
    range: 25,
    thumbnail: "",
    previewImgs: [],
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: offerCreationSchema,

    onSubmit: async (values) => {
      console.log("create values", values);
      const createPromise = await dispatch(createOffer(payload))?.unwrap();
      if (createPromise?.success === true) {
        setStepIndex(stepIndex + 1);
      }
    },
  });

  const {
    title,
    offerType,
    offer,
    savedMoney,
    offerDescription,
    range,
    thumbnail,
    previewImgs,
  } = formik.values;

  const payload = {
    title: title,
    offerType:
      offerType === "Free Offer"
        ? "free_offer"
        : offerType === "Discounted Offer"
        ? "discount_offer"
        : offerType === "2for1 Offer"
        ? "two_for_one_offer"
        : offerType === "Bundle offer"
        ? "bundle_offer"
        : "",
    isFeatured: offer === "Featured Offers" ? true : false,
    isHot: offer === "Hot Offers" ? true : false,
    discountRange: range,
    moneySaved: savedMoney,
    thumbnailImage: thumbnailUrl,
    previewImages: previewImgsUrl,
    description: offerDescription,
    id: offerData?._id,
    isCreated: false,
  };

  useEffect(() => {
    setPayloadData(payload);
  }, [
    title,
    offerType,
    offer,
    range,
    savedMoney,
    thumbnailUrl,
    previewImgsUrl,
    offerDescription,
    offerData?._id,
  ]);

  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    setValues,
  } = formik;

  const offerTypeOptions = [
    { label: "Free Offer", value: "Free Offer" },
    { label: "Discounted Offer", value: "Discounted Offer" },
    { label: "2 for 1 Offer", value: "2for1 Offer" },
    { label: "Bundle offer", value: "Bundle offer" },
  ];

  const handleTypeChange = (e) => {
    setFieldValue("offerType", e);
  };

  const radioOptions = ["Featured Offers", "Hot Offers"];
  const [rangeValues, setRangeValues] = useState([0, 25]);

  const handleRangeChange = ([firstValue, secondValue]) => {
    setRangeValues([0, secondValue]);
    setFieldValue("range", secondValue);
  };
  return (
    <div className="w-full flex flex-col items-center justify-center mt-20">
      {loading === true && <Loader />}
      <div className="sm:w-[100%] md:w-[80%] lg:w-[70%] xl:w-[55%] offerform">
        <p className="font-poppins font-[500] sm:text-[12px] md:text-[14px] lg:text-[16px] text-[#303030]">
          Let’s make your offer stand out!
        </p>
        <p className="font-poppins font-[400] sm:text-[10px] md:text-[12px] text-[#767676]">
          Briefly describe the highlights of offer
        </p>
        <Input
          type="text"
          label="Offer Title"
          placeholder=""
          width="100%"
          radius="5px"
          InputDivClass="w-[100%] mt-8"
          name="title"
          value={title}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.title}
          touched={touched.title}
        />
        <Selector
          value={offerType}
          label={"Offer Type"}
          labelClassName="lg:text-[14px] sm:text-[12px] font-[400] text-[#303030] font-poppins leading-[21px] mb-[4px]"
          divClassName="mt-5 w-[100%]"
          selectClassName="w-full rounded-[5px] bg-[#FFFFFF] text-[#303030] lg:text-[16px] sm:text-[10px] font-[400] !font-poppins !focus:outline-none"
          flexClassName="gap-3"
          options={offerTypeOptions}
          suffixIcon={<DownArrow />}
          placeholder={"Select offer type"}
          onChange={handleTypeChange}
          handleBlur={handleBlur}
          error={errors.offerType}
          touched={touched.offerType}
        />

        <RadioSelection
          options={radioOptions}
          divClassName="mt-10"
          internalDivClass="flex flex-col items-start"
          secondRadioClass="mt-3"
          textClass="lg:text-[14px] sm:text-[12px] font-[400] text-[#303030] ml-4"
          label={""}
          width={"100%"}
          name="offer"
          value={offer}
          error={errors.offer}
          touched={touched.offer}
          setFieldValue={setFieldValue}
        />
        <div className="w-full relative">
          <Input
            type="number"
            label="How much money is saved by redeeming this offer?"
            placeholder=""
            width="100%"
            radius="5px"
            InputDivClass="w-[100%] mt-8"
            name="savedMoney"
            value={savedMoney}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors.savedMoney}
            touched={touched.savedMoney}
          />
          <DollarIcon className="absolute right-3 sm:top-[3.5rem] md:top-[2.15rem] w-[25px] h-[25px]"/>
        </div>

        <div className="mt-8 offerForm w-full">
          <p className="invitationLabels">Discount Range</p>
          <Slider
            className="mt-2 w-full"
            range
            defaultValue={[0, 25]}
            disabled={false}
            onChange={handleRangeChange}
            value={rangeValues}
            name="range"
            onBlur={handleBlur}
          />
          <p className="invitationLabels ms-5">{`${
            rangeValues[1] === "" ? 0 : rangeValues[1]
          }%`}</p>
        </div>

        <InputTextArea
          label={"Offer Description"}
          placeholder={"Description"}
          divClassName="relative mt-5 w-full"
          labelClassName="invitationLabels"
          textareaClassName="w-full h-[150px] px-[1%] pt-2 rounded-[5px] bg-[#FFFFFF] border border-[#D9D9D9] text-[#303030] lg:text-[14px] sm:text-[12px] font-[400] outline-none resize-none font-poppins"
          name="offerDescription"
          value={offerDescription}
          handleBlur={handleBlur}
          error={errors.offerDescription}
          touched={touched.offerDescription}
          setFieldValue={setFieldValue}
        />
        <p className="font-poppins font-[500] sm:text-[12px] md:text-[14px] lg:text-[16px] text-[#303030] mt-5">
          Add a Thumbnail
        </p>
        <p className="font-poppins font-[400] sm:text-[10px] md:text-[12px] text-[#767676] mt-2">
          Select or upload a picture
        </p>
        <UploadDocument
          thumbnail={thumbnail}
          name={"thumbnail"}
          iconText={"Upload Thumbnail"}
          error={errors?.thumbnail}
          touched={touched?.thumbnail}
          setFieldValue={setFieldValue}
          parentDivClass={"h-[406px] w-[100%] mt-5"}
          setS3Url={setThumbnailUrl}
          logoFileImage={logoFileImage}
          setLogoFileImage={setLogoFileImage}
        />
        <p className="font-poppins font-[500] sm:text-[12px] md:text-[14px] lg:text-[16px] text-[#303030] mt-10">
          Preview Images
        </p>
        <p className="font-poppins font-[400] sm:text-[10px] md:text-[12px] text-[#767676] mt-2">
          Make your offer stand out with great images, to help your offer
          perform better, add maximum four images
        </p>
        <UploadDocuments
          previewImgs={previewImgs}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          s3Urls={previewImgsUrl}
          setS3Urls={setPreviewImgsUrl}
          logoFileImage1={logoFileImage1}
          setLogoFileImage1={setLogoFileImage1}
          logoFileImage2={logoFileImage2}
          setLogoFileImage2={setLogoFileImage2}
          logoFileImage3={logoFileImage3}
          setLogoFileImage3={setLogoFileImage3}
          logoFileImage4={logoFileImage4}
          setLogoFileImage4={setLogoFileImage4}
          offerId={offerData?._id}
        />
        <div className="w-full text-end mt-20">
          <GreyButton
            className="w-[40%] lg:w-[25%] h-[45px] rounded-[5px] text-[16px] font-[500] font-montserrat"
            label="Previous"
            onClick={handlePrevious}
          />
          <PrimaryButton
            className={
              "w-[40%] lg:w-[25%] h-[45px] rounded-[5px] text-[16px] font-[500] font-montserrat ms-2"
            }
            label="Next"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default OfferCreation;
