import React, { useEffect, useState } from "react";
import Input from "../../FormInputs";
import { useFormik } from "formik";
import {
  franchiseOfferSchema,
  uploadOfferSchema,
} from "../../../../utils/validationSchemas";
import PrimaryButton from "../../PrimaryButton";
import TimeTable from "../../../User/MerchantOnboard/Common/TimeTable";
import GoogleMaps from "../../../common/GoogleMaps";
import UploadExcel from "./UploadExcel";
import {
  addFranchise,
  getLocationById,
} from "../../../../services/franchiseService";
import { useDispatch } from "react-redux";
import PhoneFormInput from "../../PhoneInput";

const AddNewLocation = ({ uploadClicked, setLocations, setAddClicked, setSelectedLocations, setSelectedLocationIds, setLocationNames }) => {
  const dispatch = useDispatch();
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const format = "hh:mm A";

  const [fileData, setFileData] = useState();
  const [placeId, setPlaceId] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const [newLocationId, setNewLocationId] = useState(null);

  const initialValues = {
    document: "",
    franchiseName: "",
    email: "",
    contact: "",
    location: "",
    timeSet: false,
    timeData: days?.map((d) => ({
      day: d,
      openTime: undefined,
      closeTime: undefined,
      isSelected: false,
    })),
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema:
      uploadClicked === true ? uploadOfferSchema : franchiseOfferSchema,

    onSubmit: async (values) => {
      console.log("create values", values, timeData);
      const addPromise = await dispatch(
        addFranchise({
          name: values?.franchiseName,
          email: values?.email,
          phone: values?.contact,
          googlePlaceId: placeId !== null ? placeId : null,
          tradingHours: timeData
            .filter((day) => day?.isSelected === true)
            .map((day) => ({
              day: day?.day?.toLowerCase(),
              openTime: day?.openTime ? day?.openTime.format(format) : null,
              closeTime: day?.closeTime ? day?.closeTime.format(format) : null,
            })),
        })
      ).unwrap();
      if (addPromise?.success === true) {
        setNewLocationId(addPromise?.data?._id);
        setSelectedLocations((prev) => [...prev, addPromise?.data]);
        setSelectedLocationIds((prev) => [...prev, addPromise?.data?._id]);
        setLocationNames((prev) => [...prev, addPromise?.data?.name]);
      }
    },
  });

  const getLocationData = async () => {
    const getPromise = await dispatch(
      getLocationById({ id: newLocationId })
    )?.unwrap();
    setLocations((prev) => [...prev, getPromise?.data]);
    setAddClicked(false);
  };

  useEffect(() => {
    if (newLocationId !== null) {
      getLocationData();
    }
  }, [newLocationId]);

  const {
    document,
    franchiseName,
    email,
    contact,
    location,
    timeSet,
    timeData,
  } = formik.values;

  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    setValues,
    setErrors,
  } = formik;

  const handleTimeChange = (dayIndex, field, time) => {
    const updatedTimeData = [...timeData];
    updatedTimeData[dayIndex][field] = time;
    setFieldValue("timeData", updatedTimeData);
  };

  useEffect(() => {
    const allTimeSet = timeData?.every((day) => day.isSelected === false);
    setFieldValue("timeSet", allTimeSet);
  }, [timeData]);

  const handleLocationSelect = (selectedLocation) => {
    setFieldValue("location", selectedLocation?.placeName);
    setPlaceId(selectedLocation?.placeId);
  };

  const handleLocationClick = () => {
    setShowMap(true);
  };

  const validateFile = (data) => {
    var idxDot = data.lastIndexOf(".") + 1;
    var extFile = data.substr(idxDot, data.length).toLowerCase();
    if (extFile == "xlsx" || extFile == "xls") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      {uploadClicked ? (
        <>
          <p className="font-poppins font-[400] sm:text-[12px] md:text-[14px] text-[#767676] mt-2">
            Upload an excel file
          </p>
          <UploadExcel
            setFileData={setFileData}
            document={document}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
            accept={".xlsx, .xls"}
            fileText={"Uplaod an excel file"}
            validateFile={validateFile}
          />
        </>
      ) : (
        <>
          <Input
            type="text"
            label=""
            placeholder="Add Franchise Name"
            width="100%"
            radius="5px"
            InputDivClass="w-[100%] mt-8"
            name="franchiseName"
            value={franchiseName}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors.franchiseName}
            touched={touched.franchiseName}
          />
          <Input
            type="text"
            label=""
            placeholder="Email"
            width="100%"
            radius="5px"
            InputDivClass="w-[100%] mt-8"
            name="email"
            value={email}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors.email}
            touched={touched.email}
          />
          <PhoneFormInput
            label={""}
            divClassName="sm:mt-12 md:mt-0 w-full"
            name="contact"
            value={contact}
            handleBlur={handleBlur}
            error={errors.contact}
            touched={touched.contact}
            setFieldValue={setFieldValue}
            inputStyle={{
              width: "100%",
              border: "none",
            }}
            buttonStyle={{ border: "none" }}
            buttonClass="!rounded-l-[5px] !bg-[#D9D9D9] md:!w-[13%] sm:!w-[15%] lg:!w-[10%] xl:!w-[12%] 2xl:!w-[10%]"
            containerClass="!rounded-[5px] border border-[#D9D9D9]"
            inputClass="lg:!h-[45px] md:!h-[45px] !rounded-[5px] !bg-[#FFFFFF] !text-[#303030] lg:!text-[16px] sm:!text-[14px] !font-[400] !outline-none !font-poppins md:!pl-[17%] sm:!pl-[17%] lg:!pl-[12%] xl:!pl-[15%] 2xl:!pl-[12%]"
            labelClass="mb-8"
            errorClass={
              "text-red-600 sm:text-[10px] md:text-[12px] font-[600] ms-1"
            }
          />
          <Input
            type="text"
            label=""
            placeholder="Location"
            width="100%"
            radius="5px"
            InputDivClass="w-[100%] mt-8"
            name="location"
            value={location}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors.location}
            touched={touched.location}
            onClick={handleLocationClick}
          />
          {showMap && (
            <GoogleMaps
              onLocationSelect={handleLocationSelect}
              searchContainerClass="w-[56%] sm:w-[51%] md:w-[50%] lg:w-[56%] 2xl:w-[63%] absolute top-[10px] left-[33%] sm:left-[37%] md:left-[38%] lg:left-[33%] 2xl:left-[28%]"
            />
          )}

          <TimeTable
            label={"What are your working hours?"}
            labelClass="lg:text-[16px] md:text-[14px] sm:text-[12px] font-[400] text-[#303030] mb-3 font-poppins"
            openClass="lg:text-[16px] md:text-[14px] sm:text-[12px] font-[400] text-[#8C8C8C] sm:ml-[2rem] md:ml-[3rem] lg:ml-[4rem] xl:ml-[6rem] font-poppins"
            closeClass="lg:text-[16px] md:text-[14px] sm:text-[12px] font-[400] text-[#8C8C8C] sm:ml-[0rem] md:ml-[0rem] lg:ml-[0rem] xl:mr-[0rem] font-poppins"
            dayClass="lg:text-[16px] md:text-[14px] sm:text-[12px] font-[400] text-[#8C8C8C] mb-2 sm:mr-[25px] md:mr-[50px] lg:mr-[70px] xl:mr-[100px] sm:w-[18%] md:w-[12%] lg:w-[10%] xl:w-[10%] font-poppins"
            timeDivClass="flex sm:w-[80%] md:w-[70%] lg:w-[70%] xl:w-[70%] items-baseline"
            toClass="text-center sm:mx-[0.25rem] md:mx-[0.5rem] lg:mx-[1rem] xl:mx-[0.5rem] lg:text-[16px] md:text-[14px] sm:text-[12px] sm:w-[15%] md:w-[13%] lg:w-[5%] xl:w-[10%] font-poppins"
            timePickerClass="sm:w-[95%] md:w-full lg:h-[40px] sm:h-[30px] rounded-[5px] border border-[#E4E3E4] cursor-pointer"
            format={format}
            timeData={timeData}
            handleTimeChange={handleTimeChange}
            error={errors?.timeData}
            touched={touched?.timeData}
            setFieldValue={setFieldValue}
          />
          {errors?.timeSet && touched?.timeSet ? (
            <span className="text-red-600 sm:text-[10px] md:text-[12px] font-[600] ms-1">
              {errors?.timeSet}
            </span>
          ) : null}
        </>
      )}

      <div className="flex justify-end mt-8">
        <PrimaryButton
          className={
            "w-[40%] lg:w-[25%] h-[45px] rounded-[5px] text-[16px] font-[500] font-montserrat ms-2"
          }
          label="Submit"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default AddNewLocation;
