import React from "react";

const OfferStep = ({ stepNumber, content, stepIndex }) => {
  return (
    <div className="w-full relative flex flex-col items-center offerStep h-[80px]">
      <div
        className={`${stepIndex == stepNumber ? "bg-[#00ADEF] border-0 text-white": "bg-white text-[#C4C4C4] border border-[#C4C4C4]"} text-[16px] lg:text-[20px] h-[30px] w-[30px] lg:h-[40px] lg:w-[40px] flex justify-center items-center rounded-[100%] z-10`}
      >
        {stepNumber}
      </div>
      <p className="sm:text-[8px] md:text-[10px] text-[#303030] font-[400] mt-2 text-center">
        {content}
      </p>
    </div>
  );
};

export default OfferStep;
