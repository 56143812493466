import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import FundraiserHeader from "../common/FundraiserHeader";
import FundraiserBodyHeader from "../common/FundraiserBodyHeader";
import RelatedSubscriptionsHeader from "../common/RelatedSubscriptionsHeader";
import SubscriptionPlan from "../common/SubscriptionPlan";
import ExistingSubscriptionPlan from "../common/ExistingSubscriptionPlan";
import {
  cancelSubscription,
  getUserSubscription,
} from "../../../services/subscriptionService";
import CancelSubscriptionPopup from "../../common/CancelPopup.jsx";
import toast from "../../../utils/toast.js";
import { setSelectedPlan } from "../../../redux/slices/subscriptionSlice.js";
import cancelImage from "../../../Assets/Images/cancelImage.svg";

const ExistingSubscription = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const allPlans = useSelector(
    (state) => state?.subscriptionReducer?.subscriptionPlans
  );
  const activeSubscription = useSelector(
    (state) => state?.subscriptionReducer?.subscription
  );
  const subscriptionId = useSelector(
    (state) => state?.subscriptionReducer?.subscription?.subscriptionId
  );
  const [cancelClicked, setCancelClicked] = useState(false);
  const [premiumPlan, setPremiumPlan] = useState({});
  const [basicPlan, setBasicPlan] = useState({});

  useEffect(() => {
    if (allPlans !== null) {
      setPremiumPlan(allPlans[0]);
      setBasicPlan(allPlans[1]);
    }
  }, []);

  const cancelClick = () => {
    setCancelClicked(true);
  };

  const handleKeepPlan = () => {
    setCancelClicked(false);
  };

  const handleCancelSub = async () => {
    setCancelClicked(false);
    const cancelPromise = await dispatch(
      cancelSubscription({ subscriptionId: subscriptionId })
    ).unwrap();
    if (cancelPromise?.success === true) {
      dispatch(getUserSubscription());
    }
  };

  const joinClick = (plan) => {
    if (activeSubscription) {
      toast.warn("Cancel Active Subscription First!");
    } else {
      navigate("/fundraiser-subscription");
      dispatch(setSelectedPlan(plan));
    }
  };

  return (
    <div className="w-full h-full">
      <FundraiserHeader />
      {cancelClicked == true ? (
        <CancelSubscriptionPopup
          handleNo={handleKeepPlan}
          handleYes={handleCancelSub}
          heading={"Cancel Subscription"}
          content={"Are you sure you want to cancel subscription?"}
          image={cancelImage}
        />
      ) : null}
      {activeSubscription ? (
        <>
          <div className="flex flex-col items-center pt-10 sm:py-10">
            <FundraiserBodyHeader
              heading={"Your Active Subscription"}
              content={""}
              contentClass={
                "w-[100%] sm:w-[80%] md:w-[90%] text-[#8C8C8C] mb-4"
              }
            />
            <ExistingSubscriptionPlan
              title="CURRENT PLAN"
              plans={activeSubscription?.description?.split(",")}
              price={activeSubscription?.amount}
              duration={new Date(
                activeSubscription?.subscriptionExpiryTime
              ).toLocaleDateString("en-US")}
              cancelClick={cancelClick}
            />
          </div>
          <div className="flex justify-center items-center sm:hidden pb-10">
            <SubscriptionPlan
              title="CURRENT PLAN"
              plans={activeSubscription?.description?.split(",")}
              price={activeSubscription?.amount}
              duration={new Date(
                activeSubscription?.subscriptionExpiryTime
              ).toLocaleDateString("en-US")}
              cancelOption={true}
              cancelClick={cancelClick}
            />
          </div>
        </>
      ) : null}
      <div className="bg-[#F4F4F4] py-10 flex flex-col items-center gap-12">
        <RelatedSubscriptionsHeader
          heading={"Related Subscription Plans"}
          content={
            "Select a Swoodle Membership and contribute 20% of the proceeds to foundation!"
          }
          contentClass={"w-[100%] sm:w-[80%] md:w-[90%] text-[#8C8C8C] mt-4"}
        />
        <div className="flex flex-col sm:flex-row w-full items-center justify-center gap-5">
          <SubscriptionPlan
            title={basicPlan?.name}
            plans={basicPlan?.description?.split(",")}
            price={basicPlan?.amount}
            duration={basicPlan?.duration}
            joinClick={() => joinClick(basicPlan)}
          />
          <SubscriptionPlan
            title={premiumPlan?.name}
            plans={premiumPlan?.description?.split(",")}
            price={premiumPlan?.amount}
            duration={premiumPlan?.duration}
            joinClick={() => joinClick(premiumPlan)}
          />
        </div>
        <p className="text-[14px] sm:text-[16px] md:text-[18px] font-poppins font-[400] mt-4 text-center w-[90%]">
          By selecting a plan, you agree to our
          <span className="text-[#00ADEF] cursor-pointer" onClick={() => window.open("/privacy/toc", "_blank")}> Terms of Services </span>
          and
          <span className="text-[#00ADEF] cursor-pointer" onClick={() => window.open("/privacy/policy", "_blank")}> Privacy Policy</span>
        </p>
      </div>
    </div>
  );
};

export default ExistingSubscription;
