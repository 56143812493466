import React, { useEffect, useState } from "react";
import "./marketing.scss";
import BreadCrumsHeader from "../../common/BreadCrums";
import { ReactComponent as CameraIcon } from "../../../Assets/Icons/cameraIcon.svg";

import { useFormik } from "formik";
import { fundraiserMarketingSchema } from "../../../utils/validationSchemas";
import FormLayout from "../../common/FormLayout";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import UploadDocument from "../../common/OfferForm/UploadDocument";
import toast from "../../../utils/toast";
import GreyButton from "../../common/GreyButton";
import PrimaryButton from "../../common/PrimaryButton";
import { uploadFile } from "../../../utils/s3";
import { useDispatch, useSelector } from "react-redux";
import {
  addContent,
  getFundraiserContent,
} from "../../../services/fundraiserService";
import { useNavigate } from "react-router-dom";
import Loader from "../../common/Loader";

const Marketing = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.fundraiserReducer?.loading);
  const userId = useSelector(
    (state) => state?.authReducer?.tokenData?.user?._id
  );
  const navigate = useNavigate();
  const [editorHtml, setEditorHtml] = useState("");
  const [logoFileImage, setLogoFileImage] = useState();
  const [logoFileData, setLogoFileData] = useState();
  const [bannerFileImage, setBannerFileImage] = useState();

  const [logoUrl, setLogoUrl] = useState();
  const [bannerUrl, setBannerUrl] = useState();
  const [copySuccess, setCopySuccess] = useState("");
  const [content, setContent] = useState();

  const Font = Quill.import("formats/font");
  Font.whitelist = ["16px", "18px", "20px", "22px", "24px", "26px"];
  Quill.register(Font, true);

  const modules = {
    toolbar: {
      container: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
      ],
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "align",
    "list",
    "bullet",
    "link",
    "image",
    "video",
  ];

  const getContent = async () => {
    const getPromise = await dispatch(
      getFundraiserContent({ userId: userId })
    )?.unwrap();
    if (getPromise?.success === true) {
      setContent(getPromise?.data);
    }
  };

  useEffect(() => {
    getContent();
  }, []);

  const initialValues = {
    logo: "",
    aboutUs: "",
    banner: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: fundraiserMarketingSchema,

    onSubmit: async (values) => {
      console.log("Market values: ", values, bannerUrl, logoUrl);
      const payload = {
        aboutUs: values?.aboutUs,
        banner: bannerUrl,
        logo: logoUrl,
      };
      const addPromise = await dispatch(addContent(payload))?.unwrap();
      if (addPromise?.success === true) {
        navigate(`/fundraiser-brand/${userId}`);
      }
    },
  });

  const { logo, aboutUs, banner } = formik.values;

  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
  } = formik;

  useEffect(() => {
    setFieldValue("logo", content?.logo);
    setLogoFileImage(content?.logo);
    setFieldValue("banner", content?.banner);
    setBannerFileImage(content?.banner);
    setFieldValue("aboutUs", content?.aboutUs);
  }, [content]);

  const items = [
    {
      title: "Marketing",
    },
    {
      title: "Marketing Page",
    },
  ];
  const handleEditorChange = (html) => {
    setFieldValue("aboutUs", html);
    setEditorHtml(html);
  };

  const validateImage = (data) => {
    var idxDot = data.lastIndexOf(".") + 1;
    var extFile = data.substr(idxDot, data.length).toLowerCase();
    if (
      extFile == "jpg" ||
      extFile == "jpeg" ||
      extFile == "png" ||
      extFile == "svg"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleUploadLogo = async (event) => {
    let logoBase64 = "";
    let fileName = event.target.files[0].name;
    setFieldValue("logo", fileName);
    const s3Url = await uploadFile(event.target.files[0], fileName);
    setLogoUrl(s3Url);
    let checkValidImage = validateImage(fileName);

    if (checkValidImage) {
      let logoImageUrl = URL.createObjectURL(event.target.files[0]);
      setLogoFileImage(logoImageUrl);
      localStorage.setItem("profilePic", logoImageUrl);
      setLogoFileData(event.target.files[0]);
    } else {
      toast.error("Only jpg/jpeg, png, and svg files are allowed!", "warning");
    }
  };

  const handleClearLogo = () => {
    setFieldValue("logo", "");
    setLogoFileImage(undefined);
    setLogoFileData(undefined);
  };

  const handlePublicLinkClick = (url) => {
    window.open(url, "_blank");
  };

  const handleCopy = () => {
    const textToCopy = window.location.origin + "/fundraiser-brand/" + userId;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopySuccess("Copied!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  useEffect(() => {
    if (copySuccess !== "") {
      const timer = setTimeout(() => {
        setCopySuccess("");
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [copySuccess]);

  return (
    <>
      {loading === true && <Loader />}
      <BreadCrumsHeader heading="Marketing Page" items={items} form={true} />
      <FormLayout
        title={"About"}
        content={
          <>
            <div className="flex mt-7 items-center gap-8">
              <div className="h-[80px] w-[80px] rounded-[10px] border-2 border-solid border-[#E4E3E4] flex justify-center items-center">
                {logoFileImage !== undefined ? (
                  <img
                    src={logoFileImage}
                    className="w-full h-full rounded-[10px] border border-solid border-[#E4E3E4]"
                  />
                ) : (
                  <CameraIcon />
                )}
              </div>
              <input
                type="file"
                id="fileInput"
                hidden
                accept=".jpg,.jpeg,.png,.svg"
                onChange={handleUploadLogo}
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
              {logoFileImage !== undefined ? (
                <p
                  className="font-poppins font-[400] text-[12px] md:text-[14px] text-[#535353] cursor-pointer"
                  onClick={handleClearLogo}
                >
                  Clear
                </p>
              ) : (
                <label
                  htmlFor="fileInput"
                  className="font-poppins font-[400] text-[12px] md:text-[14px] text-[#535353] cursor-pointer"
                >
                  Upload logo
                </label>
              )}
            </div>
            {errors.logo && touched.logo ? (
              <div className="ms-1 mt-1">
                <span className="text-red-600 sm:text-[10px] md:text-[12px] font-[600] text-start">
                  {errors.logo}
                </span>
              </div>
            ) : null}
            <div className="w-full lg:w-[80%] xl:w-[70%] 2xl:w-[50%] mt-7 h-[40px] border border-[#D9D9D9] rounded-[5px] flex justify-between items-center px-2 relative">
              <p
                className="text-[#8C8C8C] font-poppins sm:text-[10px] md:text-[12px] cursor-pointer"
                onClick={() =>
                  handlePublicLinkClick(
                    `${window.location.origin}/fundraiser-brand/${userId}`
                  )
                }
              >
                {window.location.origin}/fundraiser-brand/{userId}
              </p>
              <PrimaryButton
                className={
                  "sm:w-[45%] md:w-[15%] font-montserrat text-[14px] text-center font-[500] h-[30px] rounded-[5px]"
                }
                label={copySuccess || "Copy"}
                onClick={handleCopy}
              />
            </div>
            <div className="w-full mt-7">
              <p className="font-poppins font-[500] text-[16px] md:text-[18px] text-[#303030]">
                Add Description
              </p>
              <ReactQuill
                theme="snow"
                value={aboutUs ? aboutUs : editorHtml}
                onChange={handleEditorChange}
                modules={modules}
                formats={formats}
                className="w-full h-[200px] md:h-[300px] mt-5"
              />
            </div>
            {errors.aboutUs && touched.aboutUs ? (
              <div className="ms-1 mt-[4.25rem]">
                <span className="text-red-600 sm:text-[10px] md:text-[12px] font-[600] text-start">
                  {errors.aboutUs}
                </span>
              </div>
            ) : null}
            <div className="w-full sm:mt-32 md:mt-24">
              <p className="font-poppins font-[500] text-[16px] md:text-[18px] text-[#303030]">
                Add a Banner
              </p>
              <p className="font-poppins font-[400] text-[12px] md:text-[14px] text-[#767676]">
                Select or upload a picture
              </p>
              <UploadDocument
                thumbnail={banner}
                name={"banner"}
                iconText={"Upload Banner"}
                error={errors?.banner}
                touched={touched?.banner}
                setFieldValue={setFieldValue}
                parentDivClass={"h-[370px] sm:w-full md:w-[70%] mt-7"}
                setS3Url={setBannerUrl}
                logoFileImage={bannerFileImage}
                setLogoFileImage={setBannerFileImage}
              />
            </div>
          </>
        }
      />
      <div className="w-full text-end mt-10">
        <GreyButton
          className="w-[30%] lg:w-[15%] h-[45px] rounded-[5px] text-[16px] font-[500] font-montserrat"
          label="Cancel"
          // onClick={handlePrevious}
        />
        <PrimaryButton
          className={
            "w-[30%] lg:w-[15%] h-[45px] rounded-[5px] text-[16px] font-[500] font-montserrat ms-2"
          }
          label="Save"
          onClick={handleSubmit}
        />
      </div>
    </>
  );
};

export default Marketing;
