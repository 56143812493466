import React from "react";
import "./fundraiserbrand.scss";
import PrimaryButton from "../../common/PrimaryButton";
import SwoodleNavLogo from "../../../Assets/Images/swoodleLogo.svg";
import BrandBannerTwo from "../../../Assets/Images/bouncing_img.svg";

const FundraiserSocial = ({ brandLogo, scrollTo, banner, fundraiserName }) => {
  return (
    <div className="flex flex-col gap-5 md:flex-row md:gap-0 justify-between md:h-[230px]">
      <div className="bannerDiv w-full md:w-[63%] h-full px-[3%] lg:px-8 py-8 lg:py-8">
        <div className="">
          <img src={brandLogo} className="w-auto h-[35px] sm:h-[45px]"></img>
          <p className="text-[10px] md:text-[12px] font-[400] mt-6 mb-7">
            Fundraising with {fundraiserName} Free Unleashed
          </p>
        </div>

        <div className="flex justify-between items-end w-full">
          <PrimaryButton
            label={"Join Now"}
            className={
              "w-[35%] sm:w-[20%] md:w-[25%] lg:w-[20%] xl:w-[18%] 2xl:w-[15%] text-[12px] sm:text-[14px] font-[600] h-[40px] sm:h-[40px] md:h-[45px] rounded-[10px]"
            }
            onClick={scrollTo}
          />
          <img
            src={SwoodleNavLogo}
            className="w-[130px] sm:w-[200px] md:w-[160px] lg:w-[200px]"
          ></img>
        </div>
      </div>

      <div className="w-full h-full md:w-[34%]">
        <img
          src={banner}
          className="w-full h-full object-contain rounded-[10px]"
        ></img>
      </div>
    </div>
  );
};

export default FundraiserSocial;
