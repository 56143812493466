// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainFormDiv {
  background-color: white;
  border-radius: 10px;
  margin-top: 37px;
  padding-bottom: 50px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1019607843);
}

.ant-picker:hover {
  border: 1px solid #D9D9D9 !important;
}

.ant-picker-focused {
  border: 1px solid #D9D9D9 !important;
}

.ant-btn {
  background-color: #00ADEF !important;
  color: white !important;
  padding-bottom: 24px !important;
  padding-right: 8px !important;
}

.anticon-clock-circle > svg {
  color: #00ADEF;
  height: 20px;
  width: 20px;
}

.anticon-calendar > svg {
  color: #00ADEF;
  width: 22.5px;
  height: 20px;
}

.anticon-close-circle > svg {
  color: #00ADEF;
  width: 20px;
  height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Fundraiser/BankForm/bankform.scss"],"names":[],"mappings":"AACA;EACI,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,oBAAA;EACA,uDAAA;AAAJ;;AAGA;EACI,oCAAA;AAAJ;;AAGA;EACI,oCAAA;AAAJ;;AAGA;EACI,oCAAA;EACA,uBAAA;EACA,+BAAA;EACA,6BAAA;AAAJ;;AAGA;EACI,cAAA;EACA,YAAA;EACA,WAAA;AAAJ;;AAGA;EACI,cAAA;EACA,aAAA;EACA,YAAA;AAAJ;;AAGA;EACI,cAAA;EACA,WAAA;EACA,YAAA;AAAJ","sourcesContent":["\n.mainFormDiv{\n    background-color: white;\n    border-radius: 10px;\n    margin-top: 37px;\n    padding-bottom: 50px;\n    box-shadow: 0px 4px 8px 0px #0000001A;\n}\n\n.ant-picker:hover{\n    border: 1px solid #D9D9D9 !important;\n}\n\n.ant-picker-focused{\n    border: 1px solid #D9D9D9 !important;\n}\n\n.ant-btn{\n    background-color: #00ADEF !important;\n    color: white !important;\n    padding-bottom: 24px !important;\n    padding-right: 8px !important;\n}\n\n.anticon-clock-circle > svg{\n    color: #00ADEF;\n    height: 20px;\n    width: 20px;\n}\n\n.anticon-calendar > svg{\n    color: #00ADEF;\n    width: 22.5px;\n    height: 20px;\n}\n\n.anticon-close-circle > svg{\n    color: #00ADEF;\n    width: 20px;\n    height: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
