// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headingsDiv {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
@media (min-width: 640px) and (max-width: 690px) {
  .headingsDiv {
    gap: 3rem;
    top: 1.25rem;
  }
}
@media (min-width: 690px) and (max-width: 768px) {
  .headingsDiv {
    gap: 3rem;
    top: 1.75rem;
  }
}
@media (min-width: 768px) and (max-width: 830px) {
  .headingsDiv {
    gap: 2rem;
    top: 0.75rem;
  }
}
@media (min-width: 830px) and (max-width: 920px) {
  .headingsDiv {
    gap: 3rem;
    top: 1.25rem;
  }
}
@media (min-width: 920px) and (max-width: 1024px) {
  .headingsDiv {
    gap: 4rem;
    top: 1.5rem;
  }
}
@media (min-width: 1024px) and (max-width: 1060px) {
  .headingsDiv {
    gap: 4rem;
    top: 1.25rem;
  }
}
@media (min-width: 1060px) and (max-width: 1200px) {
  .headingsDiv {
    gap: 4rem;
    top: 1.75rem;
  }
}
@media (min-width: 1200px) and (max-width: 1280px) {
  .headingsDiv {
    gap: 4rem;
    top: 1.75rem;
  }
}
@media (min-width: 1280px) and (max-width: 1536px) {
  .headingsDiv {
    gap: 4rem;
    top: 1.75rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/OfferForm/OfferReview/offerReview.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,mBAAA;EACA,mBAAA;EACA,sBAAA;AACJ;AAAI;EALJ;IAMQ,SAAA;IACA,YAAA;EAGN;AACF;AAFI;EATJ;IAUQ,SAAA;IACA,YAAA;EAKN;AACF;AAJI;EAbJ;IAcQ,SAAA;IACA,YAAA;EAON;AACF;AANI;EAjBJ;IAkBQ,SAAA;IACA,YAAA;EASN;AACF;AARI;EArBJ;IAsBQ,SAAA;IACA,WAAA;EAWN;AACF;AAVI;EAzBJ;IA0BQ,SAAA;IACA,YAAA;EAaN;AACF;AAZI;EA7BJ;IA8BQ,SAAA;IACA,YAAA;EAeN;AACF;AAdI;EAjCJ;IAkCQ,SAAA;IACA,YAAA;EAiBN;AACF;AAhBI;EArCJ;IAsCQ,SAAA;IACA,YAAA;EAmBN;AACF","sourcesContent":[".headingsDiv {\n    padding-left: 1rem;\n    padding-right: 1rem;\n    padding-top: 0.5rem;\n    padding-bottom: 0.5rem;\n    @media (min-width: 640px) and (max-width: 690px) {\n        gap: 3rem;\n        top: 1.25rem;\n    }\n    @media (min-width: 690px) and (max-width: 768px) {\n        gap: 3rem;\n        top: 1.75rem;\n    }\n    @media (min-width: 768px) and (max-width: 830px) {\n        gap: 2rem;\n        top: 0.75rem;\n    }\n    @media (min-width: 830px) and (max-width: 920px) {\n        gap: 3rem;\n        top: 1.25rem;\n    }\n    @media (min-width: 920px) and (max-width: 1024px) {\n        gap: 4rem;\n        top: 1.5rem;\n    }\n    @media (min-width: 1024px) and (max-width: 1060px) {\n        gap: 4rem;\n        top: 1.25rem;\n    }\n    @media (min-width: 1060px) and (max-width: 1200px) {\n        gap: 4rem;\n        top: 1.75rem;\n    }\n    @media (min-width: 1200px) and (max-width: 1280px) {\n        gap: 4rem;\n        top: 1.75rem;\n    }\n    @media (min-width: 1280px) and (max-width: 1536px) {\n        gap: 4rem;\n        top: 1.75rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
