import React from "react";

const DayBox = ({ days, handleDayClick, isDayClicked }) => {
  return (
    <div className="flex items-center gap-2 mt-2">
      {days?.map((d) => (
        <div
          className={`sm:w-[40px] md:w-[50px] sm:h-[40px] md:h-[50px] lg:w-[60px] h-[60px] border-[1px] rounded-[10px] bg-[#F0F0F0] ${
            isDayClicked(d) ? "border-[#00ADEF]" : "border-[#D9D9D9]"
          } flex justify-center items-center font-poppins lg:text-[14px] sm:text-[12px] font-[400] text-[#303030] cursor-pointer`}
          onClick={() => {
            handleDayClick(d);
          }}
        >
          {d.slice(0, 3)}
        </div>
      ))}
    </div>
  );
};

export default DayBox;
