import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import PrimaryButton from "../../PrimaryButton";
import GreyButton from "../../GreyButton";
import { locationOfferSchema } from "../../../../utils/validationSchemas";
import { ReactComponent as AddIcon } from "../../../../Assets/Icons/addNewIcon.svg";
import { ReactComponent as UploadIcon } from "../../../../Assets/Icons/uploadExcelIcon.svg";
import SelectOption from "../SelectOption";
import AddNewLocation from "./AddNewLocation";
import { useDispatch, useSelector } from "react-redux";
import { getFranchises } from "../../../../services/franchiseService";
import Loader from "../../../common/Loader";
import { createOffer } from "../../../../services/offerService";

const Location = ({
  stepIndex,
  setStepIndex,
  handlePrevious,
  setPayloadData,
  editOfferData,
  isEdit,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.franchiseReducer?.loading);
  const offerData = useSelector((state) => state?.offerReducer?.createdOffer);
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedLocationIds, setSelectedLocationIds] = useState([]);
  const [locationNames, setLocationNames] = useState([]);
  const [addClicked, setAddClicked] = useState(false);
  const [uploadClicked, setUploadClicked] = useState(false);

  const fillUpForm = (data) => {
    if (data?.tempLocations) {
      const locObjects = [];
      const locIds = [];
      const locNames = [];

      data.tempLocations?.map((location) => {
        locObjects?.push(location);
        locIds?.push(location?._id);
        locNames?.push(location?.name);
      });

      setSelectedLocations(locObjects);
      setSelectedLocationIds(locIds);
      setLocationNames(locNames);
    }
  };

  useEffect(() => {
    if (editOfferData !== null && isEdit == true) {
      fillUpForm(editOfferData);
    } else {
      fillUpForm(offerData);
    }
  }, [offerData, editOfferData]);

  const getAllFranchises = async () => {
    const franchisePromise = await dispatch(getFranchises()).unwrap();
    if (franchisePromise?.success === true) {
      setLocations(franchisePromise?.data);
    }
  };

  useEffect(() => {
    getAllFranchises();
  }, []);

  const initialValues = {
    location: [],
  };

  const payload = {
    locations: selectedLocationIds,
    id: offerData?._id,
    isCreated: false,
  };

  useEffect(() => {
    setPayloadData(payload);
  }, [selectedLocationIds, offerData?._id]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: locationOfferSchema,

    onSubmit: async (values) => {
      console.log("create values", values);
      const createPromise = await dispatch(createOffer(payload))?.unwrap();
      if (createPromise?.success === true) {
        setStepIndex(stepIndex + 1);
      }
    },
  });

  const { location } = formik.values;

  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    setValues,
  } = formik;

  const handleClick = (id, name) => {
    const isSelected = selectedLocations?.find((loc) => loc?._id === id);

    if (isSelected) {
      const updatedLocations = selectedLocations?.filter(
        (loc) => loc?._id !== id
      );
      setSelectedLocations(updatedLocations);

      const updatedIds = selectedLocationIds?.filter((locId) => locId !== id);
      setSelectedLocationIds(updatedIds);

      const updatedNames = locationNames?.filter((t) => t !== name);
      setLocationNames(updatedNames);
    } else {
      const loc = locations?.find((l) => l?._id === id);

      setSelectedLocations((prev) => [...prev, loc]);
      setSelectedLocationIds((prev) => [...prev, loc?._id]);
      setLocationNames((prev) => [...prev, loc?.name]);
    }
  };

  const isLocationClicked = (id) => {
    return selectedLocations?.find((loc) => loc?._id === id);
  };

  const handleAddClick = () => {
    setAddClicked(true);
    setUploadClicked(false);
  };

  const handleUploadClick = () => {
    if (addClicked == true) {
      setUploadClicked(true);
    }
  };

  useEffect(() => {
    setFieldValue("location", locationNames);
  }, [locationNames]);

  return (
    <div className="w-full flex flex-col items-center justify-center mt-20">
      {loading && <Loader />}
      <div className="sm:w-[100%] md:w-[80%] lg:w-[70%] xl:w-[55%] offerform">
        <p className="font-poppins font-[500] sm:text-[12px] md:text-[14px] lg:text-[16px] text-[#303030]">
          Locations
        </p>
        <p className="font-poppins font-[400] sm:text-[10px] md:text-[12px] text-[#767676] mb-5">
          Customers will visit your business location(s) to redeem your campaign
          offer
        </p>
        {locations?.length > 0 &&
          locations?.map((loc) => (
            <SelectOption
              icon={null}
              title={loc?.googlePlaceId?.address}
              clicked={isLocationClicked(loc?._id)}
              handleClick={() => handleClick(loc?._id, loc?.name)}
              postIcon = {null}
            />
          ))}
        {errors?.location && touched?.location ? (
          <span className="text-red-600 sm:text-[10px] md:text-[12px] font-[600] ms-1">
            {errors?.location}
          </span>
        ) : null}
        <div className="mt-5 w-full flex justify-between items-center">
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={handleAddClick}
          >
            <AddIcon />
            <p className="font-poppins font-[400] sm:text-[12px] md:text-[14px] text-[#00ADEF] text-center leading-[18px]">
              Add Location
            </p>
          </div>
          <UploadIcon className="cursor-pointer" onClick={handleUploadClick} />
        </div>
        {addClicked == true && (
          <AddNewLocation
            uploadClicked={uploadClicked}
            setLocations={setLocations}
            setAddClicked={setAddClicked}
            setSelectedLocations={setSelectedLocations}
            setSelectedLocationIds={setSelectedLocationIds}
            setLocationNames={setLocationNames}
          />
        )}
        <div className="w-full text-end mt-20">
          <GreyButton
            className="w-[40%] lg:w-[25%] h-[45px] rounded-[5px] text-[16px] font-[500] font-montserrat"
            label="Previous"
            onClick={handlePrevious}
          />
          <PrimaryButton
            className={
              "w-[40%] lg:w-[25%] h-[45px] rounded-[5px] text-[16px] font-[500] font-montserrat ms-2"
            }
            label="Next"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default Location;
