import React from "react";
import "./common.scss";
import { ReactComponent as CloseIcon } from "../../Assets/Icons/popupCloseIcon.svg";

const GenericPopup = ({
  icon,
  handleClose,
  content,
  popupClassName,
  continueBtn,
  cancelBtn,
}) => {
  return (
    <div className="popup-container">
      <div
        className={`popup flex flex-col items-center bg-white rounded-[10px] drop-shadow-[0_3px_3px_rgba(0,0,0,0.25)] relative ${popupClassName}`}
      >
        {handleClose && <div className="h-[3%]">
          <CloseIcon
            className="absolute top-3 right-3 cursor-pointer w-[12px] h-[12px]"
            onClick={handleClose}
          />
        </div>}
        <img
          src={icon}
          className="w-[40px] h-[40px] my-4"
        />
        <p className="text-[14px] sm:text-[16px] font-[400] font-montserrat text-[#303030] text-center px-4 break-words w-[95%]">
          {content}
        </p>
        {!cancelBtn ? (
          continueBtn
        ) : (
          <div className="w-full flex justify-center items-center gap-4">
            {cancelBtn}
            {continueBtn}
          </div>
        )}
      </div>
    </div>
  );
};

export default GenericPopup;
