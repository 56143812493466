import React from "react";
import Loader from "../Loader";

const AddPhoto = ({divClassName, icon, text, index, loading}) => {
  return (
    <div className={`relative rounded-[10px] border border-[#D9D9D9] flex flex-col items-center justify-center gap-3 img ${divClassName}`}>
      {loading === true && <Loader/>}
      {index && <div className="bg-[#BDBDBD] h-[20px] w-[20px] rounded-[50%] flex items-center justify-center">
        <p className="text-white font-poppins font-[400] text-[10px] text-center">{index}</p>
      </div>}
      <img src={icon} />
      <p className="sm:text-[14px] md:text-[14px] text-[#8C8C8C] font-[500]">
        {text}
      </p>
    </div>
  );
};

export default AddPhoto;
