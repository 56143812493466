import React from "react";
import ReactPaginate from "react-paginate";
import './common.scss';

const Pagination = ({
  totalPages,
  handlePageChange
}) => {
  const handlePageClick = (data) => {
    let selectedPage = data.selected + 1;
    handlePageChange(selectedPage);
  };

  return (
    <div className="flex justify-end mt-5">
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        previousLinkClassName="!bg-[#F0F0F0] !text-[#8C8C8C] !border !border-[#C4C4C4] !font-poppins !font-[400]"
        nextLinkClassName="!bg-[#00ADEF] !text-white w-[60px] !text-center !font-poppins !font-[400]"
        activeClassName={"active"}
      />
    </div>
  );
};

export default Pagination;