import React, { useState, useEffect } from "react";
import BreadCrumsHeader from "../../common/BreadCrums";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Pagination from "../../common/TablePagination";
import "./invitation.scss";
import CreateNewButton from "../../common/CreateNewButton";
import { getMerchantInvites } from "../../../services/onboardingService";
import { useDispatch, useSelector } from "react-redux";
import { separateDateTimeAndConvertToLocale } from "../../../utils/utilFunctions";
import Loader from "../../common/Loader";

const InvitationListDataTable = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.onboardingReducer?.loading);
  const [allInvites, setAllInvites] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);

  const getInvitesData = async () => {
    const invitesPromise = await dispatch(
      getMerchantInvites({ page: currentPage })
    )?.unwrap();
    if (invitesPromise?.success === true) {
      setAllInvites(invitesPromise?.data);
      setCurrentPage(invitesPromise?.currentPage);
      setTotalPages(invitesPromise?.totalPages);
      setCount(invitesPromise?.count);
    }
  };

  useEffect(() => {
    getInvitesData();
  }, [currentPage]);

  const items = [
    {
      title: "Merchants",
    },
    {
      title: "Invitation List",
    },
  ];

  const columns = [
    {
      name: "No.",
    },
    {
      name: "Date",
    },
    {
      name: "First Name",
    },
    {
      name: "Last Name",
    },
    {
      name: "Merchant Name",
    },
    {
      name: "Email",
    },
  ];

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      {loading === true && <Loader />}
      <div className="flex justify-between col-span-12">
        <BreadCrumsHeader heading="Invitation List" items={items} />
        <CreateNewButton
          text={"Create New Invite"}
          navigateTo={"/invitation-form"}
          iconClassName="sm:left-[1%] md:left-[8%] bottom-[30%]"
        />
      </div>

      <div className="col-span-12 mt-8">
        <TableContainer className="w-[100%]">
          <Table
            sx={{ borderCollapse: "separate", borderSpacing: "0px 8px" }}
            aria-label="simple table"
          >
            <TableHead className="bg-[#00ADEF]">
              <TableRow
                className="h-[50px]"
                sx={{ boxShadow: "0px 4px 4px 0px #00000040" }}
              >
                {columns.map((column) => {
                  return <TableCell>{column.name}</TableCell>;
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {allInvites?.map((invite, index) => {
                return (
                  <TableRow
                    className="h-[50px]"
                    sx={{
                      boxShadow: "0px 4px 4px 0px #00000040",
                      backgroundColor:
                        (index + 1) % 2 !== 0 ? "#F5F5F5" : "#FFFFFF",
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {
                        separateDateTimeAndConvertToLocale(invite.notifiedAt)
                          ?.localDate
                      }
                    </TableCell>
                    <TableCell>{invite?.firstName}</TableCell>
                    <TableCell>{invite?.lastName}</TableCell>
                    <TableCell>{invite?.merchantName}</TableCell>
                    <TableCell>{invite?.email}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default InvitationListDataTable;
