// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainDiv {
  background-color: white;
  border-radius: 5px;
  margin-top: 37px;
  padding-top: 2%;
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 6%;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1019607843);
}

.ql-container.ql-snow {
  border: 1px solid #d9d9d9 !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border: 1px solid #d9d9d9 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Fundraiser/Marketing/marketing.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,uDAAA;AACJ;;AAEA;EACI,oCAAA;EACA,yCAAA;EACA,0CAAA;AACJ;;AAEA;EACI,sCAAA;EACA,uCAAA;EACA,oCAAA;AACJ","sourcesContent":[".mainDiv{\n    background-color: white;\n    border-radius: 5px;\n    margin-top: 37px;\n    padding-top: 2%;\n    padding-left: 3%;\n    padding-right: 3%;\n    padding-bottom: 6%;\n    box-shadow: 0px 4px 8px 0px #0000001A;\n}\n\n.ql-container.ql-snow {\n    border: 1px solid #d9d9d9 !important;\n    border-bottom-left-radius: 5px !important;\n    border-bottom-right-radius: 5px !important;\n}\n\n.ql-toolbar.ql-snow {\n    border-top-left-radius: 5px !important;\n    border-top-right-radius: 5px !important;\n    border: 1px solid #d9d9d9 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
