import React, { useEffect, useState } from "react";
import PrimaryButton from "../PrimaryButton";
import GreyButton from "../GreyButton";
import { useFormik } from "formik";
import RadioSelection from "../RadioSelection";
import DayBox from "./DayBox";
import { DatePicker, TimePicker } from "antd";
import { customizeOfferSchema } from "../../../utils/validationSchemas";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";
import { CalendarOutlined } from "@ant-design/icons";
import { createOffer, getOfferById } from "../../../services/offerService";
import dayjs from "dayjs";

const CustomizeOffer = ({
  stepIndex,
  setStepIndex,
  handlePrevious,
  setPayloadData,
  editOfferData,
  isEdit,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.offerReducer?.loading);
  const offerData = useSelector((state) => state?.offerReducer?.createdOffer);
  const weekDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const format = "hh:mm A";

  const fillUpForm = (data) => {
    switch (data?.redemptionDays) {
      case "once":
        setFieldValue("rules", "Redeem Once");
        break;
      case "daily":
        setFieldValue("rules", "Redeem once daily");
        break;
      case "every_30_days":
        setFieldValue("rules", "Redeem once every 30 days");
        break;
      case "every_90_days":
        setFieldValue("rules", "Redeem once every 90 days");
        break;
      case "every_120_days":
        setFieldValue("rules", "Redeem once every 120 days");
        break;
      case "unlimited":
        setFieldValue("rules", "Redeem unlimited times");
        break;
      default:
        setFieldValue("rules", "");
    }
    setFieldValue(
      "startDate",
      data?.startDate !== undefined ? dayjs(data?.startDate) : ""
    );
    setFieldValue(
      "endDate",
      data?.endDate !== undefined ? dayjs(data?.endDate) : ""
    );
    setFieldValue(
      "startTime",
      data?.tradingHours[0]?.startTime !== undefined
        ? dayjs(data?.tradingHours[0]?.startTime, format)
        : ""
    );
    setFieldValue(
      "endTime",
      data?.tradingHours[0]?.endTime !== undefined
        ? dayjs(data?.tradingHours[0]?.endTime, format)
        : ""
    );
    const tradingDays = data?.tradingHours?.map(
      (d) => d?.day?.charAt(0)?.toUpperCase() + d?.day?.slice(1)
    );
    setFieldValue("days", tradingDays);
  };

  useEffect(() => {
    if (editOfferData !== null && isEdit == true) {
      fillUpForm(editOfferData);
    } else {
      fillUpForm(offerData);
    }
  }, [offerData, editOfferData]);

  const initialValues = {
    rules: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    days: [],
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: customizeOfferSchema,

    onSubmit: async (values) => {
      console.log("create values", values);
      const createPromise = await dispatch(createOffer(payload))?.unwrap();
      if (createPromise?.success === true) {
        setStepIndex(stepIndex + 1);
      }
    },
  });

  const { rules, startDate, endDate, startTime, endTime, days } = formik.values;

  const payload = {
    redemptionDays:
      rules === "Redeem Once"
        ? "once"
        : rules === "Redeem once daily"
        ? "daily"
        : rules === "Redeem once every 30 days"
        ? "every_30_days"
        : rules === "Redeem once every 90 days"
        ? "every_90_days"
        : rules === "Redeem once every 120 days"
        ? "every_120_days"
        : rules === "Redeem unlimited times"
        ? "unlimited"
        : "",
    startDate: startDate,
    endDate: endDate,
    tradingHours: days?.map((day) => {
      return {
        day: day?.toLowerCase(),
        startTime: startTime ? startTime.format(format) : null,
        endTime: endTime ? endTime.format(format) : null,
      };
    }),
    id: offerData?._id,
    isCreated: false,
  };

  useEffect(() => {
    setPayloadData(payload);
  }, [rules, startDate, endDate, days, startTime, endTime, offerData?._id]);

  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    setValues,
  } = formik;

  const radioOptions = [
    "Redeem Once",
    "Redeem once daily",
    "Redeem once every 30 days",
    "Redeem once every 90 days",
    "Redeem once every 120 days",
    "Redeem unlimited times",
  ];

  const handleDayClick = (day) => {
    const isSelected = days?.find((d) => d === day);

    if (isSelected) {
      const updatedDays = days?.filter((d) => d !== day);
      setFieldValue("days", updatedDays);
    } else {
      const d = weekDays?.find((d) => d === day);
      setFieldValue("days", [...days, d]);
    }
  };

  const isDayClicked = (day) => {
    return days?.find((sDay) => sDay === day);
  };

  return (
    <div className="w-full flex flex-col items-center justify-center mt-20">
      {loading === true && <Loader />}
      <div className="sm:w-[100%] md:w-[80%] lg:w-[70%] xl:w-[55%] offerform customize">
        <p className="font-poppins font-[500] sm:text-[12px] md:text-[14px] lg:text-[16px] text-[#303030]">
          Lets Customize your Offer
        </p>
        <p className="font-poppins font-[400] sm:text-[10px] md:text-[12px] text-[#767676]">
          Review the options that customers may be offered or add a new option
        </p>
        <p className="font-poppins font-[400] sm:text-[10px] md:text-[12px] lg:text-[14px] text-[#303030] mt-5">
          Necessary Rules:
        </p>
        <RadioSelection
          options={radioOptions}
          divClassName="mt-1"
          internalDivClass="flex flex-col items-start"
          secondRadioClass="mt-3"
          textClass="sm:text-[10px] md:text-[12px] lg:text-[14px] font-[400] text-[#303030] ml-4"
          label={""}
          width={"100%"}
          name="rules"
          value={rules}
          error={errors.rules}
          touched={touched.rules}
          setFieldValue={setFieldValue}
        />
        <p className="font-poppins font-[400] sm:text-[10px] md:text-[12px] lg:text-[14px] text-[#303030] mt-5">
          What time is this offer available to be claimed and at what days?
        </p>
        <div className="flex sm:flex-col md:flex-row justify-between mt-5 w-[100%]">
          <div className="md:w-[46%] sm:w-[100%]">
            <p className="invitationLabels">Start Date</p>
            <DatePicker
              className="invitationInputs w-[100%] outline-none"
              dateFormat="yyyy-MM-dd"
              placeholder=""
              name="startDate"
              defaultValue={startDate}
              value={startDate}
              onChange={(date, dateString) => {
                setFieldValue("startDate", date);
              }}
              onBlur={handleBlur}
              suffixIcon={<CalendarOutlined style={{ cursor: "pointer" }} />}
            />
            <div className="ms-1">
              <span className="text-red-600 text-[11px] font-[600] text-start">
                {errors.startDate && touched.startDate
                  ? errors.startDate
                  : null}
              </span>
            </div>
          </div>
          <div className="md:w-[46%] sm:w-[100%] sm:mt-5 md:mt-0">
            <p className="invitationLabels">End Date</p>
            <DatePicker
              className="invitationInputs w-[100%] outline-none"
              dateFormat="yyyy-MM-dd"
              placeholder=""
              name="endDate"
              defaultValue={endDate}
              value={endDate}
              onChange={(date, dateString) => {
                setFieldValue("endDate", date);
              }}
              onBlur={handleBlur}
            />
            <span className="text-red-600 text-[11px] font-[600] ms-1">
              {errors.endDate && touched.endDate ? errors.endDate : null}
            </span>
          </div>
        </div>
        <p className="font-poppins font-[400] sm:text-[10px] md:text-[12px] lg:text-[14px] text-[#303030] mt-5">
          Choose days of the week
        </p>
        <DayBox
          days={weekDays}
          handleDayClick={handleDayClick}
          isDayClicked={isDayClicked}
        />
        <span className="text-red-600 text-[12px] font-[600] ms-1">
          {errors.days && touched.days ? errors.days : null}
        </span>
        <div className="flex sm:flex-col md:flex-row justify-between mt-5 w-[100%]">
          <div className="md:w-[46%] sm:w-[100%]">
            <p className="invitationLabels">Start Time</p>
            <TimePicker
              className="invitationInputs w-[100%]"
              placeholder=""
              format={format}
              name="startTime"
              defaultValue={startTime}
              use12Hours={true}
              value={startTime}
              onChange={(date, dateString) => {
                setFieldValue("startTime", date);
              }}
              onBlur={handleBlur}
            />
            <span className="text-red-600 text-[12px] font-[600] ms-1">
              {errors.startTime && touched.startTime ? errors.startTime : null}
            </span>
          </div>
          <div className="md:w-[46%] sm:w-[100%] sm:mt-5 md:mt-0">
            <p className="invitationLabels">End Time</p>
            <TimePicker
              className="invitationInputs w-[100%]"
              placeholder=""
              format={format}
              name="endTime"
              defaultValue={endTime}
              use12Hours={true}
              value={endTime}
              onChange={(date, dateString) => {
                setFieldValue("endTime", date);
              }}
              onBlur={handleBlur}
            />
            <span className="text-red-600 text-[12px] font-[600] ms-1">
              {errors.endTime && touched.endTime ? errors.endTime : null}
            </span>
          </div>
        </div>
        <div className="w-full text-end mt-20">
          <GreyButton
            className="w-[40%] lg:w-[25%] h-[45px] rounded-[5px] text-[16px] font-[500] font-montserrat"
            label="Previous"
            onClick={handlePrevious}
          />
          <PrimaryButton
            className={
              "w-[40%] lg:w-[25%] h-[45px] rounded-[5px] text-[16px] font-[500] font-montserrat ms-2"
            }
            label="Next"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomizeOffer;
