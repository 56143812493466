import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swoodle from "../../../Assets/Images/swoodleLogo.svg";
import Backtick from "../../../Assets/Images/backtick.svg";
import Bullet from "../../../Assets/Icons/ListItem Ellipse 8767.svg";
import AppStore from "../../../Assets/Images/App Store.svg";
import PlayStore from "../../../Assets/Images/Google Play.svg";
import FundraiserHeader from "../common/FundraiserHeader";
import { retrieveSession } from "../../../services/subscriptionService";
import Loader from "../../common/Loader";

function FundraiserOrderConfirmation() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedPlan = useSelector(
    (state) => state?.subscriptionReducer?.selectedPlan
  );
  const loading = useSelector((state) => state?.subscriptionReducer?.loading);
  const [searchParams] = useSearchParams();
  const [userName, setUsername] = useState();
  const [email, setEmail] = useState();
  const sessionId = searchParams?.get("session_id");
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };

  const retrieve = async () => {
    const retrievePromise = await dispatch(
      retrieveSession({ sessionId: sessionId })
    ).unwrap();
    if (retrievePromise?.success === true) {
      setUsername(retrievePromise?.data?.user.name);
      setEmail(retrievePromise?.data?.user.email);
    } else {
      navigate("/subscription-failed");
    }
  };

  useEffect(() => {
    retrieve();
  }, []);
  return (
    <div className="bg-[#FAFAFA] flex flex-col">
      <FundraiserHeader />
      {loading && <Loader />}
      {!loading && (
        <div className="flex flex-col sm:flex-row">
          <div className="w-[0%] sm:w-[25%] md:w-[23%] lg:w-[28%] 2xl:w-[30%] bg-[#FFFFFF]"></div>
          <div className="w-[100%] sm:w-[65%] md:w-[55%] lg:w-[45%] 2xl:w-[40%] px-4 sm:px-5 lg:px-9 pt-12 bg-[#FAFAFA]">
            <div className="flex flex-col">
              <div className="flex justify-between">
                <div className="flex">
                  <div className="font-poppins font-[600] text-[18px] md:text-[20px] lg:text-[22px] text-[#303030]">
                    Order Confirmation
                  </div>
                </div>
                <div>
                  <img
                    src={Swoodle}
                    className="w-[60px] sm:w-[80px] md:w-[100px] lg:w-[120px]"
                  />
                </div>
              </div>

              <div className="flex flex-col my-2">
                <p className="text-[14px] font-[400] text-[#303030]">
                  Hi {userName},
                </p>
                <p className="text-[14px] font-[400] text-[#303030]">
                  Your order has been confirmed. We will send a confirmation and
                  activation email to {email}
                </p>
              </div>
            </div>

            {/* Summary */}

            <p className="font-poppins font-[500] text-[16px] text-[#303030] leading-[27px] mt-6 sm:mt-8 mb-4">
              Order Summary:
            </p>

            {/* Order Card */}

            <div
              className="bg-[#ffffff] px-4 sm:px-8 pt-5 pb-11 rounded-[10px] mb-10"
              style={{
                border: "2px solid #00ADEF4D",
                boxShadow: "0px 4px 6px 0px #00000040",
              }}
            >
              <div
                className="flex justify-between pb-4"
                style={{ borderBottom: "1px solid #DFE1E6" }}
              >
                <div className="font-poppins font-[600] text-[14px] text-[#303030]">
                  <p>Order Number</p>
                  <p className="font-[400]">1363750</p>
                </div>
                <div className="font-poppins font-[600] text-[14px] text-[#303030]">
                  <p>Order Date</p>
                  <p className="font-[400]">
                    {new Date()
                      .toLocaleDateString("en-GB", options)
                      .replace(/\//g, "-")}
                  </p>
                </div>
              </div>

              <div
                className="flex flex-col gap-2 sm:gap-5 pt-2 pb-8"
                style={{ borderBottom: "1px solid #DFE1E6" }}
              >
                <div className="w-[100%]  flex justify-between">
                  <div className="w-[50%] sm:w-[50%] md:w-[50%] lg:w-[60%]">
                    <p className="font-poppins font-[600] text-[12px] sm:text-[14px] mb-1 text-[#303030]">
                      Product
                    </p>
                    <p className="font-poppins font-[400] text-[12px] sm:text-[14px] text-[#303030]">
                      Subscription
                    </p>
                    <p className="font-poppins font-[400] text-[12px] sm:text-[14px] text-[#303030]">
                      upto{" "}
                      {selectedPlan?.duration ? selectedPlan?.duration : ""}
                    </p>
                  </div>
                  <div className="w-[50%] sm:w-[50%] md:w-[50%] lg:w-[40%] flex justify-between">
                    <div className="w-[40%]">
                      <p className="font-poppins font-[600] text-[12px] sm:text-[14px] mb-1 text-[#303030] ">
                        Price
                      </p>
                      <p className="font-poppins font-[400] text-[12px] sm:text-[14px] text-[#303030]">
                        ${selectedPlan?.amount ? selectedPlan?.amount : ""}
                      </p>
                    </div>
                    <div className=" w-[20%]">
                      <p className="font-poppins font-[600] text-[12px] sm:text-[14px] mb-1 text-[#303030] ">
                        Qty
                      </p>
                      <p className="font-poppins font-[400] text-[12px] sm:text-[14px] text-[#303030]">
                        1
                      </p>
                    </div>
                    <div className="w-[40%] text-end">
                      <p className="font-poppins font-[600] text-[12px] sm:text-[14px] mb-1 text-[#303030] ">
                        Total
                      </p>
                      <p className="font-poppins font-[400] text-[12px] sm:text-[14px] text-[#303030] ">
                        $
                        {selectedPlan?.amount ? selectedPlan?.amount : "$59.99"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between">
                  <div className="">
                    <p className="font-poppins font-[600] text-[12px] sm:text-[14px] mb-1 text-[#303030]">
                      Product
                    </p>
                    <p className="font-poppins font-[400] text-[12px] sm:text-[14px] w-[62%] text-[#303030] ">
                      Subscription upto{" "}
                      {selectedPlan?.duration ? selectedPlan?.duration : ""}
                    </p>
                  </div>
                  {/* <div className="text-end">
                  <p className="font-poppins font-[600] text-[12px] sm:text-[14px] mb-1 text-[#303030]">
                    Activation Code
                  </p>
                  <p className="font-poppins font-[400] text-[12px] sm:text-[14px] text-[#303030] ">
                    54637184
                  </p>
                </div> */}
                </div>

                {/* <div className="text-end">
                <PrimaryButton
                  label={"Activate"}
                  className={"w-[38%] sm:w-[30%] md:w-[35%] xl:w-[25%] text-[14px] font-[600] h-[40px] sm:h-[40px] md:h-[45px] rounded-[10px]"}
                />
              </div> */}
              </div>

              <div className="mt-4 mb-16">
                <span className="font-poppins font-[400] text-[12px] text-[#303030]">
                  To get started, simply:
                </span>

                <div className="flex flex-col mt-[15px] ps-5">
                  {/* <div className="flex items-baseline mb-1">
                    <img
                      src={Bullet}
                      className="w-[5px] sm:w-[6px] h-[5px] sm:h-[6px] !max-w-none mr-2"
                    ></img>
                    <p className="text-[12px] font-[400] text-[#303030]">
                      Activate your membership using the activation code
                    </p>
                  </div> */}

                  <div className="flex items-baseline mb-1">
                    <img
                      src={Bullet}
                      className="w-[5px] sm:w-[6px] h-[5px] sm:h-[6px] !max-w-none mr-2"
                    ></img>
                    <p className="text-[12px] font-[400] text-[#303030]">
                      Download the latest Swoodle app
                    </p>
                  </div>

                  <div className="flex items-baseline">
                    <img
                      src={Bullet}
                      className="w-[5px] sm:w-[6px] h-[5px] sm:h-[6px] !max-w-none mr-2"
                    ></img>
                    <p className="text-[12px] font-[400] text-[#303030]">
                      Login using your email and password and start saving
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-[100%] flex justify-center items-center">
                <div className="flex justify-between gap-3">
                  <img src={AppStore} alt="App Store" className="w-[110px]" />
                  <img src={PlayStore} alt="Play Store" className="w-[110px]" />
                </div>
              </div>
            </div>
          </div>
          <div className="w-[0%] sm:w-[10%] md:w-[22%] lg:w-[27%] 2xl:w-[30%] bg-[#FFFFFF]"></div>
        </div>
      )}
    </div>
  );
}

export default FundraiserOrderConfirmation;
