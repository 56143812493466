import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import { store, persistor } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { fetchToken, messaging, onMessageListener } from "./firebase";
import NetworkStatus from "./pages/common/NetworkStatus";
import AdminRoutes from "./routes/AdminRoutes";
import MerchantRoutes from "./routes/MerchantRoutes";
import FundraiserRoutes from "./routes/FundraiserRoutes";
import UserRoutes from "./routes/UserRoutes";
import CommonRoutes from "./routes/CommonRoutes";
import { setFcm } from "./utils/connectStore";
import rejectedIcon from "./Assets/Icons/rejectedIcon.svg";
import approvedIcon from "./Assets/Icons/approvedIcon.svg";
import NotificationPopup from "./components/common/NotificationPopup";

function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [notification, setNotification] = useState(null);

  const permissionRequest = async () => {
    if ("Notification" in window) {
      const permission = await Notification?.requestPermission();
      if (permission === "granted") {
        console.log("im in first iff", permission, "messaging", messaging);
        //Generate Token
        if (messaging) {
          try {
            // navigator.serviceWorker.ready.then(async() => {
            const token = await fetchToken();
            console.log("Token====>", token);
            setFcm({ fcmToken: token });
            // });
          } catch (e) {
            console.log("catch the error>>>", e);
          }
        } else {
          return null;
        }
        //Send this token to your backend DB
      } else if (permission === "denied") {
        alert("Please allow notification for this website to get up to date.");
      }
    } else {
      console.log("window else");
    }
  };

  useEffect(() => {
    //Request user to show notifications
    permissionRequest();
  }, []);

  onMessageListener()
    .then((payload) => {
      console.log("listener payload", payload);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
        icon: payload.notification.body.includes("approved")
          ? approvedIcon
          : rejectedIcon,
      });
      // alert(payload.notification.title + payload.notification.body);
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <NetworkStatus isOnline={isOnline} setIsOnline={setIsOnline} />
          {isOnline === true && (
            <>
              {notification !== null && (
                <NotificationPopup
                  heading={notification?.title}
                  content={notification?.body}
                  image={notification?.icon}
                  setNotification={setNotification}
                />
              )}
              {/*Common*/}
              <CommonRoutes />

              {/* Admin */}
              <AdminRoutes />

              {/* Merchant */}
              <MerchantRoutes />

              {/* Fundraiser */}
              <FundraiserRoutes />

              {/*User Flow */}
              <UserRoutes />
            </>
          )}
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
