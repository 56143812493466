import React, { useState, useMemo } from "react";
import "./bankform.scss";
import { ReactComponent as DownArrow } from "../../../Assets/Icons/Down Arrow.svg";
import Selector from "../../common/Selector";
import BreadCrumsHeader from "../../common/BreadCrums";
import Input from "../../common/FormInputs";
import FormLayout from "../../common/FormLayout";
import toast from "../../../utils/toast";
import { Country, State, City } from "country-state-city";

import { useFormik } from "formik";
import { bankSchema } from "../../../utils/validationSchemas";
import { addBank } from "../../../services/fundraiserService";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/Loader";

const BankForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.fundraiserReducer?.loading);

  const initialValues = {
    accountTitle: "",
    bankName: "",
    branchName: "",
    branchCode: "",
    accountNo: ""
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: bankSchema,

    onSubmit: async (values) => {
      console.log("Bank values: ", values);
      const addPromise = await dispatch(
        addBank({
          name: values?.accountTitle,
          accountNo: values?.accountNo,
          branchCode: values?.branchCode
        })
      ).unwrap();
      if (addPromise?.success === true) {
        navigate("/fundraiser/banks-list");
      }
    },
  });

  const {
    accountTitle,
    bankName,
    branchName,
    branchCode,
    accountNo
  } = formik.values;

  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
  } = formik;

  const items = [
    {
      title: "Connect with the Bank",
    },
    {
      title: "Add a Bank",
    },
  ];

  const BankFormContent = (
    <>
      <Input
        type="text"
        label="Account Title"
        placeholder=""
        width="100%"
        radius="5px"
        InputDivClass="w-[100%] mt-8"
        name="accountTitle"
        value={accountTitle}
        handleChange={handleChange}
        handleBlur={handleBlur}
        error={errors.accountTitle}
        touched={touched.accountTitle}
      />
      <Input
        type="text"
        label="Bank Name"
        placeholder=""
        width="100%"
        radius="5px"
        InputDivClass="w-[100%] mt-8"
        name="bankName"
        value={bankName}
        handleChange={handleChange}
        handleBlur={handleBlur}
        error={errors.bankName}
        touched={touched.bankName}
      />
      <Input
        type="text"
        label="Branch Name"
        placeholder=""
        width="100%"
        radius="5px"
        InputDivClass="w-[100%] mt-8"
        name="branchName"
        value={branchName}
        handleChange={handleChange}
        handleBlur={handleBlur}
        error={errors.branchName}
        touched={touched.branchName}
      />
      <Input
        type="text"
        label="Branch Code"
        placeholder=""
        width="100%"
        radius="5px"
        InputDivClass="w-[100%] mt-8"
        name="branchCode"
        value={branchCode}
        handleChange={handleChange}
        handleBlur={handleBlur}
        error={errors.branchCode}
        touched={touched.branchCode}
      />
      <Input
        type="text"
        label="Account No."
        placeholder=""
        width="100%"
        radius="5px"
        InputDivClass="w-[100%] mt-8"
        name="accountNo"
        value={accountNo}
        handleChange={handleChange}
        handleBlur={handleBlur}
        error={errors.accountNo}
        touched={touched.accountNo}
      />
    </>
  );

  return (
    <>
      {loading === true && <Loader />}
      <BreadCrumsHeader heading="Add a Bank" items={items} form={true} />
      <FormLayout title={"Add a Bank"} content={BankFormContent} />

      <div className="col-span-12 text-end mt-14">
        <button
          className="w-[30%] lg:w-[15%] h-[45px] rounded-[5px] bg-[#F0F0F0] text-[16px] font-[500] text-[#8C8C8C]"
          style={{ border: "1px solid #C4C4C4" }}
        >
          Cancel
        </button>
        <button
          type="submit"
          onClick={() => {
            handleSubmit();
          }}
          className="w-[30%] lg:w-[15%] h-[45px] rounded-[5px] bg-[#00ADEF] text-[16px] font-[500] text-[#FFFFFF] ms-2"
        >
          Save
        </button>
      </div>
    </>
  );
};

export default BankForm;
