import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";

dayjs.extend(tz);
const timeZone = dayjs.tz.guess();

export const combineDateTimeAndConvertToUTC = (date, time) => {
  const dateObj = new Date(date);
  const timeObj = new Date(time);

  // Set hours and minutes from startTime to startDate
  dateObj.setHours(timeObj.getHours());
  dateObj.setMinutes(timeObj.getMinutes());

  // Convert combined date to UTC string
  const dateTimeUTC = dateObj.toISOString();

  return dateTimeUTC;
};

export const separateDateTimeAndConvertToLocale = (utcDate) => {
  const localTime = new Date(utcDate).toLocaleTimeString("en-US", {
    timeZone: timeZone,
    hour: "2-digit",
    minute: "2-digit",
  });
  const localDate = new Date(utcDate).toLocaleDateString("en-US", {
    timeZone: timeZone,
  });
  return { localDate, localTime };
};

export const roleEnum = {
  merchant: "merchant/",
  fundraiser: "fundraiser/"
};
