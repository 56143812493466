import React from 'react'
import './dashboard.scss'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const OffersChart = ({className, barThickness}) => {

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 2,
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      title: {
        display: false,
        text: 'Chart.js Bar Chart',
      },
      tooltip: {
        enabled: false
      }
    },
    scales: {
      x: {
        grid: {
          offset: false,
          drawOnChartArea: false,
          color: "#655B96"
        },
        ticks: {
          color: "rgba(148, 148, 148, 1)"
        },
        border: {
          color: "rgba(148, 148, 148, 1)"
        }
      },
      y: {
        grid: {
          drawOnChartArea: false,
          color: "#655B96",
          fontSize: "11px",
        },
        ticks: {
          color: "rgba(148, 148, 148, 1)",
          fontSize: "11px",
          stepSize: 20
        },
        border: {
          color: "rgba(148, 148, 148, 1)"
        }
      }
    }
  };
  
  const labels = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

  const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: [65, 59, 80, 81, 56, 55, 40, 34, 20, 10, 27, 87],
        backgroundColor: 'rgba(0, 173, 239, 1)',
        barThickness: barThickness
      }
    ],
  };

  return (
    <div className={className} style={{width: '99%'}}>
      <Bar options={options} data={data} />
    </div>
  )
}

export default OffersChart
