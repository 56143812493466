import React from "react";
import DeleteIcon from "../../Assets/Icons/Delete.svg";
import { Tooltip } from "antd";

const Delete = ({ onClick }) => {
  return (
    <Tooltip title={"delete offer"} color="#EC826E">
      <div
        className="bg-[#EC826E4D] w-[25px] h-[25px] rounded-[100%] flex justify-center items-center cursor-pointer"
        style={{
          border: "0.3px solid #EC826E",
          boxShadow: "0px 2px 2px 0px #EC826E29",
        }}
        onClick={onClick}
      >
        <img src={DeleteIcon}></img>
      </div>
    </Tooltip>
  );
};

export default Delete;
