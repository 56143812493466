import React, { useState } from "react";
import "./dashboard.scss";
import ProgressBarChart from "./ProgressBarChart";

const Withdrawals = () => {
  const [progresses, setProgresses] = useState([
    {
      name: "McDonald’s",
      progress: 65
    },
    {
      name: "Grilled",
      progress: 40
    },
    {
      name: "Noodle Box",
      progress: 75
    },
    {
      name: "Village Cinemas",
      progress: 25
    },
    {
      name: "iFLY",
      progress: 20
    },
    {
      name: "NBL",
      progress: 60
    },
    {
      name: "Luna Park",
      progress: 70
    },
    {
      name: "Boost",
      progress: 40
    },
    {
      name: "Hunky Dory",
      progress: 90
    }
  ])
  return (
    <div className="summary w-full h-full p-4 offersProgress">
      <div className="pb-[2%]">
        <span className="text-[14px] font-poppins font-[500] text-[#4D4D4D] whitespace-nowrap">
          Withdrawals
        </span>
      </div>
      <hr/>
      <div className="flex justify-end w-full">
        <div className="flex justify-between mt-[2%] w-[75px]">
          <span className="text-[9px] font-poppins font-[500] text-[#8C8C8C]">Total:</span>
          <span className="text-[9px] font-poppins font-[500] text-[#303030]">$9,309</span>
        </div>
      </div>
      <div className="flex justify-end w-full">
        <div className="flex justify-between mt-[2%] mb-[4%] w-[80px]">
          <span className="text-[9px] font-poppins font-[500] text-[#8C8C8C]">Today:</span>
          <span className="text-[9px] font-poppins font-[500] text-[#303030]">$1,200</span>
        </div>
      </div>
      <ProgressBarChart/>
    </div>
  );
};

export default Withdrawals;
