import React from "react";
import OnboardSwoodleLogo from "../../../Assets/Images/Onboard Swoodle Logo.svg";
import PrimaryButton from "../../common/PrimaryButton";
import { ReactComponent as CreatedCheck } from "../../../Assets/Images/createdCheck.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../common/Loader";

const AccountCreated = () => {
  const navigate = useNavigate();
  const loading = useSelector((state) => state?.onboardingReducer?.loading);
  const handleSubmit = () => {
    navigate("/fundraiser/account-login")
  };

  return (
    <div className="w-full py-10 h-screen flex flex-col items-center offerform">
      {loading && <Loader />}
      <p className="font-poppins font-[500] sm:text-[18px] md:text-[22px] lg:text-[26px] text-[#303030]">
        Welcome to Swoodle! Let’s set up your Account!
      </p>
      <div className="flex flex-col items-center justify-center sm:w-[70%] md:w-[60%] lg:w-[50%] xl:w-[45%] 2xl:w-[40%]">
        <img
          src={OnboardSwoodleLogo}
          className="pt-[6%] pb-[2%] w-[60%]"
        ></img>
        <p className="font-poppins font-[500] sm:text-[18px] md:text-[22px] lg:text-[26px] text-[#303030]">
          FUNDRAISER
        </p>
        <CreatedCheck className="text-center sm:my-10 lg:my-16" />
        <p className="font-poppins font-[400] sm:text-[12px] md:text-[14px] lg:text-[16px] xl:text-[16px] text-[#303030] mt-2 text-center">
          Your account has been created and the password has been set
          successfully
        </p>
        <div className="text-center w-full">
          <PrimaryButton
            className={
              "sm:w-[50%] md:w-[40%] lg:w-[50%] font-poppins sm:text-[14px] lg:text-[16px] text-center font-[500] sm:h-[45px] md:h-[50px] lg:h-[55px] rounded-[10px] my-10 tracking-widest"
            }
            label="LOGIN"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountCreated;
