import React, { useEffect } from "react";
import { ReactComponent as FailureImg } from "../../Assets/Images/subscrptionFailureImg.svg";
import FundraiserHeader from "../../components/User/common/FundraiserHeader";

const NetworkStatus = ({ isOnline, setIsOnline }) => {
  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <div>
      {!isOnline ? (
        <div className="w-full h-full">
          <FundraiserHeader />
          <div className="py-10 h-[90vh] flex flex-col items-center justify-center gap-0 md:gap-6 lg:gap-12">
            <FailureImg className="w-[270px] h-[220px] sm:w-[55%] sm:h-[55%]" />
            <div className="font-poppins font-[600] text-[14px] sm:text-[16px] md:text-[18px] flex flex-col items-center justify-center">
              <p className="text-[#000000]">
                Offline. Please check your internet connection.
              </p>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default NetworkStatus;
