// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainDiv {
  background-color: white;
  border-radius: 5px;
  margin-top: 37px;
  padding-top: 2%;
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 6%;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1019607843);
}

.ant-picker:hover {
  border: 1px solid #D9D9D9 !important;
}

.ant-picker-focused {
  border: 1px solid #D9D9D9 !important;
}

.ant-btn {
  background-color: #00ADEF !important;
  color: white !important;
  padding-bottom: 24px !important;
  padding-right: 8px !important;
}

.anticon-clock-circle > svg {
  color: #00ADEF;
  height: 20px;
  width: 20px;
}

.anticon-calendar > svg {
  color: #00ADEF;
  width: 22.5px;
  height: 20px;
}

.anticon-close-circle > svg {
  color: #00ADEF;
  width: 20px;
  height: 20px;
}

.asset .ant-select-selector {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #D9D9D9 !important;
  height: 45px !important;
  padding-left: 2% !important;
}

.asset .ant-select .ant-select-arrow {
  top: 75% !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Fundraiser/FundraiserAssets/fundraiserAssets.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,uDAAA;AACJ;;AAEA;EACI,oCAAA;AACJ;;AAEA;EACI,oCAAA;AACJ;;AAEA;EACI,oCAAA;EACA,uBAAA;EACA,+BAAA;EACA,6BAAA;AACJ;;AAEA;EACI,cAAA;EACA,YAAA;EACA,WAAA;AACJ;;AAEA;EACI,cAAA;EACA,aAAA;EACA,YAAA;AACJ;;AAEA;EACI,cAAA;EACA,WAAA;EACA,YAAA;AACJ;;AAEA;EACI,wBAAA;EACA,2BAAA;EACA,oCAAA;EACA,uBAAA;EACA,2BAAA;AACJ;;AAEA;EACI,mBAAA;AACJ","sourcesContent":[".mainDiv{\n    background-color: white;\n    border-radius: 5px;\n    margin-top: 37px;\n    padding-top: 2%;\n    padding-left: 3%;\n    padding-right: 3%;\n    padding-bottom: 6%;\n    box-shadow: 0px 4px 8px 0px #0000001A;\n}\n\n.ant-picker:hover{\n    border: 1px solid #D9D9D9 !important;\n}\n\n.ant-picker-focused{\n    border: 1px solid #D9D9D9 !important;\n}\n\n.ant-btn{\n    background-color: #00ADEF !important;\n    color: white !important;\n    padding-bottom: 24px !important;\n    padding-right: 8px !important;\n}\n\n.anticon-clock-circle > svg{\n    color: #00ADEF;\n    height: 20px;\n    width: 20px;\n}\n\n.anticon-calendar > svg{\n    color: #00ADEF;\n    width: 22.5px;\n    height: 20px;\n}\n\n.anticon-close-circle > svg{\n    color: #00ADEF;\n    width: 20px;\n    height: 20px;\n}\n\n.asset .ant-select-selector {\n    outline: none !important;\n    box-shadow: none !important;\n    border: 1px solid #D9D9D9 !important;\n    height: 45px !important;\n    padding-left: 2% !important;\n}\n\n.asset .ant-select .ant-select-arrow {\n    top: 75% !important\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
