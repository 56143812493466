// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.css-1ygcj2i-MuiTableCell-root {
  color: white !important;
  font-family: Poppins, sans-serif !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  border: none;
  padding: 0px 0px 0px 15px !important;
  white-space: nowrap !important;
}

.css-1ex1afd-MuiTableCell-root {
  color: #303030 !important;
  font-family: Poppins, sans-serif !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  padding: 0px 0px 0px 15px !important;
  white-space: nowrap !important;
}

.searchBar {
  border: 2px solid #E9ECEF;
  border-radius: 10px;
  height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/Fundraiser/BanksList/banksList.scss"],"names":[],"mappings":"AACA;EACI,uBAAA;EACA,2CAAA;EACA,2BAAA;EACA,0BAAA;EACA,YAAA;EACA,oCAAA;EACA,8BAAA;AAAJ;;AAGA;EACI,yBAAA;EACA,2CAAA;EACA,2BAAA;EACA,0BAAA;EACA,oCAAA;EACA,8BAAA;AAAJ;;AAIA;EACI,yBAAA;EACA,mBAAA;EACA,YAAA;AADJ","sourcesContent":["\nth.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.css-1ygcj2i-MuiTableCell-root{\n    color: white !important;\n    font-family: Poppins, sans-serif !important;\n    font-weight: 600 !important;\n    font-size: 14px !important;\n    border: none;\n    padding: 0px 0px 0px 15px !important;\n    white-space: nowrap !important;\n}\n\n.css-1ex1afd-MuiTableCell-root {\n    color:#303030 !important;\n    font-family: Poppins, sans-serif !important;\n    font-weight: 400 !important;\n    font-size: 12px !important;\n    padding: 0px 0px 0px 15px !important;\n    white-space: nowrap !important;\n}\n\n//SearchBar\n.searchBar {\n    border: 2px solid #E9ECEF;\n    border-radius: 10px;\n    height: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
