import axios from "../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const addFranchise = createAsyncThunk(
  "location",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post("location", payload);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const getFranchises = createAsyncThunk(
  "allLocations",
  async (payload, thunkAPI) => {
    try {
      const url = payload?.merchant
        ? `location/merchant?merchant=${payload?.merchant}`
        : payload?.page
        ? `location/merchant?page=${payload?.page}`
        : `location/merchant?limit=${100}`;
      const response = await axios.get(url);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const toggleFranchiseStatus = createAsyncThunk(
  "location/status",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.patch(`location/status/${payload?.id}`);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const editFranchise = createAsyncThunk(
  "location/update",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.patch(`location/${payload?.id}`, payload);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const deleteFranchise = createAsyncThunk(
  "location/delete",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.delete(`location/${payload?.id}`);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const getLocationById = createAsyncThunk(
  "location/id",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(`location/${payload?.id}`);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);