import React from "react";

const Input = ({
  type,
  label,
  placeholder,
  width,
  radius,
  InputDivClass,
  name,
  value,
  handleChange,
  handleBlur,
  error,
  touched,
  onClick,
  handleKeyDown,
}) => {
  return (
    <div className={`${InputDivClass}`}>
      <p className="invitationLabels">{label}</p>
      <input
        type={type ? type : "text"}
        name={name}
        value={value}
        placeholder={placeholder}
        className={`invitationInputs`}
        style={{ width: width, borderRadius: radius }}
        onChange={handleChange}
        onBlur={handleBlur}
        onClick={onClick}
        onKeyDown={handleKeyDown}
      />
      {error && touched ? (
        <div className="ms-1">
          <span className="text-red-600 sm:text-[10px] md:text-[12px] font-[600] text-start">
            {error}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default Input;
