import React from "react";
import { useLocation } from "react-router-dom";
import MerchantOfferlist from "./MerchantOfferlist";
import AdminOfferlist from "./AdminOfferlist";

const OfferListDataTable = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <>
      {currentPath?.includes("merchant/") ? (
        <MerchantOfferlist />
      ) : (
        <AdminOfferlist />
      )}
    </>
  );
};

export default OfferListDataTable;
