import React from "react";
import { ReactComponent as Cross } from "../../../Assets/Icons/redCross.svg";
import { ReactComponent as Tick } from "../../../Assets/Icons/tick.svg";
import { ReactComponent as ApprovedIcon } from "../../../Assets/Icons/approveIcon.svg";
import { ReactComponent as DeclinedIcon } from "../../../Assets/Icons/declinedIcon.svg";
import { ReactComponent as PendingIcon } from "../../../Assets/Icons/pendingIcon.svg";
import { ReactComponent as InfoIcon } from "../../../Assets/Icons/infoIcon.svg";
import { useLocation } from "react-router-dom";
import { ReactComponent as DraftedIcon } from "../../../Assets/Icons/draftedIcon.svg";

const Confirmation = ({
  status,
  handleApproveClick,
  handleRejectionClick,
  handleInfoClick,
}) => {
  const location = useLocation();
  const currentPath = location?.pathname;

  return status === "pending" && !currentPath?.includes("merchant/") ? (
    <div className="flex justify-start items-center">
      <Tick className="cursor-pointer" onClick={handleApproveClick} />
      <Cross className="cursor-pointer" onClick={handleRejectionClick} />
    </div>
  ) : status === "pending" && currentPath?.includes("merchant/") ? (
    // <div className="flex justify-center items-center">
      <PendingIcon />
    // </div>
  ) : status === "approved" ? (
    <ApprovedIcon />
  ) : status === "rejected" ? (
    <div className="flex justify-start items-center gap-1">
      <DeclinedIcon />{" "}
      <InfoIcon className="cursor-pointer" onClick={handleInfoClick} />
    </div>
  ) : status === "drafted" ? (
    <DraftedIcon />
  ) : null;
};

export default Confirmation;
