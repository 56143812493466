import React from "react";
import { useSelector } from "react-redux";
import Tick from "../../../Assets/Images/tick.svg";
import PrimaryOutlineButton from "../../common/PrimaryOutlineButton";
import Loader from "../../common/Loader";

const ExistingSubscriptionPlan = ({
  title,
  plans,
  price,
  duration,
  cancelClick
}) => {
  const loading = useSelector((state) => state.subscriptionReducer.loading);
  return (
    <div
      className="bg-[#ffffff] rounded-[10px] w-[95%] sm:w-[98%] md:w-[80%] lg:w-[60%] 2xl:w-[50%] hidden sm:block"
      style={{
        border: "2px solid #00ADEF4D",
        boxShadow: "0px 4px 6px 0px #00000040",
      }}
    >
      <div className="bg-[#00ADEF] w-full text-[#ffffff] text-[18px] font-[600] px-12 py-5 rounded-tl-[10px] rounded-tr-[10px] text-start whitespace-nowrap">
        {title}
      </div>
      {loading && <Loader/>}
      <div className="flex justify-between items-center">
        <div className="pt-9 pb-7 pl-12">
          {plans.map((plan) => {
            return (
              <div className="flex gap-2 mb-4">
                <div className="flex items-center">
                  <img src={Tick} className="w-[13px]"></img>
                </div>
                <p className="text-[12px] font-[400] text-[#303030]">{plan}</p>
              </div>
            );
          })}

          <div className="text-start mt-11">
            <PrimaryOutlineButton
              label={"Cancel Subscription"}
              className={
                "w-[70%] text-[14px] font-[500] h-[40px] sm:h-[40px] md:h-[45px] rounded-[10px]"
              }
              onClick={cancelClick}
            />
          </div>
        </div>
        <div className="flex flex-col items-end justify-center pr-12">
          <p className="text-[38px] font-[600] text-[#303030]">${price}</p>
          <p className="text-[11px] font-[400] text-[#303030]">
            up to {duration}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ExistingSubscriptionPlan;
