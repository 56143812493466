import React from "react";
import { useSelector } from "react-redux";
import Tick from "../../../Assets/Images/tick.svg";
import PrimaryButton from "../../common/PrimaryButton";
import PrimaryOutlineButton from "../../common/PrimaryOutlineButton";
import Loader from "../../common/Loader";

const SubscriptionPlan = ({
  title,
  plans,
  price,
  duration,
  joinClick,
  cancelOption,
  cancelClick,
}) => {
  const loading = useSelector((state) => state.subscriptionReducer.loading);
  return (
    <>
      <div
        className={`bg-[#ffffff] rounded-[10px] w-[90%] sm:w-[45%] md:w-[40%] lg:w-[30%] 2xl:w-[25%]`}
        style={{
          border: "2px solid #00ADEF4D",
          boxShadow: "0px 4px 6px 0px #00000040",
        }}
      >
        {loading && <Loader />}
        <div className="flex justify-center bg-[#00ADEF] text-[#ffffff] text-[18px] 2xl:text-[26px] font-[600] px-24 py-5 rounded-tl-[10px] rounded-tr-[10px] whitespace-nowrap">
          {title}
        </div>

        <div className="flex flex-col items-center justify-center gap-y-4 py-8 bg-[#00ADEF1A]">
          <p className="text-[38px] 2xl:text-[44px] font-[600] text-[#303030]">
            ${price}
          </p>
          <p className="text-[11px] 2xl:text-[18px] font-[400] text-[#303030]">
            up to {duration}
          </p>
        </div>

        <div className="pt-9 pb-7 px-3">
          {plans
            ? plans.map((plan) => {
                return (
                  <div className="flex gap-2 mb-4">
                    <div className="flex items-center">
                      <img src={Tick} className="w-[13px]"></img>
                    </div>
                    <p className="text-[10px] 2xl:text-[14px] font-[400] text-[#303030]">
                      {plan}
                    </p>
                  </div>
                );
              })
            : null}

          {cancelOption == true ? (
            <div className="text-center mt-11">
              <PrimaryOutlineButton
                label={"Cancel Subscription"}
                className={
                  "w-[72%] text-[14px] 2xl:text-[20px] font-[500] h-[40px] sm:h-[40px] md:h-[45px] rounded-[10px]"
                }
                onClick={cancelClick}
              />
            </div>
          ) : (
            <div className="text-center mt-11">
              <PrimaryButton
                label={"Join Now"}
                className={
                  "w-[72%] text-[16px] 2xl:text-[20px] font-[600] h-[40px] sm:h-[40px] md:h-[45px] rounded-[10px]"
                }
                onClick={joinClick}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SubscriptionPlan;
