import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import FormBg from "../../../Assets/Images/Fundraiser/membershipActivation.svg";
import Profile from "../../../Assets/Images/Profile.svg";
import passwordIcon from "../../../Assets/Icons/passwordIcon.svg";
import TextInput from "../../common/SignUp/TextInput";
import FundraiserHeader from "../common/FundraiserHeader";
import FundraiserBodyHeader from "../common/FundraiserBodyHeader";
import PrimaryButton from "../../common/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { resetPasswordSchema } from "../../../utils/validationSchemas";
import { resetPassword } from "../../../services/authService";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const otpData = useSelector((state) => state?.authReducer?.otpToken);

  const { price, duration, email } = location?.state;

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPassword = () => {
    setConfirmShowPassword(!showConfirmPassword);
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: async (values) => {
      try {
        const resetPromise = await dispatch(
          resetPassword({ token: otpData, password: password })
        ).unwrap();

        if(resetPromise?.success === true) {
          navigate("/existing-login",{
            state: {
              email: email
            },
          });
        } else {
          navigate("/forget-password")
        }
      } catch (err) {
        console.log("err", err);
      }
      // Handle form submission
      console.log("values", values);
    },
  });

  const { password, confirmPassword } = formik.values;

  const { handleChange, handleBlur, handleSubmit, errors, touched } = formik;

  return (
    <div className="pb-24">
      <FundraiserHeader />

      <div className="flex flex-col items-center">
        <FundraiserBodyHeader
          heading={"Enter New Password"}
          content={""}
          contentClass={"w-[100%] sm:w-[80%] md:w-[90%] text-[#8C8C8C]"}
        />

        <div className="relative mt-12 sm:mt-24 mb-14">
          <img src={FormBg} className="w-[330px] sm:w-[350px]"></img>

          <div className="absolute top-2 sm:top-0 w-full mt-[-14%]">
            <div className="flex justify-center">
              <img src={Profile} className="w-[80px] sm:w-[104px]"></img>
            </div>

            <div className="px-[6%] sm:px-[8%] mt-5">
              <TextInput
                Icon={passwordIcon}
                type={showPassword ? "text" : "password"}
                name={"password"}
                value={password}
                placeholder={"Enter Password "}
                inputClass={"w-full rounded-tr-[10px] rounded-br-[10px] border-none"}
                iconAreaClass={"w-[18%] bg-[#E1E1E1] rounded-tl-[10px] rounded-bl-[10px] border-none"}
                inputDivClass={"mb-3 sm:mb-2 h-[55px] sm:h-[65px]"}
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.password}
                touched={touched.password}
                showPassword={showPassword}
                togglePassword={togglePassword}
                autoComplete={"new-password"}
              />

              <TextInput
                Icon={passwordIcon}
                type={showConfirmPassword ? "text" : "password"}
                name={"confirmPassword"}
                value={confirmPassword}
                placeholder={"Confirm Password"}
                inputClass={"w-full rounded-tr-[10px] rounded-br-[10px] border-none"}
                iconAreaClass={"w-[18%] bg-[#E1E1E1] rounded-tl-[10px] rounded-bl-[10px] border-none"}
                inputDivClass={"mb-3 sm:mb-2 h-[55px] sm:h-[65px]"}
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.confirmPassword}
                touched={touched.confirmPassword}
                showPassword={showConfirmPassword}
                togglePassword={toggleConfirmPassword}
              />

              <PrimaryButton
                className={
                  "mt-5 w-[100%] text-[18px] font-[600] h-[50px] sm:h-[45px] md:h-[50px] rounded-[10px]"
                }
                label="LOGIN"
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
