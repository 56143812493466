import React, { useState, useMemo } from "react";
import "./referForm.scss";
import BreadCrumsHeader from "../../common/BreadCrums";
import Input from "../../common/FormInputs";
import PhoneFormInput from "../../common/PhoneInput";
import FormLayout from "../../common/FormLayout";
import InputTextArea from "../../common/InputTextArea";
import { ReactComponent as RedNose } from "../../../Assets/Images/rednose.svg";
import { ReactComponent as DownArrow } from "../../../Assets/Icons/Down Arrow.svg";
import Selector from "../../common/Selector";
import { Country, State, City } from "country-state-city";
import { useFormik } from "formik";
import { referSchema } from "../../../utils/validationSchemas";
import GoogleMaps from "../../common/GoogleMaps";

const ReferFormPage = () => {
  const [showMap, setShowMap] = useState(false);
  const [placeId, setPlaceId] = useState(null);

  const handleLocationSelect = (selectedLocation) => {
    setFieldValue("location", selectedLocation?.placeName);
    setPlaceId(selectedLocation?.placeId);
  };

  const handleLocationClick = () => {
    setShowMap(true);
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      contact: "",
      message: "",
      business: "",
      location: "",
      country: "",
      state: "",
      city: "",
      postCode: "",
      website: "",
    },
    validationSchema: referSchema,
    onSubmit: (values) => {
      // Handle form submission
      console.log(values);
    },
  });

  const {
    firstName,
    lastName,
    email,
    contact,
    message,
    business,
    location,
    country,
    state,
    city,
    postCode,
    website,
  } = formik.values;

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = formik;

  const countries = Country.getAllCountries();
  const countryOptions = countries.map((country) => ({
    value: country?.name ?? "abc",
    label: country?.name ?? "dsc",
  }));
  const selectCountry = () => {
    const res = countries.find((val) => val.name === country);
    return res;
  };
  const selectedCountry = useMemo(() => selectCountry(), [country]);

  const states = useMemo(
    () => State.getStatesOfCountry(selectedCountry?.isoCode),
    [selectedCountry]
  );
  const stateOptions = states.map((state) => ({
    value: state?.name ?? "abc",
    label: state?.name ?? "dsc",
  }));

  const selectState = () => {
    const res = states.find((val) => val.name === state);
    return res;
  };

  const selectedState = useMemo(() => selectState(), [state]);

  const cities = useMemo(
    () =>
      City.getCitiesOfState(selectedState?.countryCode, selectedState?.isoCode),
    [selectedState]
  );

  const cityOptions = cities.map((city) => ({
    value: city?.name ?? "abc",
    label: city?.name ?? "dsc",
  }));

  const selectCity = () => {
    const res = cities.find((val) => val.name === city);
    return res;
  };

  const handleCountryChange = (e) => {
    if (e) {
      setFieldValue("country", e);
      setFieldValue("state", null);
      setFieldValue("city", null);
    }
  };

  const handleStateChange = (e) => {
    if (e) {
      setFieldValue("state", e);
      setFieldValue("city", null);
    }
  };

  const handleCityChange = (e) => {
    if (e) {
      setFieldValue("city", e);
    }
  };

  const items = [
    {
      title: "Refer a Merchant",
    },
    {
      title: "Refer New Merchant",
    },
  ];

  const ContactDetails = (
    <>
      {/* Form Inputs */}

      <div className="flex sm:flex-col md:flex-row justify-between mt-7 w-[100%]">
        <Input
          label="First Name"
          placeholder="Name"
          width="100%"
          radius="10px"
          InputDivClass="w-[100%]"
          name={"firstName"}
          value={firstName}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.firstName}
          touched={touched.firstName}
        />
        <div className="w-[10%]"></div>
        <Input
          label="Last Name"
          placeholder="Name"
          width="100%"
          radius="10px"
          InputDivClass="w-[100%] sm:mt-10 md:mt-0"
          name={"lastName"}
          value={lastName}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.lastName}
          touched={touched.lastName}
        />
      </div>

      <div className="flex sm:flex-col md:flex-row justify-between mt-7">
        <Input
          label="Email"
          placeholder="Email Address"
          width="100%"
          radius="10px"
          InputDivClass="w-[100%]"
          name={"email"}
          value={email}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.email}
          touched={touched.email}
        />
        <div className="w-[10%]"></div>
        <PhoneFormInput
          label={"Contact no."}
          divClassName="sm:mt-12 md:mt-0 w-full"
          name="contact"
          value={contact}
          handleBlur={handleBlur}
          error={errors.contact}
          touched={touched.contact}
          setFieldValue={setFieldValue}
          inputStyle={{
            width: "100%",
            border: "none",
          }}
          buttonStyle={{ border: "none" }}
          buttonClass="!rounded-l-[8px] !bg-[#D9D9D9] md:!w-[20%] sm:!w-[15%] lg:!w-[15%] xl:!w-[12%] 2xl:!w-[10%]"
          containerClass="!rounded-[10px] border border-[#D9D9D9]"
          inputClass="lg:!h-[45px] md:!h-[45px] !rounded-[10px] !bg-[#FFFFFF] !text-[#303030] lg:!text-[16px] sm:!text-[14px] !font-[400] !outline-none !font-poppins md:!pl-[22%] sm:!pl-[17%] lg:!pl-[17%] xl:!pl-[15%] 2xl:!pl-[12%]"
          labelClass="text-[14px] font-[400] text-[#303030] mb-6 font-poppins"
          errorClass={
            "text-red-600 sm:text-[10px] md:text-[12px] font-[600] ms-1"
          }
        />
      </div>

      <InputTextArea
        label={"Message"}
        divClassName="relative mt-5 w-full"
        labelClassName="invitationLabels"
        textareaClassName="w-full h-[150px] px-[1%] pt-2 rounded-[5px] bg-[#FFFFFF] border border-[#D9D9D9] text-[#303030] lg:text-[14px] sm:text-[12px] font-[400] outline-none resize-none font-poppins"
        name="message"
        value={message}
        handleBlur={handleBlur}
        error={errors.message}
        touched={touched.message}
        setFieldValue={setFieldValue}
      />

      <div className="invite mt-7 flex justify-end w-full">
        <button
          type="submit"
          className="sendButton text-[14px] font-[400] font-poppins text-center sm:w-[35%] md:w-[30%] lg:w-[15%]"
          onClick={() => {
            handleSubmit();
          }}
        >
          Send
        </button>
      </div>
    </>
  );

  const OrganizationDetails = (
    <>
      <Input
        type="text"
        label="Business Name"
        placeholder=""
        width="100%"
        radius="5px"
        InputDivClass="w-[100%] mt-8"
        name="business"
        value={business}
        handleChange={handleChange}
        handleBlur={handleBlur}
        error={errors.business}
        touched={touched.business}
      />
      <Input
        type="text"
        label="Location"
        placeholder=""
        width="100%"
        radius="5px"
        InputDivClass="w-[100%] mt-8"
        name="location"
        value={location}
        handleChange={handleChange}
        handleBlur={handleBlur}
        error={errors.location}
        touched={touched.location}
        onClick={handleLocationClick}
      />
      {showMap && (
        <GoogleMaps
          onLocationSelect={handleLocationSelect}
          searchContainerClass="w-[56%] sm:w-[51%] md:w-[50%] lg:w-[56%] 2xl:w-[63%] absolute top-[10px] left-[33%] sm:left-[37%] md:left-[38%] lg:left-[33%] 2xl:left-[28%]"
        />
      )}
      <div className="flex sm:flex-col md:flex-row justify-between mt-8 w-[100%] bankform">
        <Selector
          label={"Country"}
          value={country}
          labelClassName="lg:text-[14px] sm:text-[12px] font-[400] text-[#303030] mb-2 font-poppins lg:leading-[27px] sm:leading-[21px]"
          divClassName="mt-5 md:w-[46%] sm:w-[100%] sm:mt-10 md:mt-0"
          selectClassName="w-full pt-2 rounded-[5px] bg-[#FFFFFF] text-[#303030] lg:text-[16px] sm:text-[10px] font-[400] !font-poppins !focus:outline-none"
          flexClassName="gap-7"
          options={countryOptions}
          suffixIcon={<DownArrow />}
          placeholder={"Select Country"}
          onChange={handleCountryChange}
          handleBlur={handleBlur}
          error={errors.country}
          touched={touched.country}
        />
        <Selector
          label={"Province or State"}
          value={state}
          labelClassName="lg:text-[14px] sm:text-[12px] font-[400] text-[#303030] mb-2 font-poppins lg:leading-[27px] sm:leading-[21px]"
          divClassName="mt-5 md:w-[46%] sm:w-[100%] sm:mt-10 md:mt-0"
          selectClassName="w-full pt-2 rounded-[5px] bg-[#FFFFFF] text-[#303030] lg:text-[16px] sm:text-[10px] font-[400] !font-poppins !focus:outline-none"
          flexClassName="gap-7"
          options={stateOptions}
          suffixIcon={<DownArrow />}
          placeholder={"Select State"}
          onChange={handleStateChange}
          handleBlur={handleBlur}
          error={errors.state}
          touched={touched.state}
        />
      </div>

      <div className="flex sm:flex-col md:flex-row justify-between mt-8 w-[100%] bankform">
        <Selector
          label={"City"}
          value={city}
          labelClassName="lg:text-[14px] sm:text-[12px] font-[400] text-[#303030] mb-2 font-poppins lg:leading-[27px] sm:leading-[21px]"
          divClassName="mt-5 md:w-[46%] sm:w-[100%] sm:mt-10 md:mt-0"
          selectClassName="w-full pt-2 rounded-[5px] bg-[#FFFFFF] text-[#303030] lg:text-[16px] sm:text-[10px] font-[400] !font-poppins !focus:outline-none"
          flexClassName="gap-7"
          options={cityOptions}
          suffixIcon={<DownArrow />}
          placeholder={"Select City"}
          onChange={handleCityChange}
          handleBlur={handleBlur}
          error={errors.city}
          touched={touched.city}
        />
        <Input
          type="text"
          label="Post Code"
          placeholder=""
          width="100%"
          radius="5px"
          InputDivClass="md:w-[46%] sm:w-[100%] sm:mt-10 md:mt-0"
          name="postCode"
          value={postCode}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.postCode}
          touched={touched.postCode}
        />
      </div>

      <Input
        type="text"
        label="Website"
        placeholder=""
        width="100%"
        radius="5px"
        InputDivClass="w-[100%] mt-8"
        name="website"
        value={website}
        handleChange={handleChange}
        handleBlur={handleBlur}
        error={errors.website}
        touched={touched.website}
      />
    </>
  );

  return (
    <>
      <div className="flex flex-col gap-5">
        <RedNose />
        <BreadCrumsHeader
          heading="Refer New Merchant"
          items={items}
          form={true}
        />
      </div>
      <FormLayout
        title={"Organization Details"}
        content={OrganizationDetails}
      />
      <FormLayout title={"Contact Details"} content={ContactDetails} />
    </>
  );
};

export default ReferFormPage;
