import React from "react";
import searchicon from "../../Assets/Icons/akar-icons_search.svg";

const SearchBar = ({
  parentDivClass,
  divClassName,
  inputClassName,
  iconClassName,
  placeholder,
  value,
  handleChange,
  handleKeyDown,
  searchResults,
  handleResultClick,
}) => {
  return (
    <div className={parentDivClass}>
      <div className={divClassName}>
        <input
          type="text"
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          className={inputClassName}
        />
        <img className={iconClassName} src={searchicon} />
      </div>
      {searchResults?.length > 0 && (
        <div className="w-full h-[120px] rounded-[8px] mt-1 bg-white overflow-y-auto px-2 py-1">
          {searchResults.map((result, index) => (
            <div
              key={index}
              onClick={() => handleResultClick(result)}
              className="cursor-pointer font-poppins font-[400] text-[14px] hover:bg-[#00ADEF] hover:pl-1 rounded-[2px]"
            >
              {result?.structured_formatting?.main_text}{" "}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
